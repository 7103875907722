import config from "config";
import { authGetJson } from "shared/auth/authFetches";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IBasinItem, IBusinessItem, ICountry, IFacility, IHazard, IHazardDescription, IOtherLocation, IPhase, IPointOfRelease, IRiskStandard, ISituationIncidentOrEventType, IValidityPeriod } from "types/masterDataTypes";
import { formatGetBasinsResponse, formatGetCountries, formatGetDivisionsResponse, formatGetFacilities, formatGetHazardDescriptionsResponse, formatGetHazardsResponse, formatGetOtherLocationsResponse, formatGetPhasesResponse, formatGetPointsOfReleaseResponse, formatGetRiskStandardsResponse, formatGetSituationsResponse, formatGetValidityPeriodsResponse } from "./masterDataFormatters";

class MasterDataApi {
  public async getValidityPeriods(): Promise<IValidityPeriod[]> {
    const response = await authGetJson(config.endpoints.masterData.validityPeriods);
    await throwIfResponseError(response);
    return formatGetValidityPeriodsResponse(await response.json());
  }

  public async getPhases(): Promise<IPhase[]> {
    const response = await authGetJson(config.endpoints.masterData.phases);
    await throwIfResponseError(response);
    return formatGetPhasesResponse(await response.json());
  }

  public async getOtherLocations(): Promise<IOtherLocation[]> {
    const response = await authGetJson(config.endpoints.masterData.otherLocations);
    await throwIfResponseError(response);
    return formatGetOtherLocationsResponse(await response.json());
  }

  public async getRiskStandards(): Promise<IRiskStandard[]> {
    const response = await authGetJson(config.endpoints.masterData.standards);
    await throwIfResponseError(response);
    return formatGetRiskStandardsResponse(await response.json());
  }

  public async getEventTypes(): Promise<ISituationIncidentOrEventType[]> {
    const response = await authGetJson(config.endpoints.masterData.situations);
    await throwIfResponseError(response);
    return formatGetSituationsResponse(await response.json());
  }

  public async getPointsOfRelease(): Promise<IPointOfRelease[]> {
    const response = await authGetJson(config.endpoints.masterData.pointsOfRelease);
    await throwIfResponseError(response);
    return formatGetPointsOfReleaseResponse(await response.json());
  }

  public async getHazards(): Promise<IHazard[]> {
    const response = await authGetJson(config.endpoints.masterData.hazards);
    await throwIfResponseError(response);
    return formatGetHazardsResponse(await response.json());
  }

  public async getHazardDescriptions(): Promise<IHazardDescription[]> {
    const response = await authGetJson(config.endpoints.masterData.hazardDescriptions);
    await throwIfResponseError(response);
    return formatGetHazardDescriptionsResponse(await response.json());
  }
  
  public async getFacilities(searchText?: string): Promise<IFacility[]> {
    const response = await authGetJson(config.endpoints.masterData.facilities + `?searchText=${searchText}`);
    await throwIfResponseError(response);
    return formatGetFacilities(await response.json());
  }

  public async getCountries(): Promise<ICountry[]> {
    const response = await authGetJson(config.endpoints.masterData.countries);
    await throwIfResponseError(response);
    return formatGetCountries(await response.json());
  }

  public async getBusinesses(): Promise<IBusinessItem[]> {
    const response = await authGetJson(config.endpoints.masterData.divisions);
    await throwIfResponseError(response);
    return formatGetDivisionsResponse(await response.json());
  }

  public async getBasins(): Promise<IBasinItem[]> {
    const response = await authGetJson(config.endpoints.masterData.basins);
    await throwIfResponseError(response);
    return formatGetBasinsResponse(await response.json());
  }
}

const masterDataApi = new MasterDataApi();
export default masterDataApi;
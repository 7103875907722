import { IAzureADUser } from "shared/types/userTypes";
import { YesNo } from "./answerTypes";
import { BusinessTypes, IBusinessItem, ICountry, IFacility, IHazard, IHazardDescription, IOtherLocation, IPhase, IPointOfRelease, IRiskStandard, ISituationIncidentOrEventType, IValidityPeriod } from "./masterDataTypes";

export interface IRiskItem {
  id: string,
  processOperationUID: string,
  mahruid: number,
  mahruidPrefixed: string,
  dateOfAssessment?: number,
  highLevelDescription?: string,
  reporter?: IAzureADUser,
  validityPeriod?: IValidityPeriod,
  phase?: IPhase,
  otherLocation?: IOtherLocation,
  riskStandard?: IRiskStandard,
  divisionHierarchy?: IRiskBusinessItem[],
  basinHierarchy?: IRiskBasinItem[],
  country?: ICountry,
  facility?: IFacility,

  teamLeader?: IAzureADUser,
  teamMembers?: IAzureADUser[],

  otherLocationDescription?: string,

  // Hazard Identification Info
  wellControlRisk?: YesNo,
  existingProcess?: YesNo,
  existingProcedures?: string,
  similarExperience?: string,
  processFlowDiagram?: string,
  PandID?: string,
  operatingHistory?: string,

  // Type of Operation
  permanentProcessingFacilityOrEquipment?: YesNo,
  temporaryProcessingFacilityOrEquipment?: YesNo,
  continuousOperation?: YesNo,
  batchOperation?: YesNo,

  // References
  prevMAHRegisterLink?: string,
  regulatoryReqsLink?: string,
  contracturalReqsLink?: string,
  assumptions?: string,
  emergencyResponsePlan?: string,
  riskWorkshopReportLink?: string,

  // Admin stuff that comes from the backend.
  createdBy?: IAzureADUser,
  createdOn?: number,
  modifiedBy?: IAzureADUser,

  hazards: IRiskHazardItem[],
}

export interface IRiskHazardItem {
  lineItemNumber: number,
  hazard?: IHazard,
  hazardDescription?: IHazardDescription,
  source?: string,
  volume?: string,
  comments?: string,
  maxReleaseAboveTier1Threshold?: YesNo,
  pointOfRelease?: IPointOfRelease,
  otherPointOfReleaseDescription?: string,

  relativeRanking?: YesNo,
  whatIfAnalysis?: YesNo,
  safetyReview?: YesNo,
  prelimHazardAnalysis?: YesNo,
  checklist?: YesNo,
  otherHIRATools?: YesNo,
  otherHIRAToolsDescription?: string,

  type?: ISituationIncidentOrEventType,
  typeDescription?: string,

  potentialRisk?: RiskMatrixValues,
  residualRisk?: RiskMatrixValues,

  kpiTier3?: string,
  kpiTier4?: string,
}

export interface IRiskBusinessItem {
  id: string,
  code: string,
  name: string,
  type: BusinessTypes,
}

export interface IRiskBasinItem {
  id: string,
  code: string,
  name: string,
  type: string,
}

export enum RiskMatrixValues {
  A0 = "A0",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  B0 = "B0",
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  B4 = "B4",
  B5 = "B5",
  C0 = "C0",
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  D0 = "D0",
  D1 = "D1",
  D2 = "D2",
  D3 = "D3",
  D4 = "D4",
  D5 = "D5",
}

export enum RiskMatrixColors {
  white = "white",
  orange = "orange",
  red = "red",
  yellow = "yellow",
}

export enum RiskMatrixColumns {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}

export enum RiskMatrixRows {
  Row0 = 0,
  Row1 = 1,
  Row2 = 2,
  Row3 = 3,
  Row4 = 4,
  Row5 = 5,
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export const RiskMatrixCellColors: EnumDictionary<RiskMatrixValues, string> = {
  [RiskMatrixValues.A0]: RiskMatrixColors.white,
  [RiskMatrixValues.A1]: RiskMatrixColors.white,
  [RiskMatrixValues.A2]: RiskMatrixColors.white,
  [RiskMatrixValues.A3]: RiskMatrixColors.white,
  [RiskMatrixValues.A4]: RiskMatrixColors.orange,
  [RiskMatrixValues.A5]: RiskMatrixColors.orange,
  [RiskMatrixValues.B0]: RiskMatrixColors.white,
  [RiskMatrixValues.B1]: RiskMatrixColors.white,
  [RiskMatrixValues.B2]: RiskMatrixColors.white,
  [RiskMatrixValues.B3]: RiskMatrixColors.orange,
  [RiskMatrixValues.B4]: RiskMatrixColors.orange,
  [RiskMatrixValues.B5]: RiskMatrixColors.red,
  [RiskMatrixValues.C0]: RiskMatrixColors.white,
  [RiskMatrixValues.C1]: RiskMatrixColors.white,
  [RiskMatrixValues.C2]: RiskMatrixColors.yellow,
  [RiskMatrixValues.C3]: RiskMatrixColors.orange,
  [RiskMatrixValues.C4]: RiskMatrixColors.red,
  [RiskMatrixValues.C5]: RiskMatrixColors.red,
  [RiskMatrixValues.D0]: RiskMatrixColors.white,
  [RiskMatrixValues.D1]: RiskMatrixColors.white,
  [RiskMatrixValues.D2]: RiskMatrixColors.yellow,
  [RiskMatrixValues.D3]: RiskMatrixColors.red,
  [RiskMatrixValues.D4]: RiskMatrixColors.red,
  [RiskMatrixValues.D5]: RiskMatrixColors.red,
};

export interface IRiskHazardLineItemView {
  // Header information.
  businessLines: IBusinessItem[],
  country: ICountry,
  facility: IFacility,
  processUID: string,
  processDescription: string,
  phase: IPhase,
  id: string,

  // Body information.
  lineItemId: string,
  lineItemNumber: number,
  hazard?: IHazard,
  hazardDescription?: IHazardDescription,
  source?: string,
  volume?: string,
  maxReleaseAboveTier1Threshold?: YesNo,
  pointOfRelease?: IPointOfRelease,
  otherPointOfReleaseDescription?: string,
  comments?: string,

  relativeRanking?: YesNo,
  whatIfAnalysis?: YesNo,
  safetyReview?: YesNo,
  prelimHazardAnalysis?: YesNo,
  checklist?: YesNo,
  otherHIRATools?: YesNo,
  otherHIRAToolsDescription?: string,

  type?: ISituationIncidentOrEventType,
  typeDescription?: string,

  potentialRisk?: RiskMatrixValues,
  residualRisk?: RiskMatrixValues,

  kpiTier3?: string,
  kpiTier4?: string,
}

export interface IRiskItemCreateResponse {
  id: string,
  mahruid: number,
  mahruidNumber: string,
  processOperationUID: string,
}

export interface IRiskListFilters {
  startDate?: number,
  endDate?: number,
  reporter?: string,
  teamLeader?: string,
  highLevelDescription?: string,
  hazardId?: string,
  divisionHierarchyIds: string[],
  basinHierarchyIds: string[],
  countryIds: string[],
  facilityIds: string[],
  otherLocationId?: string,
  otherLocationDescription?: string,
  processOperationUID?: string,
  mahruidLineNumber?: string,
  phaseId?: string,
  valid?: YesNo,
  wellControlRisk?: YesNo,
  permanentProcessing?: YesNo,
  temporaryProcessing?: YesNo,
  continuous?: YesNo,
  batch?: YesNo,
  potentialRisks: RiskMatrixValues[],
  residualRisks: RiskMatrixValues[],
}
import React from "react";
import "./FlexCol.scoped.scss";

const FlexCol: React.FC<React.HTMLProps<HTMLDivElement>> = (props) =>
  <div
    {...props}
    className={(props.className ?? "") + " flex-col"}
  >
    {props.children}
  </div>;

export default FlexCol;
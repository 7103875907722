import React, { useState } from "react";
import { Link, useMatch } from "react-router-dom";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import "./MenuItem.scoped.scss";

export interface IMenuItemProps {
  icon: string,
  label: string,
  route: string,
  key: string,
  toggleOnClick?: boolean,
  subItems?: IMenuItemProps[],
  onClick?(): void
}

const MenuItem: React.FC<IMenuItemProps> = ({
  icon,
  label,
  route,
  toggleOnClick,
  subItems,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const itemContents = (
    <>
      {icon &&
        <img
          src={icon}
          alt=""
        />
      }
      <label>{label}</label>
    </>
  );

  return (
    <div
      className="menu-item"
    >
      <div
        className={`item ${useMatch(route) ? "active" : ""}`}
      >
        {(!route
          || toggleOnClick) &&
          <span
            onClick={() => onClick
              ? onClick()
              : toggleOnClick
                ? setIsOpen(!isOpen)
                : undefined}
          >
            {itemContents}
          </span>
        }
        {route
          && !toggleOnClick &&
          <Link
            to={route}
          >
            {itemContents}
          </Link>
        }
        {subItems &&
          <CollapseSwitch
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            className="menu-toggle"
          />
        }
      </div>
      {isOpen &&
        <div
          className="sub-items"
        >
          {subItems?.map(si => (
            <MenuItem
              label={si.label}
              key={si.key}
              icon={si.icon}
              subItems={si.subItems}
              toggleOnClick={si.toggleOnClick}
              route={si.route}
              onClick={si.onClick}
            />
          ))}
        </div>
      }
    </div>
  );
}

export default MenuItem;

import React from "react";
import "./PageFooter.scoped.scss";

const PageFooter: React.FC<{ children: React.ReactNode; }> = ({ children }) =>
  <div
    className="page-footer"
  >
    {children}
  </div>;

export default PageFooter;
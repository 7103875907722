import React from "react";
import "./LabeledControl.scoped.scss";

export interface ILabeledControlProps {
  /**
   * The text to show.
   */
  label: string,
  /**
   * Determines whether to show the required indicator or not in the label.
   */
  isRequired?: boolean,
  className?: string,
  /**
   * If true, the label will show up in a lighter gray color.
   */
  isLight?: boolean,
  children: React.ReactNode,
}

const LabeledControl: React.FC<ILabeledControlProps> = ({
  label,
  isRequired,
  className,
  isLight,
  children,
}) =>
  <div
    className={`labeled-control ${className || ""} ${isLight ? "light" : ""}`}
  >
    <label
      className={isRequired
        ? "required"
        : ""
      }
    >
      {label}
    </label>
    <div>
      {children}
    </div>
  </div>;

export default LabeledControl;
import React from "react";
import { Link } from "react-router-dom";
import logoIcon from "../../../media/icons/logo-icon.svg";
import "./TitleBar.scoped.scss";
import UserBadge from "./UserBadge";

interface ITitleBarProps {
  title: string,
  titleUrl: string,
}

const TitleBar: React.FC<ITitleBarProps> = ({
  title,
  titleUrl,
}) =>
  <div
    className="title-bar no-text-select"
  >
    <div
      className="logo-row"
    >
      <img
        alt=""
        src={logoIcon}
        className="logo-icon"
      />

      <Link
        className="link"
        to={titleUrl}
      >
        {title}
      </Link>
    </div>

    <span
      className="space"
    >
    </span>
    <UserBadge />
  </div>;

export default TitleBar;
import { IColumnItem } from "shared/types/columnTypes";
import "./TableRows.scoped.scss";

interface ITableRowsProps<T, K extends keyof T> {
  data: Array<T>,
  columns: Array<IColumnItem<T, K>>,
  columnRender: (columnKey: string, boundObj: any, objProperty: any) => JSX.Element,
}

const TableRows = <T, K extends keyof T>({ data, columns, columnRender }: ITableRowsProps<T, K>): JSX.Element => {

  const rows = data.map((row, index) => {
    return (
      <tr 
        key={`row-${index}`}
      >
        {columns.map((column) => {
          return (
            columnRender(column.key, row, column.property)
          )
        }
        )}
      </tr>
    );
  });

  return (
    <tbody>
      {rows}
    </tbody>
  );
};

export default TableRows;
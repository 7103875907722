import UsersApi from "api/users-api/UsersApi";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Note from "shared/components/common/note/Note";
import Spinner from "shared/components/common/spinner/Spinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import addIcon from "shared/media/dls/add.svg";
import removeIcon from "shared/media/dls/remove.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IOperation } from "shared/types/operationTypes";
import { IAzureADUser } from "shared/types/userTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { isValidEmail } from "shared/utilities/emailUtilities";
import { useAppDispatch } from "store/store";
import "./UsersEditorModal.scoped.scss";

interface IUsersEditorModalProps {
  allowMultiple?: boolean,
  currentUsers: IAzureADUser[],
  title: React.ReactNode,
  allowExternal?: boolean,
  onClose(): void,
  onSave(selectedUsers: IAzureADUser[]): void,
  isDisabled?: boolean,
}

const UsersEditorModal: React.FC<IUsersEditorModalProps> = ({
  allowMultiple,
  currentUsers,
  title,
  allowExternal,
  onClose,
  onSave,
  isDisabled,
}) => {
  const [localSelection, setLocalSelection] = useState(currentUsers.slice());
  const [searchOp, setSearchOp] = useState<IOperation<IAzureADUser[]>>({
    isWorking: false,
  });
  const [isAddExternalOpen, setIsAddExternalOpen] = useState(false);
  const [externalUserData, setExternalUserData] = useState<IAzureADUser>({
    name: "",
    email: "",
  });

  const dispatch = useAppDispatch();

  const onSearchValueChange = async (searchTerm: string) => {
    if (searchTerm.trim()) {
      setSearchOp({
        isWorking: true,
      });
      try {
        const users = await UsersApi.searchADUsers(searchTerm);
        setSearchOp({
          isWorking: false,
          data: users,
        });
      } catch (err) {
        setSearchOp({
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        });
      }
    } else {
      setSearchOp({
        isWorking: false,
      });
    }
  };

  const debouncedChangeHandler = useMemo(() => debounce(onSearchValueChange, 300), []);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const canAddUser = (user: IAzureADUser) =>
    !localSelection
      .some(z => z.email.toLowerCase() === user.email.toLowerCase())
    && (allowMultiple
      || localSelection.length === 0);

  const tryAddUser = (user: IAzureADUser) => {
    if (canAddUser(user)) {
      setLocalSelection(localSelection.concat(user));
    }
  };

  const tryAddExternalUser = () => {
    if (!externalUserData.name.trim()
      || !externalUserData.email.trim()) {
      dispatch(showErrorToast("Both Name and Email are required."));
      return;
    }

    if (!isValidEmail(externalUserData.email)) {
      dispatch(showErrorToast("The specified Email is not valid."));
      return;
    }

    if (!canAddUser(externalUserData)) {
      dispatch(showErrorToast(allowMultiple
        ? "The specified user already exists in the selected users list."
        : "There is already a user selected."
      ));
      return;
    }

    setLocalSelection(localSelection.concat(externalUserData));
    setIsAddExternalOpen(false);
  };

  let searchResultsComp: React.ReactNode | undefined;

  if (searchOp.isWorking) {
    searchResultsComp = (
      <Spinner />
    );
  } else if (searchOp.errorMessage) {
    searchResultsComp = (
      <Banner
        type={BannerType.error}
      >
        {searchOp.errorMessage}
      </Banner>
    );
  } else if (searchOp.data) {
    if (searchOp.data.length) {
      searchResultsComp = (
        <ul>
          {searchOp.data
            .slice()
            .sort(sortUsers)
            .map(x => (
              <li
                key={x.email}
              >
                <img
                  className={`icon-small button ${!canAddUser(x) ? "disabled" : ""}`}
                  onClick={() => tryAddUser(x)}
                  src={addIcon}
                  alt="Add"
                  title="Add"
                />
                {x.name} ({x.email})
              </li>
            ))}
        </ul>
      );
    } else {
      searchResultsComp = (
        <Banner
          type={BannerType.info}
        >
          No users were found matching the search term.
        </Banner>
      );
    }
  }
  return (
    <Modal
      minWidth="75%"
      header={title}
      isOpen={true}
      buttons={[{
        key: "add_external",
        text: "add external user",
        className: "tertiary",
        disabled: (!allowMultiple
          && localSelection.length > 0)
          || isDisabled,
        onClick: () => {
          setExternalUserData({
            name: "",
            email: "",
          });
          setIsAddExternalOpen(true);
        },
      }, {
        key: "cancel",
        text: "cancel",
        className: "secondary",
        onClick: onClose,
      }, {
        key: "save",
        text: "save",
        className: "primary",
        disabled: isDisabled,
        onClick: () => {
          onSave(localSelection);
          onClose();
        },
      }].filter(x => x.key !== "add_external" || allowExternal)}
    >
      <FlexRow>
        <LabeledControl
          label={`Selected User${allowMultiple ? "s" : ""}`}
        >
          {!localSelection.length
            ? (
              <Note>Use the search box to the right to find users.</Note>
            ) : (
              <ul>
                {localSelection
                  .slice()
                  .sort(sortUsers)
                  .map(x => (
                    <li
                      key={x.email}
                    >
                      <img
                        src={removeIcon}
                        className={`icon-small button ${isDisabled ? "disabled" : ""}`}
                        onClick={isDisabled
                          ? undefined
                          : () => setLocalSelection(localSelection.filter(z => z.email.toLowerCase() !== x.email.toLowerCase()))
                        }
                        alt="Remove"
                        title="Remove"
                      />

                      {x.name} ({x.email})
                    </li>
                  ))}
              </ul>
            )}
        </LabeledControl>

        <LabeledControl
          label="Search for SLB Users"
        >
          <input
            type="text"
            placeholder="Type to begin searching"
            onChange={(e) => debouncedChangeHandler(e.currentTarget.value)}
            disabled={isDisabled}
          />

          {searchResultsComp}
        </LabeledControl>
      </FlexRow>

      {isAddExternalOpen && (
        <Modal
          isOpen={true}
          header="Add External User"
          buttons={[{
            key: "cancel",
            text: "cancel",
            className: "secondary",
            onClick: () => setIsAddExternalOpen(false),
          }, {
            key: "add",
            text: "add",
            className: "primary",
            onClick: () => tryAddExternalUser(),
          }]}
        >
          <FlexCol>
            <LabeledControl
              label="Full Name (First and Last)"
            >
              <input
                type="text"
                value={externalUserData.name}
                onChange={e => setExternalUserData({
                  ...externalUserData,
                  name: e.currentTarget.value,
                })}
              />
            </LabeledControl>
            <LabeledControl
              label="Email Address"
            >
              <input
                type="text"
                value={externalUserData.email}
                onChange={e => setExternalUserData({
                  ...externalUserData,
                  email: e.currentTarget.value,
                })}
              />
            </LabeledControl>
          </FlexCol>
        </Modal>
      )}
    </Modal>
  );
};

export default UsersEditorModal;

function sortUsers(a: IAzureADUser, b: IAzureADUser) {
  return a.name < b.name
    ? -1
    : (a.email < b.email
      ? -1
      : 1
    );
}
import React from "react";
import Portal from "shared/components/layout/portal/Portal";
import spinnerIcon from "shared/media/Pulse-1s-200px.svg";
import "./ModalSpinner.scoped.scss";

interface IModalSpinnerProps {
  altText?: string,
}

const ModalSpinner: React.FC<IModalSpinnerProps> = (props) =>
  <Portal>
    <div
      className="modal-spinner"
    >
      <div
        className="fade"
      >
      </div>
      <div
        className="spinner"
        style={{
          display: "block",
          textAlign: "center",
        }}
      >
        <img
          src={spinnerIcon}
          alt={props.altText || ""}
        />
      </div>
    </div>
  </Portal>;

export default ModalSpinner;
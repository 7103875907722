import { authDelete, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IRiskHazardLineItemView, IRiskItem, IRiskItemCreateResponse, IRiskListFilters } from "types/riskTypes";
import { formatCreateRiskItemRequest, formatGetRiskItemResponse, formatRiskLineItemViewResponse, formatSearchRiskFilter, formatUpdateRiskItemRequest } from "./riskFormatters";

class RisksApi {
  public async getRiskLineItemViews(pageSize: number,
    page: number,
    filters: IRiskListFilters): Promise<IPagedResult<IRiskHazardLineItemView>> {
    const response = await authPostJson(`${config.endpoints.riskLineItems}/search?page=${page}&pageSize=${pageSize}`,
      formatSearchRiskFilter(filters));
    await throwIfResponseError(response);
    return formatRiskLineItemViewResponse(await response.json());
  }

  public async createRiskItem(item: IRiskItem): Promise<IRiskItemCreateResponse> {
    const response = await authPostJson(config.endpoints.riskItems, formatCreateRiskItemRequest(item));
    await throwIfResponseError(response);
    return (await response.json()).id;
  }

  public async updateRiskItem(item: IRiskItem): Promise<void> {
    const response = await authPutJson(`${config.endpoints.riskItems}/${item.id}`,
      formatUpdateRiskItemRequest(item));
    await throwIfResponseError(response);
  }

  public async getRiskItem(id: string): Promise<IRiskItem> {
    const response = await authGetJson(`${config.endpoints.riskItems}/${id}`);
    await throwIfResponseError(response);
    return formatGetRiskItemResponse(await response.json());
  }

  public async deleteRiskItem(id: string): Promise<void> {
    const response = await authDelete(`${config.endpoints.riskItems}/${id}`);
    await throwIfResponseError(response);
  }
}

const risksApi = new RisksApi();
export default risksApi;
import React from "react";
import "./PageBar.scoped.scss";
import leftArrowIcon from "shared/media/dls/arrow-left-3.svg";
import { Link } from "react-router-dom";

export interface IPageBarProps {
  title: string,
  backButtonRoute?: string,
  headerControls?: IPageHeaderControl[],
}

export interface IPageHeaderControl {
  key: string,
  element: React.ReactNode,
}

const PageBar: React.FC<IPageBarProps> = ({
  title,
  backButtonRoute,
  headerControls,
}) =>
  <React.Fragment>
    <div
      className="page-bar"
    >
      <div
        className={"pageBarTitle"}
      >
        {backButtonRoute &&
          <Link
            to={backButtonRoute}
          >
            <img
              src={leftArrowIcon}
              alt="< Back"
              className="icon-small"
            />
          </Link>
        }

        <h4>{title}</h4>
      </div>

      <div
        className="controls"
      >
        {!!headerControls?.length && (
          <React.Fragment>
            {headerControls.map(control =>
              <div
                key={control.key}
              >
                {control.element}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  </React.Fragment>;

export default PageBar;
import { useEffect, useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";

const UNDEFINED_DROPDOWN_VALUE = "___UNDEFINED___";

export interface IDropdownProps<TItem> {
  isLoading?: boolean,
  loadError?: string,
  items: TItem[],
  selectedItem?: TItem,
  onChange: (item: TItem | undefined) => void,
  keyMapper: (item: TItem) => string,
  textMapper: (item: TItem) => string,
  loadItems?: () => void,
  /**
   * A handler that will return if the item should be disabled in the dropdown or not.
   */
  disabledItemMapper?: (item: TItem) => boolean,
  autoSelectFirst?: boolean,
  autoSelectValue?: string,
  autoLoadItems?: boolean,
  isDisabled?: boolean,
  placeholder?: string,
}

const Dropdown = <TItem,>({
  isLoading,
  loadError,
  items,
  selectedItem,
  onChange,
  keyMapper,
  textMapper,
  loadItems,
  disabledItemMapper,
  autoSelectFirst,
  autoSelectValue,
  autoLoadItems,
  isDisabled,
  placeholder,
}: IDropdownProps<TItem>) => {
  const [didUserSelect, setDidUserSelect] = useState(false);

  useEffect(() => {
    if (autoLoadItems
      && loadItems) {
      loadItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!didUserSelect
      && items
      && items.length
      && !selectedItem) {
      if (autoSelectFirst) {
        onChange(items[0]);
      } else if (autoSelectValue) {
        onChange(items.find(x => keyMapper(x) === autoSelectValue));
      }
    }
  }, [items, selectedItem, autoSelectFirst, autoSelectValue, keyMapper, onChange, didUserSelect]);

  if (isLoading) {
    return (
      <Spinner
        className="small-spinner"
        hAlign="left"
      />
    );
  } else if (loadError) {
    return (
      <span
        className="error"
        title={loadError}
      >
        Load error.
        {loadItems !== undefined && (
          <button
            className="link"
            onClick={loadItems}
          >
            Retry
          </button>
        )}
      </span>
    );
  }

  return (
    <select
      onChange={e => {
        onChange(e.currentTarget.value === "UNDEFINED_DROPDOWN_VALUE"
          || !e.currentTarget.value
          ? undefined
          : items.find(x => keyMapper(x) === e.currentTarget.value));
        setDidUserSelect(true);
      }}
      value={selectedItem
        ? keyMapper(selectedItem)
        : UNDEFINED_DROPDOWN_VALUE
      }
      disabled={isDisabled}
      className={!selectedItem
        ? "placeholder"
        : undefined
      }
    >
      <option
        value={UNDEFINED_DROPDOWN_VALUE}
        className="placeholder"
      >
        {placeholder || "Select"}
      </option >
      {items.map(item => (
        <option
          key={keyMapper(item)}
          value={keyMapper(item)}
          disabled={disabledItemMapper
            ? disabledItemMapper(item)
            : undefined
          }
        >
          {textMapper(item)}
        </option>
      ))
      }
      {!items.length
        && selectedItem && (
          <option
            value={keyMapper(selectedItem)}
          >
            {textMapper(selectedItem)}
          </option>
        )}
    </select>
  );
};

export default Dropdown;
import { Action, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { history } from "App";
import MasterDataApi from "api/master-data/MasterDataApi";
import RisksApi from "api/risks/RisksApi";
import { Routes } from "components/routing/Routing";
import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { YesNo } from "types/answerTypes";
import { BusinessTypes, IBasinItem, IBusinessItem, ICountry, IFacility } from "types/masterDataTypes";
import { IRiskItem } from "types/riskTypes";
import { validateRiskLineItem } from "utilities/riskUtilities";
import { ManageRiskPickerKeys, deleteRiskItem, finishDeletingRiskItem, finishLoadingEventTypes, finishLoadingHazardDescriptions, finishLoadingHazards, finishLoadingOtherLocations, finishLoadingPhases, finishLoadingPointsOfRelease, finishLoadingRiskItem, finishLoadingRiskStandards, finishLoadingValidityPeriods, finishSavingRiskItem, loadEventTypes, loadHazardDescriptions, loadHazards, loadOtherLocations, loadPhases, loadPickerItems, loadPointsOfRelease, loadRiskItem, loadRiskStandards, loadValidityPeriods, saveRiskItem, setPickerError, setPickerItems, setSelectedPickerItems } from "./ManageRiskSlice";

export function* manageRiskSagas() {
  yield all([
    loadRiskItemAsync(),
    loadPickerDataAsync(),
    saveRiskItemAsync(),
    deleteRiskItemAsync(),
  ]);
}

function* loadPickerDataAsync() {
  yield takeEvery([
    loadValidityPeriods,
    loadPhases,
    loadOtherLocations,
    loadRiskStandards,
    loadHazards,
    loadHazardDescriptions,
    loadEventTypes,
    loadPointsOfRelease,
    loadPickerItems,
  ], function* (action: Action) {
    let apiMethod: (() => Promise<any[]>) | undefined;
    let finishAction: ActionCreatorWithPayload<IOperation<any[]>, string> | undefined;

    if (loadValidityPeriods.match(action)) {
      apiMethod = MasterDataApi.getValidityPeriods;
      finishAction = finishLoadingValidityPeriods;
    } else if (loadOtherLocations.match(action)) {
      apiMethod = MasterDataApi.getOtherLocations;
      finishAction = finishLoadingOtherLocations;
    } else if (loadPhases.match(action)) {
      apiMethod = MasterDataApi.getPhases;
      finishAction = finishLoadingPhases;
    } else if (loadRiskStandards.match(action)) {
      apiMethod = MasterDataApi.getRiskStandards;
      finishAction = finishLoadingRiskStandards;
    } else if (loadHazards.match(action)) {
      apiMethod = MasterDataApi.getHazards;
      finishAction = finishLoadingHazards;
    } else if (loadHazardDescriptions.match(action)) {
      apiMethod = MasterDataApi.getHazardDescriptions;
      finishAction = finishLoadingHazardDescriptions;
    } else if (loadEventTypes.match(action)) {
      apiMethod = MasterDataApi.getEventTypes;
      finishAction = finishLoadingEventTypes;
    } else if (loadPointsOfRelease.match(action)) {
      apiMethod = MasterDataApi.getPointsOfRelease;
      finishAction = finishLoadingPointsOfRelease;
    } else if (loadPickerItems.match(action)) {
      const pickerKey = action.payload.pickerKey;
      const searchValue = action.payload.searchValue;

      switch (action.payload.pickerKey) {
        case ManageRiskPickerKeys.facilities:
          yield retrieveAndPutPickerData(MasterDataApi.getFacilities,
            (item): IPickerItem<IFacility> => ({
              key: item.id,
              item: item,
            }),
            pickerKey,
            false,
            searchValue);
          return;
        case ManageRiskPickerKeys.countries:
          yield retrieveAndPutPickerData(MasterDataApi.getCountries,
            (item): IPickerItem<ICountry> => ({
              key: item.id,
              item: item,
            }),
            pickerKey,
            false,
            searchValue);
          return;
        case ManageRiskPickerKeys.businesses:
          yield retrieveAndPutPickerData(MasterDataApi.getBusinesses,
            (item): IPickerItem<IBusinessItem> => mapBusinessToPickerItem(item),
            pickerKey,
            false,
            searchValue);
          return;
        case ManageRiskPickerKeys.basins:
          yield retrieveAndPutPickerData(MasterDataApi.getBasins,
            (item): IPickerItem<IBasinItem> => mapBasinToPickerItem(item),
            pickerKey,
            false,
            searchValue);
          return;
      }
    }

    if (!apiMethod
      || !finishAction) {
      yield put(showErrorToast(`Load dropdown action not found for ${action.type}.`));
      return;
    }

    yield loadDropdownData(apiMethod,
      finishAction);
  });
}

function* loadRiskItemAsync() {
  yield takeLatest(loadRiskItem, function* (action: Action) {
    if (!loadRiskItem.match(action)) {
      return;
    }

    try {
      // TODO: Go load the risk item from the api.
      const riskItem: IRiskItem = yield call(RisksApi.getRiskItem, action.payload);

      yield put(finishLoadingRiskItem({
        isWorking: false,
        data: riskItem,
      }));
    }
    catch (err) {
      yield put(finishLoadingRiskItem({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* loadDropdownData<T>(apiMethod: () => Promise<T[]>,
  finishAction: ActionCreatorWithPayload<IOperation<T[]>, string>) {
  try {
    const data: T[] = yield call(apiMethod);

    yield put(finishAction({
      isWorking: false,
      data,
    }));
  } catch (err) {
    yield put(finishAction({
      isWorking: false,
      errorMessage: getResponseErrorMessage(err),
    }));
  }
}

function* retrieveAndPutPickerData<T>(apiMethod: (searchValue?: string | undefined) => Promise<T[]>,
  itemMapper: (item: T) => IPickerItem<T>,
  pickerKey: string,
  selectFirstIfOnlyOne: boolean,
  searchValue?: string) {

  try {
    const rawItems: T[] = yield call(apiMethod, searchValue);
    const items = rawItems.map(itemMapper);
    yield put(setPickerItems({
      pickerKey,
      items,
    }));

    if (items.length === 1
      && selectFirstIfOnlyOne) {
      yield put(setSelectedPickerItems({
        pickerKey,
        selectedItems: items,
      }));
    }

    yield put(setPickerError({
      pickerKey,
      errorMessage: "",
      stopLoading: true,
    }));
  } catch (err) {
    yield put(setPickerError({
      pickerKey,
      errorMessage: getResponseErrorMessage(err),
      stopLoading: true,
    }));
  }
}

function mapBusinessToPickerItem(item: IBusinessItem): IPickerItem<IBusinessItem> {
  return {
    key: item.id,
    item: item,
    children: item.children.map(child => mapBusinessToPickerItem(child)),
  };
}

function mapBasinToPickerItem(item: IBasinItem): IPickerItem<IBasinItem> {
  return {
    key: item.id,
    item: item,
    children: item.children.map(child => mapBasinToPickerItem(child)),
  };
}

function* saveRiskItemAsync() {
  yield takeEvery(saveRiskItem, function* (action: Action) {
    if (!saveRiskItem.match(action)) {
      return;
    }

    const riskItem: IRiskItem = yield select((store: RootState) => store.manageRisk.riskItem);

    try {
      validateRiskItem(riskItem);

      if (!riskItem.id) {
        // Create a new risk item in the API.
        const newId: string = yield call(RisksApi.createRiskItem, riskItem);
        yield put(showSuccessToast("Entry saved successfully."));

        yield put(finishSavingRiskItem({
          isWorking: false,
          data: {
            id: newId,
          },
        }));

        // Adds a delay before redirecting the user since doing it immediately is causing
        // the alpha version of the Prompt on the page to ask the user if they want to leave
        // even though the previous put should have cleared the isDirty flag.
        yield delay(1);

        history.push(Routes.ManageRiskReport.replace(":riskId", newId));
        return;
      }

      // Update the existing risk item in the API.
      yield call(RisksApi.updateRiskItem, riskItem);
      yield put(showSuccessToast("Entry saved successfully."));
      yield put(finishSavingRiskItem({
        isWorking: false,
      }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSavingRiskItem({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function validateRiskItem(item: IRiskItem | undefined) {
  if (!item) {
    throw new Error("No data has been loaded to save!");
  }

  let errors: string[] = [];

  if (!item.dateOfAssessment) {
    errors.push("Date Of Assessment is required!");
  }
  if (!item.highLevelDescription) {
    errors.push("Process System/Operation high level description is required!");
  }
  if (!item.reporter) {
    errors.push("Reporter is required!");
  }
  if (!item.validityPeriod) {
    errors.push("Validity Period is required!");
  }
  if (!item.phase) {
    errors.push("Phase is required!");
  }
  if (!item.riskStandard) {
    errors.push("Risk Standard is required!");
  }
  if (!item.divisionHierarchy) {
    errors.push("Division Hierarchy is required!");
  } else if (!item.divisionHierarchy.some(x => x.type === BusinessTypes.BusinessLine)) {
    errors.push("At least one Business Line is required in the Division Hierarchy.");
  }
  if (!item.basinHierarchy) {
    errors.push("Basin Hierarchy is required!");
  }
  if (!item.country) {
    errors.push("Country is required!");
  }
  if (!item.facility) {
    errors.push("Facility is required!");
  }
  if (!item.teamLeader) {
    errors.push("Team Leader is required!");
  }
  if (!item.otherLocationDescription
    && item.otherLocation) {
    errors.push("Other Location Description is required!");
  }
  if (!item.wellControlRisk) {
    errors.push("Well Control Risk is required!");
  }
  if (!item.existingProcess) {
    errors.push("Existing Process is required!");
  }
  if (!item.existingProcedures
    && item.existingProcess === YesNo.Yes) {
    errors.push("Existing Procedures is required!");
  }
  if (!item.similarExperience) {
    errors.push("Similar Experience is required!");
  }
  if (!item.processFlowDiagram) {
    errors.push("Process Flow Diagram is required!");
  }
  if (!item.PandID) {
    errors.push("P&ID is required!");
  }
  if (!item.operatingHistory) {
    errors.push("Operating History is required!");
  }
  if (!item.permanentProcessingFacilityOrEquipment) {
    errors.push("Permanent Processing Facility/Equipment is required!");
  }
  if (!item.temporaryProcessingFacilityOrEquipment) {
    errors.push("Temporary Processing Facility/Equipment is required!");
  }
  if (!item.continuousOperation) {
    errors.push("Continuous Operation is required!");
  }
  if (!item.batchOperation) {
    errors.push("Batch Operation is required!");
  }
  if (!item.prevMAHRegisterLink) {
    errors.push("Link to Previous Major Accident Hazard Register!");
  }
  if (!item.regulatoryReqsLink) {
    errors.push("Applicable Regulatory Requirements is required!");
  }
  if (!item.contracturalReqsLink) {
    errors.push("Applicable Contractual Requirements is required!");
  }
  if (!item.assumptions) {
    errors.push("Assumptions is required!");
  }
  if (!item.emergencyResponsePlan) {
    errors.push("Emergency Response Plan is required!");
  }
  if (!item.riskWorkshopReportLink) {
    errors.push("Link to Report from Risk Identification Workshop is required!");
  }
  if (!item.hazards.length) {
    errors.push("Major Accident Hazards are required!");
  }

  if (errors.length) {
    throw new Error(errors.join(' '));
  }

  item.hazards.forEach(x => {
    try {
      if (!x.lineItemNumber) {
        errors.push("lineItemNumber is required!");
      }
      validateRiskLineItem(x, true);
    } catch (err) {
      const originalError = getResponseErrorMessage(err);

      const lineItemDisplay = [item.mahruidPrefixed, x.lineItemNumber]
        .filter(x => x)
        .join('-');

      throw new Error(`Hazard ${lineItemDisplay} has an error: ${originalError}`);
    }
  });
}

function* deleteRiskItemAsync() {
  yield takeLatest(deleteRiskItem, function* (action: Action) {
    if (!deleteRiskItem.match(action)) {
      return;
    }

    try {
      const riskItem: IRiskItem = yield select((store: RootState) => store.manageRisk.riskItem);

      if (!riskItem) {
        throw new Error("No risk item data is loaded to delete!");
      } else if (!riskItem.id) {
        throw new Error("The risk item cannot be deleted until it is saved!");
      }

      yield call(RisksApi.deleteRiskItem, riskItem.id);

      yield put(showSuccessToast("Entry deleted."));
      yield put(finishDeletingRiskItem({
        isWorking: false,
      }));

      history.push(Routes.RiskList);
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishDeletingRiskItem({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}
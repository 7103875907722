declare global {
  interface Window {
    __settings: {
      authConfig: {
        tenantId: string,
        clientId: string,
        redirectUri: string,
        appScope: string,
      },
      endpoints: {
        riskItems: string,
        riskLineItems: string,
        users: {
          myProfile: string,
          searchADUsers: string,
        },
        masterData: {
          phases: string,
          validityPeriods: string,
          otherLocations: string,
          standards: string,
          situations: string,
          pointsOfRelease: string,
          hazards: string,
          hazardDescriptions: string,
          basins: string,
          divisions: string,
          countries: string,
          facilities: string,
        },
      },
      serviceWorker: {
        isEnabled: boolean,
      },
      appInsights: {
        instrumentationKey: string,
      },
    },
  }
}

const config = window.__settings || {};

// Parse the values that are not strings.
config.serviceWorker.isEnabled = parseAsBoolean(config.serviceWorker.isEnabled, "indexedDb.dropCreateOnVersionDiff");

validateObjNoPlaceholders(config, 'config');

function validateObjNoPlaceholders(obj: any, keyPath: string) {
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj)
      .forEach(key => {
        validateObjNoPlaceholders(obj[key], keyPath + `.${key}`);
      });
  } else if (obj !== null
    && typeof obj === "string"
    && obj.toString().indexOf('#{') === 0) {
    throw new Error(`The setting named '${keyPath}' in settings.json is a placeholder and must be overwritten by a similar entry in local.settings.json.`);
  }
}

function parseAsBoolean(value: any, settingName: string) {
  if (value === ""
    || value === undefined
    || value === null) {
    throw new Error(`The setting named '${settingName}' is empty, null, or undefined. Please check settings.json and your local.settings.json file.`);
  }

  return value.toLowerCase() === "true";
}

export default config;

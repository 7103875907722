import React, { useState } from "react";
import "./UserBadge.scoped.scss";
import arrowDownIcon from "shared/media/dls/arrow-down-2.svg";
import profileIcon from "shared/media/dls/profile.svg";
import { useMsal } from "@azure/msal-react";

const UserBadge: React.FC = () => {
  const { accounts, instance } = useMsal();
  const user = accounts[0];
  const [isOpen, setIsOpen] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <span
      className="badge"
      onClick={() => setIsOpen(!isOpen)}
    >
      <label>
        {user.username}
      </label>
      <img
        className="down-arrow"
        src={arrowDownIcon}
        alt=""
      />
      <img
        className="profile-image"
        src={profileIcon}
        alt=""
      />
      {isOpen &&
        <div
          className="menu"
        >
          <span
            onClick={() => instance.logoutRedirect()}
          >
            Log Out
          </span>
        </div>
      }
    </span>
  )
};

export default UserBadge;
import { useMsal } from "@azure/msal-react";
import UsersApi from "api/users-api/UsersApi";
import React, { useEffect, useState } from "react";
import PreAuthScreen from "shared/components/auth/PreAuthScreen";
import { IOperation } from "shared/types/operationTypes";
import { setCurrentUser } from "store/current-user/CurrentUserSlice";
import { useAppDispatch } from "store/store";

const AppStartup: React.FC<{ children: React.ReactNode }> = (props) => {
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const user = accounts[0];
  const [authOperation, setAuthOperation] = useState<IOperation<boolean>>({
    isWorking: true,
  });

  const myEmail = user.username || "";

  useEffect(() => {
    let aborted = false;

    const getProfile = async () => {
      try {
        // If this throws an error, that means the user isn't authorized to use this module.
        const myProfile = await UsersApi.getMyProfile(myEmail);

        dispatch(setCurrentUser(myProfile));

        if (!aborted) {
          setAuthOperation({
            isWorking: false,
            data: true,
          });
        }
      } catch (err: any) {
        if (!aborted) {
          setAuthOperation({
            isWorking: false,
            errorMessage: err.message === "Profile not found."
              ? "Unforunately, you are not authorized to use this Quest module. If you require access, please contact an administrator."
              : err.message,
          })
        }
      }
    }

    getProfile();

    return () => {
      aborted = true;
    };
  }, [myEmail, dispatch]);

  if (authOperation.isWorking) {
    return (
      <PreAuthScreen
        message="Authorizing..."
      />
    );
  } else if (authOperation.errorMessage) {
    return (
      <PreAuthScreen
        message={authOperation.errorMessage}
      />
    );
  } else {
    return (
      <>
        {props.children}
      </>
    );
  }
};

export default AppStartup;
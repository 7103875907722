import React from "react";
import Note from "shared/components/common/note/Note";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import RadioButtonSet from "shared/components/controls/radio-button-set/RadioButtonSet";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { ManageRiskCards, toggleCardState, updateRiskProperties } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { YesNo } from "types/answerTypes";
import "./HazardIdentificationCard.scoped.scss";

const HazardIdentificationCard: React.FC = () => {
  const riskItem = useAppSelector(store => store.manageRisk.riskItem);
  const cardStates = useAppSelector(store => store.manageRisk.cardStates);
  const isViewOnly = useAppSelector(store => store.manageRisk.isViewOnly);
  const dispatch = useAppDispatch();

  if (!riskItem) {
    return null;
  }

  const isOpen = cardStates.find(x => x.card === ManageRiskCards.hazardIdentification)?.isOpen ?? false;

  return (
    <Card
      title="Hazard Identification Information on hand at time of Analysis"
      headerElement={
        <CollapseSwitch
          isOpen={isOpen}
          onClick={() => dispatch(toggleCardState({
            card: ManageRiskCards.hazardIdentification,
            isOpen: !isOpen,
          }))}
        />
      }
      isCollapsed={!isOpen}
    >
      <FlexCol>
        <FlexRow
          className="row-to-col"
        >
          <LabeledControl
            label="Permanent Processing Facility/Equipment"
            isRequired={true}
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={riskItem.permanentProcessingFacilityOrEquipment}
              allowClickToDisable={true}
              onChange={(val) => dispatch(updateRiskProperties({
                permanentProcessingFacilityOrEquipment: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Temporary Processing Facility/Equipment"
            isRequired={true}
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={riskItem.temporaryProcessingFacilityOrEquipment}
              allowClickToDisable={true}
              onChange={(val) => dispatch(updateRiskProperties({
                temporaryProcessingFacilityOrEquipment: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Continuous"
            isRequired={true}
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={riskItem.continuousOperation}
              allowClickToDisable={true}
              onChange={(val) => dispatch(updateRiskProperties({
                continuousOperation: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Batch"
            isRequired={true}
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={riskItem.batchOperation}
              allowClickToDisable={true}
              onChange={(val) => dispatch(updateRiskProperties({
                batchOperation: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Well Control Risk?"
            isRequired={true}
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={riskItem.wellControlRisk}
              allowClickToDisable={true}
              onChange={(val) => dispatch(updateRiskProperties({
                wellControlRisk: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Existing Process?"
            isRequired={true}
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={riskItem.existingProcess}
              allowClickToDisable={true}
              onChange={(val) => dispatch(updateRiskProperties({
                existingProcess: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>
        </FlexRow>

        <FlexRow
          className="row-to-col"
        >
          <LabeledControl
            label="Existing Procedures"
            isRequired={riskItem.existingProcess === YesNo.Yes}
          >
            <textarea
              value={riskItem.existingProcedures || ""}
              onChange={(e) => dispatch(updateRiskProperties({
                existingProcedures: e.currentTarget.value,
              }))}
              maxLength={2000}
              disabled={isViewOnly}
            />
            <Note>- Only required if the "Existing Process?" question is answered Yes</Note>
            <Note>- Enter N/A if Document is not available</Note>
          </LabeledControl>

          <LabeledControl
            label="Similar Experience"
            isRequired={true}
          >
            <textarea
              value={riskItem.similarExperience || ""}
              onChange={(e) => dispatch(updateRiskProperties({
                similarExperience: e.currentTarget.value,
              }))}
              maxLength={2000}
              disabled={isViewOnly}
            />
            <Note>- Enter N/A if Document is not available</Note>
          </LabeledControl>

          <LabeledControl
            label="Process Flow Diagram"
            isRequired={true}
          >
            <textarea
              value={riskItem.processFlowDiagram || ""}
              onChange={(e) => dispatch(updateRiskProperties({
                processFlowDiagram: e.currentTarget.value,
              }))}
              maxLength={2000}
              disabled={isViewOnly}
            />
            <Note>- Enter N/A if Document is not available</Note>
          </LabeledControl>

          <LabeledControl
            label="P&amp;ID"
            isRequired={true}
          >
            <textarea
              value={riskItem.PandID || ""}
              onChange={(e) => dispatch(updateRiskProperties({
                PandID: e.currentTarget.value,
              }))}
              maxLength={2000}
              disabled={isViewOnly}
            />
            <Note>- Enter N/A if Document is not available</Note>
          </LabeledControl>

          <LabeledControl
            label="Operating History"
            isRequired={true}
          >
            <textarea
              value={riskItem.operatingHistory || ""}
              onChange={(e) => dispatch(updateRiskProperties({
                operatingHistory: e.currentTarget.value,
              }))}
              maxLength={2000}
              disabled={isViewOnly}
            />
            <Note>- Enter N/A if Document is not available</Note>
          </LabeledControl>
        </FlexRow>
      </FlexCol>
    </Card>
  )
};

export default HazardIdentificationCard;
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import AppStartup from 'components/app-startup/AppStartup';
import Routing, { Routes } from 'components/routing/Routing';
import { createBrowserHistory } from "history";
import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import ToastContainer from 'shared/components/common/toasts/ToastContainer';
import MainLayout from 'shared/components/layout/MainLayout';
import addIcon from "shared/media/dls/add.svg";
import listViewIcon from "shared/media/dls/list-view.svg";
import { reactPlugin } from "./AppInsights";

export const history = createBrowserHistory();

const App: React.FC = () =>
  <AppInsightsContext.Provider value={reactPlugin}>
    <AppStartup>
      <CustomRouter
        history={history}
      >
        <MainLayout
          siteTitle="Process Safety - Major Accident Hazard Risk Register"
          siteTitleUrl={Routes.RiskList}
          menuItems={[
            {
              key: "Create MAH",
              label: "Create MAH",
              route: Routes.NewRiskReport,
              icon: addIcon,
            },
            {
              key: "MAH List",
              label: "MAH List",
              route: Routes.RiskList,
              icon: listViewIcon,
            },
          ]}
        >
          <ToastContainer
            reduxReducerName="toast"
          />
          <Routing />
        </MainLayout>
      </CustomRouter>
    </AppStartup>
  </AppInsightsContext.Provider>;
export default App;

interface ICustomRouterProps {
  history: any,
  children?: React.ReactNode,
}

const CustomRouter: React.FC<ICustomRouterProps> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
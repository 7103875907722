import React from "react";
import logoIcon from "shared/media/icons/logo-icon.svg";
import "./PreAuthScreen.scoped.scss";

interface IPreAuthScreenProps {
  message: React.ReactNode,
}

const PreAuthScreen: React.FC<IPreAuthScreenProps> = ({
  message,
}) =>
  <div
    className="login-page"
  >
    <div
      className="logo-row"
    >
      <img
        alt=""
        src={logoIcon}
        className="logo-icon"
      />

      <span
        className="site-title"
      >
        Process Safety - Major Accident Hazard Risk Register
      </span>
    </div>

    <div
      className="prompt"
    >
      {message}
    </div>
  </div>;

export default PreAuthScreen;
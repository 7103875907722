import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import pickerHandlers, { getInitialPickerState, ISetPickerSelectedItemsAction } from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import { IBasinItem, IBusinessItem, ICountry, IFacility, IHazard, IHazardDescription, IOtherLocation, IPhase, IPointOfRelease, IRiskStandard, ISituationIncidentOrEventType, IValidityPeriod } from "types/masterDataTypes";
import { IRiskBasinItem, IRiskBusinessItem, IRiskHazardItem, IRiskItem } from "types/riskTypes";

export interface IManageRiskState {
  pickerData: {
    facilities: IPickerState<IFacility>,
    countries: IPickerState<ICountry>,
    businesses: IPickerState<IBusinessItem>,
    basins: IPickerState<IBasinItem>,
  },
  validityPeriods: IOperation<IValidityPeriod[]>,
  phases: IOperation<IPhase[]>,
  otherLocations: IOperation<IOtherLocation[]>,
  riskStandards: IOperation<IRiskStandard[]>,
  hazards: IOperation<IHazard[]>,
  eventTypes: IOperation<ISituationIncidentOrEventType[]>,
  hazardDescriptions: IOperation<IHazardDescription[]>,
  pointsOfRelease: IOperation<IPointOfRelease[]>,

  riskItem?: IRiskItem,
  originalRiskItem?: IRiskItem,
  isDirty: boolean,
  isViewOnly: boolean,
  loadRiskItemOp?: IOperation<IRiskItem>,
  saveRiskItemOp?: IOperation<IRiskItem>,
  deleteRiskItemOp?: IOperation<void>,

  isLeaderEditorOpen: boolean,
  isTeamEditorOpen: boolean,
  isReporterEditorOpen: boolean,
  isConfirmDeleteVisible: boolean,

  cardStates: IManageRiskCardState[],

  mahModal: {
    isOpen: boolean,
    hazardItem: IRiskHazardItem | undefined,
  },
}

export interface IManageRiskCardState {
  card: ManageRiskCards,
  isOpen: boolean
}

export enum ManageRiskPickerKeys {
  facilities = "facilities",
  countries = "countries",
  businesses = "businesses",
  basins = "basins",
}

export enum ManageRiskCards {
  process,
  references,
  hazardIdentification,
  majorAccidentHazards,
}

const initialState: IManageRiskState = {
  pickerData: {
    facilities: getInitialPickerState(ManageRiskPickerKeys.facilities),
    countries: getInitialPickerState(ManageRiskPickerKeys.countries),
    businesses: getInitialPickerState(ManageRiskPickerKeys.businesses),
    basins: getInitialPickerState(ManageRiskPickerKeys.basins),
  },
  validityPeriods: {
    isWorking: false,
  },
  phases: {
    isWorking: false,
  },
  otherLocations: {
    isWorking: false,
  },
  riskStandards: {
    isWorking: false,
  },
  hazards: {
    isWorking: false,
  },
  eventTypes: {
    isWorking: false,
  },
  hazardDescriptions: {
    isWorking: false,
  },
  pointsOfRelease: {
    isWorking: false,
  },

  riskItem: getDefaultRiskItem(),
  originalRiskItem: getDefaultRiskItem(),
  isDirty: false,
  isViewOnly: true,
  loadRiskItemOp: undefined,
  saveRiskItemOp: undefined,
  deleteRiskItemOp: undefined,

  isLeaderEditorOpen: false,
  isTeamEditorOpen: false,
  isReporterEditorOpen: false,
  isConfirmDeleteVisible: false,

  cardStates: [
    {
      card: ManageRiskCards.process,
      isOpen: true,
    },
    {
      card: ManageRiskCards.references,
      isOpen: false,
    },
    {
      card: ManageRiskCards.hazardIdentification,
      isOpen: false,
    },
    {
      card: ManageRiskCards.majorAccidentHazards,
      isOpen: false,
    },
  ],

  mahModal: {
    isOpen: false,
    hazardItem: undefined,
  },
};

export const manageRiskSlice = createSlice({
  name: "manage-risk",
  initialState,
  reducers: {
    ...pickerHandlers,
    setSelectedPickerItems: (state, action: PayloadAction<ISetPickerSelectedItemsAction<any>>) => {
      if (!state.riskItem) {
        return;
      }

      // Call the base method too.
      pickerHandlers.setSelectedPickerItems(state, action);

      setRiskItemPickerItems(state.riskItem,
        action.payload.pickerKey,
        action.payload.selectedItems);

      state.isDirty = calculateIsDirty(state);
    },
    resetRiskStore: (state) => {
      Object.assign(state, cloneDeep(initialState));
    },
    loadRiskItem: (state, _: PayloadAction<string>) => {
      state.loadRiskItemOp = {
        isWorking: true,
      };
    },
    finishLoadingRiskItem: (state, action: PayloadAction<IOperation<IRiskItem>>) => {
      if (action.payload.errorMessage) {
        state.loadRiskItemOp = action.payload;
        return;
      }

      state.loadRiskItemOp = undefined;
      state.riskItem = cloneDeep(action.payload.data);
      state.originalRiskItem = cloneDeep(action.payload.data);
      state.isDirty = calculateIsDirty(state);
      state.cardStates.forEach(x => x.isOpen = false);
    },
    setIsViewOnly: (state, action: PayloadAction<boolean>) => {
      state.isViewOnly = action.payload;
    },
    saveRiskItem: (state) => {
      state.saveRiskItemOp = {
        isWorking: true,
      };
    },
    finishSavingRiskItem: (state, action: PayloadAction<IOperation<Partial<IRiskItem>>>) => {
      state.saveRiskItemOp = undefined;

      if (action.payload.errorMessage) {
        return;
      }

      if (action.payload.data) {
        state.riskItem = Object.assign({}, state.riskItem, action.payload.data);
      }

      state.originalRiskItem = cloneDeep(state.riskItem);
      state.isDirty = false;
    },
    updateRiskProperties: (state, action: PayloadAction<Partial<IRiskItem>>) => {
      if (!state.riskItem) {
        return;
      }

      Object.assign(state.riskItem, action.payload);
      state.isDirty = calculateIsDirty(state);
    },
    toggleDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.isConfirmDeleteVisible = action.payload;
    },
    deleteRiskItem: (state) => {
      state.deleteRiskItemOp = {
        isWorking: true,
      };
    },
    finishDeletingRiskItem: (state, action: PayloadAction<IOperation<void>>) => {
      state.deleteRiskItemOp = undefined;

      if (action.payload.errorMessage) {
        return;
      }

      state.isDirty = false;
    },
    removeHazardItem: (state, action: PayloadAction<number>) => {
      if (!state.riskItem) {
        return;
      }

      state.riskItem.hazards = state.riskItem.hazards
        .filter(x => x.lineItemNumber !== action.payload);
      state.isDirty = calculateIsDirty(state);
    },
    updateCurrentHazardItemProperties: (state, action: PayloadAction<Partial<IRiskHazardItem>>) => {
      if (!state.mahModal.hazardItem) {
        return;
      }

      const oldHazard = state.mahModal.hazardItem;
      const newHazard = action.payload.hazard;


      if (newHazard
        && newHazard !== state.mahModal.hazardItem.hazard) {
        oldHazard.volume = newHazard.volume;
        oldHazard.source = newHazard.sources;

        if (oldHazard.volume === "N/A") {
          oldHazard.maxReleaseAboveTier1Threshold = undefined;
        }
      }

      Object.assign(state.mahModal.hazardItem, action.payload);
    },
    loadValidityPeriods: (state) => {
      state.validityPeriods = {
        isWorking: true,
      };
    },
    finishLoadingValidityPeriods: (state, action: PayloadAction<IOperation<IValidityPeriod[]>>) => {
      state.validityPeriods = action.payload;
    },
    loadPhases: (state) => {
      state.phases = {
        isWorking: true,
      };
    },
    finishLoadingPhases: (state, action: PayloadAction<IOperation<IPhase[]>>) => {
      state.phases = action.payload;
    },
    loadOtherLocations: (state) => {
      state.otherLocations = {
        isWorking: true,
      };
    },
    finishLoadingOtherLocations: (state, action: PayloadAction<IOperation<IOtherLocation[]>>) => {
      state.otherLocations = action.payload;
    },
    loadRiskStandards: (state) => {
      state.riskStandards = {
        isWorking: true,
      };
    },
    finishLoadingRiskStandards: (state, action: PayloadAction<IOperation<IOtherLocation[]>>) => {
      state.riskStandards = action.payload;
    },
    loadHazards: (state) => {
      state.hazards = {
        isWorking: true,
      };
    },
    finishLoadingHazards: (state, action: PayloadAction<IOperation<IHazard[]>>) => {
      state.hazards = action.payload;
    },
    loadEventTypes: (state) => {
      state.eventTypes = {
        isWorking: true,
      };
    },
    finishLoadingEventTypes: (state, action: PayloadAction<IOperation<ISituationIncidentOrEventType[]>>) => {
      state.eventTypes = action.payload;
    },
    loadHazardDescriptions: (state) => {
      state.hazardDescriptions = {
        isWorking: true,
      };
    },
    finishLoadingHazardDescriptions: (state, action: PayloadAction<IOperation<IHazardDescription[]>>) => {
      state.hazardDescriptions = action.payload;
    },
    loadPointsOfRelease: (state) => {
      state.pointsOfRelease = {
        isWorking: true,
      };
    },
    finishLoadingPointsOfRelease: (state, action: PayloadAction<IOperation<IPointOfRelease[]>>) => {
      state.pointsOfRelease = action.payload;
    },
    toggleLeaderEditorVisibility: (state, action: PayloadAction<boolean>) => {
      state.isLeaderEditorOpen = action.payload;
    },
    toggleTeamEditorVisibility: (state, action: PayloadAction<boolean>) => {
      state.isTeamEditorOpen = action.payload;
    },
    toggleReporterEditorVisibility: (state, action: PayloadAction<boolean>) => {
      state.isReporterEditorOpen = action.payload;
    },
    toggleCardState: (state, action: PayloadAction<IManageRiskCardState>) => {
      const existingState = state.cardStates.find(x => x.card === action.payload.card);

      if (existingState) {
        existingState.isOpen = action.payload.isOpen;
      } else {
        state.cardStates.push(action.payload);
      }
    },
    showNewMAHModal: (state) => {
      state.mahModal.isOpen = true;
      state.mahModal.hazardItem = getDefaultHazardItem(0);
    },
    viewHazardItem: (state, action: PayloadAction<number>) => {
      const item = state.riskItem?.hazards.find(x => x.lineItemNumber === action.payload);

      if (!item) {
        return;
      }

      state.mahModal.isOpen = true;
      state.mahModal.hazardItem = cloneDeep(item);
    },
    cancelMAHModal: (state) => {
      state.mahModal.isOpen = false;
      state.mahModal.hazardItem = undefined;
    },
    saveCurrentHazardItem: (state) => {
      if (!state.riskItem
        || !state.mahModal.hazardItem) {
        return;
      }

      if (state.mahModal.hazardItem.lineItemNumber === 0) {
        state.mahModal.hazardItem.lineItemNumber = state.riskItem.hazards.length === 0
          ? 1
          : Math.max.apply(null, state.riskItem.hazards.map(x => x.lineItemNumber)) + 1;

        state.riskItem.hazards.push(state.mahModal.hazardItem);
      } else {
        const ix = state.riskItem.hazards
          .findIndex(x => x.lineItemNumber === state.mahModal.hazardItem?.lineItemNumber);

        if (ix === -1) {
          return;
        }

        state.riskItem.hazards.splice(ix, 1, state.mahModal.hazardItem);
      }

      state.mahModal.hazardItem = undefined;
      state.mahModal.isOpen = false;
      state.isDirty = calculateIsDirty(state);
    },
  },
});

export const {
  openPicker,
  closePicker,
  loadPickerItems,
  setPickerError,
  setPickerItems,
  setSelectedPickerItems,
  expandPickerItem,
  collapsePickerItem,

  resetRiskStore,
  loadRiskItem,
  finishLoadingRiskItem,
  setIsViewOnly,
  saveRiskItem,
  finishSavingRiskItem,
  updateRiskProperties,
  toggleDeleteConfirmation,
  deleteRiskItem,
  finishDeletingRiskItem,

  toggleLeaderEditorVisibility,
  toggleTeamEditorVisibility,
  toggleReporterEditorVisibility,

  toggleCardState,

  showNewMAHModal,
  viewHazardItem,
  cancelMAHModal,
  saveCurrentHazardItem,

  removeHazardItem,
  updateCurrentHazardItemProperties,

  // Dropdown loaders.
  loadValidityPeriods,
  finishLoadingValidityPeriods,
  loadPhases,
  finishLoadingPhases,
  loadOtherLocations,
  finishLoadingOtherLocations,
  loadRiskStandards,
  finishLoadingRiskStandards,
  loadHazards,
  finishLoadingHazards,
  loadEventTypes,
  finishLoadingEventTypes,
  loadPointsOfRelease,
  finishLoadingPointsOfRelease,
  loadHazardDescriptions,
  finishLoadingHazardDescriptions,
} = manageRiskSlice.actions;

function getDefaultRiskItem(): IRiskItem {
  return {
    id: "",
    processOperationUID: "",
    mahruid: 0,
    mahruidPrefixed: "",
    dateOfAssessment: new Date().getTime(),
    hazards: [],
  };
}

function getDefaultHazardItem(lineItemNumber: number): IRiskHazardItem {
  return {
    lineItemNumber,
  };
}

function setRiskItemPickerItems(riskItem: IRiskItem,
  pickerKey: string,
  selectedItems: IPickerItem<any>[]) {

  if (pickerKey === ManageRiskPickerKeys.businesses) {
    riskItem.divisionHierarchy = mapChangePickerItems<IBusinessItem, IRiskBusinessItem>(selectedItems,
      undefined,
      item => ({
        id: item.id,
        name: item.name,
        code: item.code,
        type: item.type,
      }));
  } else if (pickerKey === ManageRiskPickerKeys.basins) {
    riskItem.basinHierarchy = mapChangePickerItems<IBasinItem, IRiskBasinItem>(selectedItems,
      undefined,
      item => ({
        id: item.id,
        name: item.name,
        code: item.code,
        type: item.type,
      }));
  } else if (pickerKey === ManageRiskPickerKeys.countries) {
    const chosenCountries = mapPickerItems<ICountry>(selectedItems);
    riskItem.country = chosenCountries.length
      ? chosenCountries[0]
      : undefined;
  } else if (pickerKey === ManageRiskPickerKeys.facilities) {
    const chosenFacilities = mapPickerItems<IFacility>(selectedItems);
    riskItem.facility = chosenFacilities.length
      ? chosenFacilities[0]
      : undefined;
  }
}

function mapPickerItems<InputType>(selectedItems: IPickerItem<InputType>[],
  filter?: (item: InputType) => boolean): InputType[] {
  if (!filter) {
    return selectedItems
      .map(x => x.item as InputType);
  }

  return selectedItems
    .filter(x => x.item
      && filter(x.item))
    .map(x => x.item as InputType);
}

function mapChangePickerItems<InputType, OutputType>(selectedItems: IPickerItem<InputType>[],
  filter: ((item: InputType) => boolean) | undefined,
  mapper: (item: InputType) => OutputType): OutputType[] {
  if (!filter) {
    return selectedItems
      .map(x => mapper(x.item as InputType));
  }

  return selectedItems
    .filter(x => x.item
      && filter(x.item))
    .map(x => mapper(x.item as InputType));
}

function calculateIsDirty(state: IManageRiskState): boolean {
  return !isEqual(state.riskItem, state.originalRiskItem);
}

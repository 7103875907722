import GroupedDropdown, { IGroupedDropdownItem } from "components/controls/grouped-dropdown/GroupedDropdown";
import React, { useState } from "react";
import Dropdown from "shared/components/controls/dropdown/Dropdown";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import RadioButtonSet from "shared/components/controls/radio-button-set/RadioButtonSet";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { cancelMAHModal, loadEventTypes, loadHazardDescriptions, loadHazards, loadPointsOfRelease, saveCurrentHazardItem, updateCurrentHazardItemProperties } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { YesNo } from "types/answerTypes";
import { IHazard, IPointOfRelease } from "types/masterDataTypes";
import { RiskMatrixCellColors } from "types/riskTypes";
import { validateRiskLineItem } from "utilities/riskUtilities";
import "./MAHModal.scoped.scss";
import RiskMatrixModal from "./risk-matrix-modal/RiskMatrixModal";

const MAHModal: React.FC = () => {
  const {
    riskItem,
    hazards,
    hazardDescriptions,
    eventTypes,
    pointsOfRelease,
    isViewOnly,
    mahModal: {
      hazardItem,
    },
  } = useAppSelector(store => store.manageRisk);
  const dispatch = useAppDispatch();
  const [matrixType, setMatrixType] = useState<"Potential" | "Residual" | undefined>();

  if (!riskItem
    || !hazardItem) {
    return null;
  }

  const trySaveHazardItem = () => {
    try {
      validateRiskLineItem(hazardItem, false);
    } catch (err: any) {
      dispatch(showErrorToast(err.toString()));
      return;
    }

    dispatch(saveCurrentHazardItem());
  }

  return (
    <Modal
      minWidth="75%"
      header={
        <FlexCol>
          <FlexRow
            className="title-row"
          >
            MAH Entry
          </FlexRow>
          <FlexRow
            className="title-row"
          >
            <span>
              <LabeledControl
                label="Hazard"
              >
                {hazardItem.lineItemNumber === 0
                  ? "Not yet saved"
                  : hazardItem.lineItemNumber
                }
              </LabeledControl>
            </span>
            <span
              className="right"
            >
              <LabeledControl
                label="Major Accident Hazard Register UID"
              >
                {riskItem.mahruidPrefixed || "Not yet saved"}
              </LabeledControl>
            </span>
          </FlexRow>
        </FlexCol>
      }
      isOpen={true}
      buttons={[{
        key: "cancel",
        text: "cancel",
        className: "secondary",
        onClick: () => dispatch(cancelMAHModal()),
      }, {
        key: "save",
        text: "save",
        className: "primary",
        onClick: trySaveHazardItem,
        disabled: isViewOnly
      }]}
    >
      <FlexCol>
        <FlexCol>
          <label
            className="section-title"
          >
            Hazard Identification
          </label>

          <FlexRow
            className="row-to-col"
          >
            <LabeledControl
              label="Hazard"
              isRequired={true}
            >
              <GroupedDropdown
                loadOperation={{
                  ...hazards,
                  data: hazards.data?.map((x: IHazard): IGroupedDropdownItem => ({
                    value: x.id,
                    parentValue: hazards.data?.find(z => z.number === x.parent)?.id || "",
                    text: `${x.number} - ${x.description}`,
                  }))
                }}
                isDisabled={isViewOnly}
                loadItems={() => dispatch(loadHazards())}
                onValueChanged={e => dispatch(updateCurrentHazardItemProperties({
                  hazard: e
                    ? hazards.data?.find(x => x.id === e.value)
                    : undefined,
                }))}
                selectedId={hazardItem.hazard?.id}
                allowSelectGroup={false}
              />
            </LabeledControl>

            <LabeledControl
              label="Hazard Description"
              isRequired={true}
            >
              <Dropdown
                isLoading={hazardDescriptions.isWorking}
                loadError={hazardDescriptions.errorMessage}
                items={hazardDescriptions.data || []}
                selectedItem={hazardItem.hazardDescription}
                onChange={value => dispatch(updateCurrentHazardItemProperties({
                  hazardDescription: value,
                }))}
                keyMapper={item => item.id.toString()}
                textMapper={item => item.name}
                loadItems={() => !hazardDescriptions.data?.length
                  ? dispatch(loadHazardDescriptions())
                  : []
                }
                autoLoadItems={true}
                isDisabled={isViewOnly}
              />
            </LabeledControl>
            <LabeledControl
              label="Source"
              isRequired={true}
            >
              <textarea
                value={hazardItem.source || ""}
                onChange={e => dispatch(updateCurrentHazardItemProperties({
                  source: e.currentTarget.value,
                }))}
                maxLength={500}
                disabled={isViewOnly}
              />
            </LabeledControl>
            <LabeledControl
              label="Tier1 Threshold (1 hour release)"
              isRequired={true}
            >
              <textarea
                value={hazardItem.volume || ""}
                onChange={e => dispatch(updateCurrentHazardItemProperties({
                  volume: e.currentTarget.value,
                }))}
                maxLength={500}
                disabled={true}
              />
            </LabeledControl>

            <LabeledControl
              label="Max. Release Above Tier 1 Threshold?"
              isRequired={true}
            >
              <RadioButtonSet
                options={Object.entries(YesNo).map(val => ({
                  value: val[0],
                  text: val[0],
                }))}
                selectedValue={hazardItem.maxReleaseAboveTier1Threshold}
                allowClickToDisable={true}
                onChange={val => dispatch(updateCurrentHazardItemProperties({
                  maxReleaseAboveTier1Threshold: val === undefined
                    ? undefined
                    : YesNo[val as YesNo],
                }))}
                isDisabled={isViewOnly
                  || hazardItem.volume === "N/A"
                }
              />
            </LabeledControl>

            <LabeledControl
              label="Point of Release"
              isRequired={true}
            >
              <GroupedDropdown
                loadOperation={{
                  ...pointsOfRelease,
                  data: pointsOfRelease.data?.map((x: IPointOfRelease): IGroupedDropdownItem => ({
                    value: x.id,
                    parentValue: x.parentId || "",
                    text: x.name,
                  }))
                }}
                isDisabled={isViewOnly}
                loadItems={() => dispatch(loadPointsOfRelease())}
                onValueChanged={e => dispatch(updateCurrentHazardItemProperties({
                  pointOfRelease: e
                    ? pointsOfRelease.data?.find(x => x.id === e.value)
                    : undefined,
                }))}
                selectedId={hazardItem.pointOfRelease?.id}
                allowSelectGroup={false}
                finalSortGroups={options => {
                  const otherIx = options.findIndex(x => x.text === "Other Point of Release");
                  if (otherIx > -1) {
                    let arrCopy = options.slice();
                    let removedItem = arrCopy.splice(otherIx, 1);
                    arrCopy.push(removedItem[0]);
                    return arrCopy;
                  }
                  return options;
                }}
              />
            </LabeledControl>

            <LabeledControl
              label="Other Point of Release Description"
              isRequired={hazardItem.pointOfRelease?.name === "Other Point of Release"}
            >
              <textarea
                value={hazardItem.otherPointOfReleaseDescription || ""}
                onChange={e => dispatch(updateCurrentHazardItemProperties({
                  otherPointOfReleaseDescription: e.currentTarget.value,
                }))}
                maxLength={500}
                disabled={isViewOnly}
              />
            </LabeledControl>

            <LabeledControl
              label="Comments"
              isRequired={true}
            >
              <textarea
                value={hazardItem.comments || ""}
                onChange={e => dispatch(updateCurrentHazardItemProperties({
                  comments: e.currentTarget.value,
                }))}
                maxLength={1000}
                disabled={isViewOnly}
              />
            </LabeledControl>
          </FlexRow>
        </FlexCol>

        <FlexCol
          className="hira-tools"
        >
          <label
            className="section-title"
          >
            HIRA Tools Used
          </label>

          <LabeledControl
            label="Relative Ranking (RR)"
            isRequired={true}
            className="horizontal"
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={hazardItem.relativeRanking}
              allowClickToDisable={true}
              onChange={val => dispatch(updateCurrentHazardItemProperties({
                relativeRanking: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="What If Analysis (WI)"
            isRequired={true}
            className="horizontal"
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={hazardItem.whatIfAnalysis}
              allowClickToDisable={true}
              onChange={val => dispatch(updateCurrentHazardItemProperties({
                whatIfAnalysis: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Safety Review (SR)"
            isRequired={true}
            className="horizontal"
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={hazardItem.safetyReview}
              allowClickToDisable={true}
              onChange={val => dispatch(updateCurrentHazardItemProperties({
                safetyReview: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Preliminary Hazard Analysis (PreHA)"
            isRequired={true}
            className="horizontal"
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={hazardItem.prelimHazardAnalysis}
              allowClickToDisable={true}
              onChange={val => dispatch(updateCurrentHazardItemProperties({
                prelimHazardAnalysis: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Checklist"
            isRequired={true}
            className="horizontal"
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={hazardItem.checklist}
              allowClickToDisable={true}
              onChange={val => dispatch(updateCurrentHazardItemProperties({
                checklist: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Other"
            isRequired={true}
            className="horizontal"
          >
            <RadioButtonSet
              options={Object.entries(YesNo).map(val => ({
                value: val[0],
                text: val[0],
              }))}
              selectedValue={hazardItem.otherHIRATools}
              allowClickToDisable={true}
              onChange={val => dispatch(updateCurrentHazardItemProperties({
                otherHIRATools: val === undefined
                  ? undefined
                  : YesNo[val as YesNo],
              }))}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Other Description"
            isRequired={hazardItem.otherHIRATools === YesNo.Yes}
            className="horizontal"
          >
            <textarea
              value={hazardItem.otherHIRAToolsDescription || ""}
              onChange={e => dispatch(updateCurrentHazardItemProperties({
                otherHIRAToolsDescription: e.currentTarget.value,
              }))}
              maxLength={250}
              disabled={isViewOnly}
            />
          </LabeledControl>
        </FlexCol>

        <FlexCol>
          <label
            className="section-title"
          >
            Situation / Incident or event of concern
          </label>
          <LabeledControl
            label="Type"
            isRequired={true}
            className="horizontal"
          >
            <Dropdown
              isLoading={eventTypes.isWorking}
              loadError={eventTypes.errorMessage}
              items={eventTypes.data || []}
              selectedItem={hazardItem.type}
              onChange={value => dispatch(updateCurrentHazardItemProperties({
                type: value,
              }))}
              keyMapper={item => item.id.toString()}
              textMapper={item => item.name}
              loadItems={() => !eventTypes.data?.length
                ? dispatch(loadEventTypes())
                : []
              }
              autoLoadItems={true}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Type Description"
            isRequired={true}
            className="horizontal"
          >
            <textarea
              value={hazardItem.typeDescription || ""}
              onChange={e => dispatch(updateCurrentHazardItemProperties({
                typeDescription: e.currentTarget.value,
              }))}
              maxLength={1000}
              disabled={isViewOnly}
            />
          </LabeledControl>
        </FlexCol>

        <FlexCol>
          <label
            className="section-title"
          >
            MAH Risk Matrix
          </label>
          <FlexRow
            className="row-to-col"
          >
            <LabeledControl
              label="Potential Risk"
              isRequired={true}
            >
              <button
                className="secondary"
                onClick={() => setMatrixType("Potential")}
                style={hazardItem.potentialRisk
                  ? {
                    backgroundColor: RiskMatrixCellColors[hazardItem.potentialRisk],
                  } : undefined
                }
              >
                {hazardItem.potentialRisk || "Click to choose"}
              </button>
            </LabeledControl>

            <LabeledControl
              label="Residual Risk"
              isRequired={true}
            >
              <button
                className="secondary"
                onClick={() => setMatrixType("Residual")}
                style={hazardItem.residualRisk
                  ? {
                    backgroundColor: RiskMatrixCellColors[hazardItem.residualRisk],
                  } : undefined
                }
              >
                {hazardItem.residualRisk || "Click to choose"}
              </button>
            </LabeledControl>
          </FlexRow>
        </FlexCol>

        <FlexCol>
          <label
            className="section-title"
          >
            Key Performance Indicators
          </label>
          <LabeledControl
            label="Tier 3"
            className="horizontal"
          >
            <textarea
              value={hazardItem.kpiTier3 || ""}
              onChange={e => dispatch(updateCurrentHazardItemProperties({
                kpiTier3: e.currentTarget.value,
              }))}
              maxLength={100}
              disabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Tier 4"
            className="horizontal"
          >
            <textarea
              value={hazardItem.kpiTier4 || ""}
              onChange={e => dispatch(updateCurrentHazardItemProperties({
                kpiTier4: e.currentTarget.value,
              }))}
              maxLength={100}
              disabled={isViewOnly}
            />
          </LabeledControl>
        </FlexCol>
      </FlexCol>

      {matrixType !== undefined && (
        <RiskMatrixModal
          type={matrixType}
          onCancel={() => setMatrixType(undefined)}
          onValueSelected={(val) => {
            if (matrixType === "Potential") {
              dispatch(updateCurrentHazardItemProperties({
                potentialRisk: val,
              }));
            } else if (matrixType === "Residual") {
              dispatch(updateCurrentHazardItemProperties({
                residualRisk: val,
              }));
            }

            setMatrixType(undefined);
          }}
          isDisabled={isViewOnly}
        />
      )}
    </Modal>
  );
};

export default MAHModal;
import { isArray } from "lodash";
import { BusinessTypes, IBasinItem, IBusinessItem, ICountry, IFacility, IHazard, IHazardDescription, IOtherLocation, IPhase, IPointOfRelease, IRiskStandard, ISituationIncidentOrEventType, IValidityPeriod } from "types/masterDataTypes";

export function formatGetBasinsResponse(obj: any[]): IBasinItem[] {
  let allBasins = obj.map((x): IBasinItem => ({
    children: [],
    code: x.code,
    id: x.id,
    name: x.name,
    type: x.type,
    parent: x.parent,
  }));

  let topLevelBasins: IBasinItem[] = allBasins.filter(x => !x.parent)
    .sort((a, b) => a.code < b.code ? -1 : 1);

  topLevelBasins.forEach(x => {
    assignBasinChildren(x, allBasins);
  });

  return topLevelBasins.sort(sortByCodeProp);
}

function assignBasinChildren(basinItem: IBasinItem, allItems: IBasinItem[]) {
  basinItem.children = allItems.filter(x => x.parent === basinItem.id)
    .sort(sortByCodeProp);
  basinItem.children.forEach(x => {
    assignBasinChildren(x, allItems);
  });
}

export function formatGetDivisionsResponse(obj: any[]): IBusinessItem[] {
  let allDivisions = obj.map((x): IBusinessItem => ({
    children: [],
    code: x.code,
    id: x.id,
    name: x.name,
    type: x.type as BusinessTypes,
    parent: x.parent,
  }));

  let topLevelDivisions: IBusinessItem[] = allDivisions.filter(x => !x.parent)
    .sort(sortByCodeProp);

  topLevelDivisions.forEach(x => {
    assignDivisionChildren(x, allDivisions);
  });

  return topLevelDivisions;
}

function assignDivisionChildren(item: IBusinessItem, allItems: IBusinessItem[]) {
  item.children = allItems.filter(x => x.parent === item.id)
    .sort(sortByCodeProp);
  item.children.forEach(x => {
    assignDivisionChildren(x, allItems);
  });
}

export function formatGetFacilities(obj: any): IFacility[] {
  return (isArray(obj)
    ? obj.map((x: any): IFacility => formatFacility(x))
    : []).sort(sortByNameProp);
}

export function formatFacility(x: any): IFacility {
  return {
    commonId: x.commonId,
    name: x.name,
    id: x.id,
  };
}

export function formatGetCountries(obj: any): ICountry[] {
  return (isArray(obj)
    ? obj.map((x: any): ICountry => formatCountry(x))
    : []).sort(sortByNameProp);
}

export function formatCountry(x: any): ICountry {
  return {
    name: x.name,
    id: x.id,
  };
}

export function formatGetValidityPeriodsResponse(obj: any): IValidityPeriod[] {
  return (isArray(obj)
    ? obj.map((x: any): IValidityPeriod => ({
      name: x.name,
      id: x.id,
    }))
    : []).sort(sortByNameProp);
}

export function formatGetPhasesResponse(obj: any): IPhase[] {
  return (isArray(obj)
    ? obj.map((x: any): IPhase => formatPhase(x))
    : []).sort(sortByNameProp);
}

export function formatPhase(x: any): IPhase {
  return {
    name: x.name,
    id: x.id,
  };
}

export function formatGetOtherLocationsResponse(obj: any): IOtherLocation[] {
  return (isArray(obj)
    ? obj.map((x: any): IOtherLocation => ({
      name: x.name,
      id: x.id,
    }))
    : []).sort(sortByNameProp);
}

export function formatGetRiskStandardsResponse(obj: any): IRiskStandard[] {
  return (isArray(obj)
    ? obj.map((x: any): IRiskStandard => ({
      name: x.name,
      id: x.id,
    }))
    : []).sort(sortByNameProp);
}

export function formatGetSituationsResponse(obj: any): ISituationIncidentOrEventType[] {
  return (isArray(obj)
    ? obj.map((x: any) => formatSituation(x))
    : []).sort(sortByNameProp);
}

export function formatSituation(item: any): ISituationIncidentOrEventType {
  return {
    name: item.name,
    id: item.id,
  };
}

export function formatGetPointsOfReleaseResponse(obj: any): IPointOfRelease[] {
  let data = (isArray(obj)
    ? obj.map((x: any) => formatPointOfRelease(x))
    : []).sort(sortByNameProp);

  return data;
}

export function formatPointOfRelease(item: any): IPointOfRelease {
  return {
    name: item.name,
    id: item.id,
    parentId: item.parentId,
  };
}

export function formatGetHazardsResponse(obj: any): IHazard[] {
  return (isArray(obj)
    ? obj as any[]
    : []).map((x: any): IHazard => formatHazard(x));
}

export function formatHazard(x: any): IHazard {
  return {
    id: x.id,
    number: x.number,
    parent: x.parent,
    description: x.description,
    safety: x.safety,
    health: x.health,
    environmental: x.environmental,
    sources: x.sources,
    volume: x.volume,
  };
}

export function formatGetHazardDescriptionsResponse(obj: any): IHazardDescription[] {
  return (isArray(obj)
    ? obj as any[]
    : []).map((x: any): IHazardDescription => formatHazardDescription(x));
}

export function formatHazardDescription(x: any): IHazardDescription {
  return {
    id: x.id,
    name: x.name,
  };
}

function sortByNameProp(a: { name: string }, b: { name: string }): number {
  return (a.name || "").toLowerCase() < (b.name || "").toLowerCase()
    ? -1
    : 1;
}

function sortByCodeProp(a: { code: string }, b: { code: string }): number {
  return (a.code || "").toLowerCase() < (b.code || "").toLowerCase()
    ? -1
    : 1;
}
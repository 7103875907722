import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Card from "shared/components/layout/card/Card";
import { showNewMAHModal } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./HazardListCard.scoped.scss";
import MAHModal from "./MAHModal/MAHModal";
import MAHTableHeader from "./table/MAHTableHeader";
import MAHTableRow from "./table/MAHTableRow";

const HazardListCard: React.FC = () => {
  const riskItem = useAppSelector(store => store.manageRisk.riskItem);
  const isViewOnly = useAppSelector(store => store.manageRisk.isViewOnly);
  
  const dispatch = useAppDispatch();

  if (!riskItem) {
    return null;
  }

  return (
    <div
      className="cardContainer"
    >
      <Card
        title={`Major Accident Hazard Register UID: ${riskItem.mahruidPrefixed || "###"}`}
        className="mahCard"
        headerElement={
          <button
            className="primary"
            onClick={() => dispatch(showNewMAHModal())}
            disabled={isViewOnly}

          >
            + Major Accident Hazard
          </button>
        }
      >
        {riskItem.hazards.length === 0
          ? (
            <Banner
              type={BannerType.info}
            >
              No hazards have been added.
            </Banner>
          ) : (
            <div
              className="table-holder"
            >
              <table
                className="sticky-table"
              >
                <MAHTableHeader />
                <tbody>
                  {riskItem.hazards.map(hazardItem => (
                    <MAHTableRow
                      riskItem={riskItem}
                      item={hazardItem}
                      key={hazardItem.lineItemNumber}
                      isViewOnly={isViewOnly}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )
        }
        <MAHModal />
      </Card >
    </div >
  );
};

export default HazardListCard;
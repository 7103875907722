import { YesNo } from "types/answerTypes";
import { IRiskHazardItem, IRiskItem } from "types/riskTypes";
import { ICurrentUser, Roles } from "types/userTypes";

export function isRiskItemReadOnly(riskItem: IRiskItem,
  currUser: ICurrentUser) {
  if (!riskItem.id) {
    return false;
  }

  if (currUser.roles.some(x => x.role === Roles.Admin)) {
    return false;
  }

  let isViewOnly = false;

  if (riskItem?.teamLeader?.email.toLowerCase() !== currUser.email.toLowerCase()
    && riskItem?.createdBy?.email.toLowerCase() !== currUser.email.toLowerCase()) {
    isViewOnly = true;
  } else if (riskItem?.createdOn) {
    const now = new Date();
    const dateOffset = (24 * 60 * 60 * 1000) * 31; // 31 days.
    const cutoff = new Date(riskItem.createdOn);
    cutoff.setTime(cutoff.getTime() + dateOffset);

    isViewOnly = now > cutoff;
  }

  return isViewOnly;
}

export function validateRiskLineItem(item: IRiskHazardItem, requireLineItemNumber: boolean) {
  let errors: string[] = [];

  if (requireLineItemNumber
    && !item.lineItemNumber) {
    errors.push("lineItemNumber is required!");
  }
  if (!item.hazard) {
    errors.push("Hazard is required!");
  }
  if (!item.hazardDescription) {
    errors.push("Hazard Description is required!");
  }
  if (!item.source) {
    errors.push("Source is required!");
  }
  if (!item.volume) {
    errors.push("Tier1 Threshold (1 hour release) is required!");
  }
  if (item.volume !== "N/A"
    && !item.maxReleaseAboveTier1Threshold) {
    errors.push("Max. Release Above Tier 1 Threshold is required!");
  } else if (item.volume === "N/A"
    && item.maxReleaseAboveTier1Threshold !== undefined) {
    errors.push("When Tier1 Threshold (1 hour release) is 'N/A', Max. Release Above Tier 1 Threshold must not be specified!");
  }
  if (!item.pointOfRelease) {
    errors.push("Point of Release is required!");
  }
  if (item.pointOfRelease?.name === "Other Point of Release"
    && !item.otherPointOfReleaseDescription) {
    errors.push("Other Point of Release Description is required!");
  }
  if (!item.comments) {
    errors.push("Comments is required!");
  }
  if (!item.relativeRanking) {
    errors.push("Relative Ranking (RR) is required!");
  }
  if (!item.whatIfAnalysis) {
    errors.push("What If Analysis (WI) is required!");
  }
  if (!item.safetyReview) {
    errors.push("Safety Review (SR) is required!");
  }
  if (!item.prelimHazardAnalysis) {
    errors.push("Preliminary Hazard Analysis (PreHA) is required!");
  }
  if (!item.checklist) {
    errors.push("Checklist is required!");
  }
  if (!item.otherHIRATools) {
    errors.push("Other is required!");
  }
  if (item.otherHIRATools === YesNo.Yes
    && !item.otherHIRAToolsDescription) {
    errors.push("Other Description is required!");
  }
  if (!item.type) {
    errors.push("Type is required!");
  }
  if (!item.typeDescription) {
    errors.push("Type Description is required!");
  }
  if (!item.potentialRisk) {
    errors.push("Potential Risk is required!");
  }
  if (!item.residualRisk) {
    errors.push("Residual Risk is required!");
  }

  if (errors.length) {
    throw new Error(errors.join(' '));
  }
}
import { authRequest, msalPublicClient } from "./auth-config";

const acquireAccessToken = async () => {
  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = msalPublicClient.getActiveAccount();
  const accounts = msalPublicClient.getAllAccounts();

  if (!activeAccount
    && accounts.length === 0) {
    throw new Error("Network request aborted. User is not authenticated.");
  }

  const request = {
    scopes: authRequest.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalPublicClient.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch {
      msalPublicClient.loginRedirect();
  }
};

const authFetch = async (method: "GET" | "POST" | "PUT" | "DELETE",
  url: string,
  body?: any,
  headers?: any) => {
  const token = await acquireAccessToken();

  return fetch(url, {
    method,
    headers: Object.assign({},
      headers || {},
      {
        "Authorization": `Bearer ${token}`,
      }),
    body,
  });
}
const authGetJson = (url: string) => authFetch("GET", url);
const authPostJson = (url: string, data: any) => authFetch("POST", url, JSON.stringify(data), {
  "Content-Type": "application/json",
});
const authPutJson = (url: string, data: any) => authFetch("PUT", url, JSON.stringify(data), {
  "Content-Type": "application/json",
});
const authDelete = (url: string) => authFetch("DELETE", url);

export {
  authFetch,
  authGetJson,
  authPostJson,
  authPutJson,
  authDelete,
};

import React from "react";
import "./FlexRow.scoped.scss";

const FlexRow: React.FC<React.HTMLProps<HTMLDivElement>> = (props) =>
  <div
    {...props}
    className={(props.className ?? "") + " flex-row"}
  >
    {props.children}
  </div>;

export default FlexRow;
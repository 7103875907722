import React, { CSSProperties } from "react";
import "./Card.scoped.scss";

interface ICardProps {
  title?: string,
  titleStyle?: React.CSSProperties,
  headerElement?: React.ReactNode,
  className?: string,
  isCollapsed?: boolean,
  footer?: React.ReactNode,
  /**
   * If a title is provided, this will default to true.
   */
  showHeader?: boolean,
  style?: CSSProperties,
  children: React.ReactNode,
}

const Card: React.FC<ICardProps> = ({
  title,
  titleStyle,
  headerElement,
  children,
  className,
  isCollapsed,
  footer,
  showHeader,
  style,
}) =>
  <div
    className={`card ${className || ""} ${isCollapsed
      || children === undefined
      ? "collapsed"
      : ""}`
    }
    style={style}
  >
    {((!!title
      && (showHeader === undefined
        || showHeader === true))
      || showHeader === true) && (
        <div
          className="header"
        >
          {!!title && (
            <h4
              className="title"
              style={titleStyle}
            >
              {title}
            </h4>
          )}
          {headerElement}
        </div>
      )}
    {children !== undefined
      && !isCollapsed && (
        <div
          className="body"
        >
          {children}
        </div>
      )}
    {footer && (
      <div
        className="footer"
      >
        {footer}
      </div>
    )}
  </div>;

export default Card;
export interface IValidityPeriod {
  id: string,
  name: string,
}

export interface IPhase {
  id: string,
  name: string,
}

export interface IOtherLocation {
  id: string,
  name: string,
}

export interface IRiskStandard {
  id: string,
  name: string,
}

export interface ISituationIncidentOrEventType {
  id: string,
  name: string,
}

export interface IFacility {
  id: string,
  commonId: number,
  name: string,
}

export interface ICountry {
  id: string,
  name: string,
}

export interface IBusinessItem {
  id: string,
  code: string,
  parent: string,
  name: string,
  type: BusinessTypes,
  children: IBusinessItem[],
}

export enum BusinessTypes {
  BusinessLine = "BusinessLine",
  Division = "Division",
  SubBusinessLine = "SubBusinessLine",
  Support = "Support",
}

export interface IBasinItem {
  id: string,
  code: string,
  name: string,
  type: string,
  parent: string,
  children: IBasinItem[],
}

export interface IHazard {
  id: string,
  number: string,
  parent: string,
  description: string,
  safety: string,
  health: string,
  environmental: string,
  sources: string,
  volume: string,
}

export interface IHazardDescription {
  id: string,
  name: string,
}

export interface IPointOfRelease {
  id: string,
  name: string,
  parentId: string,
}
import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import MasterDataApi from "api/master-data/MasterDataApi";
import RisksApi from "api/risks/RisksApi";
import { Action } from "redux";
import { takeEvery } from "redux-saga/effects";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IOperation } from "shared/types/operationTypes";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IBasinItem, IBusinessItem, ICountry, IFacility } from "types/masterDataTypes";
import { IRiskHazardLineItemView } from "types/riskTypes";
import { applyFilters, finishLoadingHazards, finishLoadingOtherLocations, finishLoadingPhases, finishLoadingRiskList, IRiskListState, loadHazards, loadOtherLocations, loadPhases, loadPickerItems, loadRiskList, RiskListPickerKeys, setPickerError, setPickerItems, setSelectedPickerItems } from "./RiskListSlice";

export function* riskListSagas() {
  yield all([
    watchLoadRiskList(),
    loadPickerDataAsync(),
  ]);
}

function* watchLoadRiskList() {
  yield takeLatest([loadRiskList, applyFilters], function* (action: Action) {
    if (!loadRiskList.match(action)
      && !applyFilters.match(action)) {
      return;
    }

    try {
      const state: IRiskListState = yield select((store: RootState) => store.riskList);

      const {
        page,
        pageSize,
        appliedFilters,
      } = state;

      let pagedItems: IPagedResult<IRiskHazardLineItemView>;

      if (loadRiskList.match(action)) {
        pagedItems = yield call(RisksApi.getRiskLineItemViews,
          action.payload.pageSize,
          action.payload.pageNumber,
          appliedFilters);
      } else {
        pagedItems = yield call(RisksApi.getRiskLineItemViews,
          pageSize,
          page,
          appliedFilters);
      }

      yield put(finishLoadingRiskList({
        isWorking: false,
        data: pagedItems,
      }));
    } catch (err) {
      yield put(finishLoadingRiskList({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* loadPickerDataAsync() {
  yield takeEvery([
    loadPickerItems,
    loadPhases,
    loadHazards,
    loadOtherLocations
  ], function* (action: Action) {
    let apiMethod: (() => Promise<any[]>) | undefined;
    let finishAction: ActionCreatorWithPayload<IOperation<any[]>, string> | undefined;

    if (loadOtherLocations.match(action)) {
      apiMethod = MasterDataApi.getOtherLocations;
      finishAction = finishLoadingOtherLocations;
    } else if (loadPhases.match(action)) {
      apiMethod = MasterDataApi.getPhases;
      finishAction = finishLoadingPhases;
    } else if (loadHazards.match(action)) {
      apiMethod = MasterDataApi.getHazards;
      finishAction = finishLoadingHazards;
    } else if (loadPickerItems.match(action)) {
      const pickerKey = action.payload.pickerKey;
      const searchValue = action.payload.searchValue;

      switch (action.payload.pickerKey) {
        case RiskListPickerKeys.facilities:
          yield retrieveAndPutPickerData(MasterDataApi.getFacilities,
            (item): IPickerItem<IFacility> => ({
              key: item.id,
              item: item,
            }),
            pickerKey,
            false,
            searchValue);
          return;
        case RiskListPickerKeys.countries:
          yield retrieveAndPutPickerData(MasterDataApi.getCountries,
            (item): IPickerItem<ICountry> => ({
              key: item.id,
              item: item,
            }),
            pickerKey,
            false,
            searchValue);
          return;
        case RiskListPickerKeys.businesses:
          yield retrieveAndPutPickerData(MasterDataApi.getBusinesses,
            (item): IPickerItem<IBusinessItem> => mapBusinessToPickerItem(item),
            pickerKey,
            false,
            searchValue);
          return;
        case RiskListPickerKeys.basins:
          yield retrieveAndPutPickerData(MasterDataApi.getBasins,
            (item): IPickerItem<IBasinItem> => mapBasinToPickerItem(item),
            pickerKey,
            false,
            searchValue);
          return;
      }
    }

    if (!apiMethod
      || !finishAction) {
      yield put(showErrorToast(`Load dropdown action not found for ${action.type}.`));
      return;
    }

    yield loadDropdownData(apiMethod,
      finishAction);
  });
}

function* loadDropdownData<T>(apiMethod: () => Promise<T[]>,
  finishAction: ActionCreatorWithPayload<IOperation<T[]>, string>) {
  try {
    const data: T[] = yield call(apiMethod);

    yield put(finishAction({
      isWorking: false,
      data,
    }));
  } catch (err) {
    yield put(finishAction({
      isWorking: false,
      errorMessage: getResponseErrorMessage(err),
    }));
  }
}

function* retrieveAndPutPickerData<T>(apiMethod: (searchValue?: string | undefined) => Promise<T[]>,
  itemMapper: (item: T) => IPickerItem<T>,
  pickerKey: string,
  selectFirstIfOnlyOne: boolean,
  searchValue?: string) {

  try {
    const rawItems: T[] = yield call(apiMethod, searchValue);
    const items = rawItems.map(itemMapper);
    yield put(setPickerItems({
      pickerKey,
      items,
    }));

    if (items.length === 1
      && selectFirstIfOnlyOne) {
      yield put(setSelectedPickerItems({
        pickerKey,
        selectedItems: items,
      }));
    }

    yield put(setPickerError({
      pickerKey,
      errorMessage: "",
      stopLoading: true,
    }));
  } catch (err) {
    yield put(setPickerError({
      pickerKey,
      errorMessage: getResponseErrorMessage(err),
      stopLoading: true,
    }));
  }
}

function mapBusinessToPickerItem(item: IBusinessItem): IPickerItem<IBusinessItem> {
  return {
    key: item.id,
    item: item,
    children: item.children.map(child => mapBusinessToPickerItem(child)),
  };
}

function mapBasinToPickerItem(item: IBasinItem): IPickerItem<IBasinItem> {
  return {
    key: item.id,
    item: item,
    children: item.children.map(child => mapBasinToPickerItem(child)),
  };
}
import React, { useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import deleteIcon from "shared/media/dls/delete.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import { removeHazardItem, viewHazardItem } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch } from "store/store";
import { IRiskHazardItem, IRiskItem, RiskMatrixCellColors } from "types/riskTypes";
import { formatRiskMatrixValue } from "utilities/riskMatrixUtilities";
import "../HazardListCard.scoped.scss";

interface IMAHTableRowProps {
  riskItem: IRiskItem,
  item: IRiskHazardItem,
  isViewOnly?: boolean,
}

const MAHTableRow: React.FC<IMAHTableRowProps> = ({
  riskItem,
  item,
  isViewOnly,
}) => {
  const dispatch = useAppDispatch();
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);

  return (
    <tr>
      <td className="cmd-col">
        <img
          src={editIcon}
          alt="View/Edit"
          title="View/Edit"
          className="icon-small button"
          onClick={() => dispatch(viewHazardItem(item.lineItemNumber))}
        />
      </td>
      <td>
        {[
          riskItem.mahruidPrefixed || "###",
          item.lineItemNumber,
        ].join('-')}
      </td>
      <td
        title={item.hazard
          ? `${item.hazard?.number} ${item.hazard?.description}`
          : undefined
        }
      >
        {item.hazard?.number} {item.hazard?.description}
      </td>
      <td
        title={item.hazardDescription?.name}
      >
        {item.hazardDescription?.name}
      </td>
      <td
        title={item.source}
      >
        {item.source}
      </td>
      <td
        title={item.volume}
      >
        {item.volume}
      </td>
      <td>
        {item.maxReleaseAboveTier1Threshold}
      </td>
      <td
        title={item.pointOfRelease?.name}
      >
        {item.pointOfRelease?.name}
      </td>
      <td
        title={item.comments}
      >
        {item.comments}
      </td>
      <td
        className="spacer"
      ></td>
      <td>
        {item.relativeRanking}
      </td>
      <td>
        {item.whatIfAnalysis}
      </td>
      <td>
        {item.safetyReview}
      </td>
      <td>
        {item.prelimHazardAnalysis}
      </td>
      <td>
        {item.checklist}
      </td>
      <td>
        {item.otherHIRATools}
      </td>
      <td
        title={item.otherHIRAToolsDescription}
      >
        {item.otherHIRAToolsDescription}
      </td>
      <td
        className="spacer"
      ></td>
      <td
        title={item.type?.name}
      >
        {item.type?.name}
      </td>
      <td
        title={item.typeDescription}
      >
        {item.typeDescription}
      </td>
      <td
        className="spacer"
      ></td>
      <td
        style={item.potentialRisk
          ? {
            backgroundColor: RiskMatrixCellColors[item.potentialRisk]
          } : undefined
        }
      >
        {formatRiskMatrixValue(item.potentialRisk)}
      </td>
      <td
        style={item.residualRisk
          ? {
            backgroundColor: RiskMatrixCellColors[item.residualRisk]
          } : undefined
        }
      >
        {formatRiskMatrixValue(item.residualRisk)}
      </td>
      <td
        className="spacer"
      ></td>
      <td
        title={item.kpiTier3}
      >
        {item.kpiTier3}
      </td>
      <td
        title={item.kpiTier4}
      >
        {item.kpiTier4}
      </td>
      <td className="cmd-col">
        <img
          src={deleteIcon}
          alt="Delete"
          title="Delete"
          className={`icon-small button ${isViewOnly ? "disabled" : ""}`}
          onClick={isViewOnly
            ? undefined
            : () => setIsConfirmDeleteVisible(true)
          }
        />
        {isConfirmDeleteVisible && (
          <ConfirmModal
            header="Confirm Delete"
            message={
              <Banner
                type={BannerType.warn}
              >
                Are you sure you want to remove this item?<br />
                Once the data is saved, this operation can no longer be undone.
              </Banner>
            }
            onYesClicked={() => dispatch(removeHazardItem(item.lineItemNumber))}
            onNoClicked={() => setIsConfirmDeleteVisible(false)}
          />
        )}
      </td>
    </tr>
  );
};

export default MAHTableRow;
import React from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import "./PickerItem.scoped.scss";

interface IPickerItemProps<T> {
  item: IPickerItem<T>,
  isSelected: boolean,
  pickerKey: string,
  type: "checkbox" | "radio",
  renderItem?: (item: T) => string | React.ReactNode,
  onSelect(): void,
  onDeselect(): void,
  onToggle?(): void,
}

const PickerItem = <T,>({
  item,
  isSelected,
  pickerKey,
  type,
  renderItem,
  onSelect,
  onDeselect,
  onToggle,
}: IPickerItemProps<T>) =>
  <span
    className="picker-item"
  >
    {onToggle !== undefined && (
      <button
      >
        +
      </button>
    )}
    <label>
      <input
        type={type}
        name={pickerKey}
        value={item.key}
        checked={isSelected}
        onChange={(e) => type === "checkbox"
          ? (
            e.target.checked
              ? onSelect()
              : onDeselect()
          ) : onSelect()
        }
      />
      <span>
        {renderItem && item.item
          ? renderItem(item.item)
          : item.text
        }
      </span>
    </label>
  </span>;

export default PickerItem;
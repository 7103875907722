import React, { useState } from "react";
import "./MainMenu.scoped.scss";
import MenuItem, { IMenuItemProps } from "./MenuItem";
import homeIcon from "shared/media/dls/home.svg";
import closeIcon from "shared/media/dls/arrow-left-2.svg";
import openIcon from "shared/media/dls/arrow-right-2.svg";

export interface IMainMenuProps {
  menuItems: IMenuItemProps[],
}

const MainMenu: React.FC<IMainMenuProps> = ({
  menuItems,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  }

  return (
    <div
      className="menu-holder"
    >
      <div
        className={`menu ${!isOpen
          ? "collapsed"
          : ""}`
        }
      >
        <MenuItem
          key="HOME"
          label="Home"
          icon={homeIcon}
          route="/"
        />
        {menuItems.map(mi => (
          <MenuItem
            label={mi.label}
            key={mi.key}
            icon={mi.icon}
            toggleOnClick={mi.toggleOnClick}
            subItems={mi.subItems}
            route={mi.route}
          />
        ))}
        <div
          className="toggle-button"
          onClick={(e) => toggleMenu(e)}
        >
          <img
            src={isOpen
              ? closeIcon
              : openIcon
            }
            alt={isOpen
              ? "<"
              : ">"
            }
            className="icon-small"
          />
        </div>
      </div>
      <div
        className="inside-corner"
      >
      </div>
    </div>
  );
}

export default MainMenu;
import RiskMatrix from "components/risks/risk-matrix/RiskMatrix";
import React from "react";
import Modal from "shared/components/layout/modal/Modal";
import { RiskMatrixValues } from "types/riskTypes";
import "./RiskMatrixModal.scoped.scss";

interface IRiskMatrixModalProps {
  type: "Potential" | "Residual",
  onValueSelected(value: RiskMatrixValues): void,
  onCancel(): void,
  isDisabled?: boolean,
}

const RiskMatrixModal: React.FC<IRiskMatrixModalProps> = ({
  type,
  onValueSelected,
  onCancel,
  isDisabled,
}) =>
  <Modal
    header={`${type} Risk`}
    isOpen={true}
    buttons={[{
      key: "cancel",
      text: "close",
      className: "primary",
      onClick: onCancel,
    }]}
  >
    <RiskMatrix
      isDisabled={isDisabled}
      onCellClicked={onValueSelected}
      userMessage={"Please click on the relevant Risk level in the matrix."}
    />
  </Modal>;

export default RiskMatrixModal;
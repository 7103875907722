import React from "react";
import Note from "shared/components/common/note/Note";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { ManageRiskCards, toggleCardState, updateRiskProperties } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./ReferencesCard.scoped.scss";

const ReferencesCard: React.FC = () => {
  const riskItem = useAppSelector(store => store.manageRisk.riskItem);
  const cardStates = useAppSelector(store => store.manageRisk.cardStates);
  const isViewOnly = useAppSelector(store => store.manageRisk.isViewOnly);
  const dispatch = useAppDispatch();

  if (!riskItem) {
    return null;
  }

  const isOpen = cardStates.find(x => x.card === ManageRiskCards.references)?.isOpen ?? true;

  return (
    <Card
      title="References Used in Risk Identification"
      headerElement={
        <CollapseSwitch
          isOpen={isOpen}
          onClick={() => dispatch(toggleCardState({
            card: ManageRiskCards.references,
            isOpen: !isOpen,
          }))}
        />
      }
      isCollapsed={!isOpen}
    >
      <FlexRow
        className="row-to-col"
      >
        <LabeledControl
          label="Link to Previous Major Accident Hazard Register"
          isRequired={true}
        >
          <textarea
            value={riskItem.prevMAHRegisterLink || ""}
            onChange={(e) => dispatch(updateRiskProperties({
              prevMAHRegisterLink: e.currentTarget.value,
            }))}
            maxLength={2000}
            disabled={isViewOnly}
          />
          <Note>- Enter "First One" if this is the first MAH entry for this location</Note>
        </LabeledControl>

        <LabeledControl
          label="Applicable Regulatory Requirements"
          isRequired={true}
        >
          <textarea
            value={riskItem.regulatoryReqsLink || ""}
            onChange={(e) => dispatch(updateRiskProperties({
              regulatoryReqsLink: e.currentTarget.value,
            }))}
            maxLength={2000}
            disabled={isViewOnly}
          />
          <Note>- Enter "N/A" if document is not available</Note>
        </LabeledControl>

        <LabeledControl
          label="Applicable Contractual Requirements"
          isRequired={true}
        >
          <textarea
            value={riskItem.contracturalReqsLink || ""}
            onChange={(e) => dispatch(updateRiskProperties({
              contracturalReqsLink: e.currentTarget.value,
            }))}
            maxLength={2000}
            disabled={isViewOnly}
          />
          <Note>- Enter "N/A" if document is not available</Note>
        </LabeledControl>

        <LabeledControl
          label="Assumptions"
          isRequired={true}
        >
          <textarea
            value={riskItem.assumptions || ""}
            onChange={(e) => dispatch(updateRiskProperties({
              assumptions: e.currentTarget.value,
            }))}
            maxLength={2000}
            disabled={isViewOnly}
          />
          <Note>- Enter "N/A" if document is not available</Note>
        </LabeledControl>

        <LabeledControl
          label="Emergency Response Plan"
          isRequired={true}
        >
          <textarea
            value={riskItem.emergencyResponsePlan || ""}
            onChange={(e) => dispatch(updateRiskProperties({
              emergencyResponsePlan: e.currentTarget.value,
            }))}
            maxLength={2000}
            disabled={isViewOnly}
          />
          <Note>- Enter "N/A" if document is not available</Note>
        </LabeledControl>

        <LabeledControl
          label="Link to Report from Risk Identification Workshop"
          isRequired={true}
        >
          <textarea
            value={riskItem.riskWorkshopReportLink || ""}
            onChange={(e) => dispatch(updateRiskProperties({
              riskWorkshopReportLink: e.currentTarget.value,
            }))}
            maxLength={2000}
            disabled={isViewOnly}
          />
          <Note>- Enter "N/A" if document is not available</Note>
        </LabeledControl>

      </FlexRow>
    </Card>
  );
}

export default ReferencesCard;
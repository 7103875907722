export async function throwIfResponseError(response: Response) {
  if (response.status === 500) {
    await throwHttp500Error(response, "The server encountered a fatal error.");
  } else if (response.status === 400) {
    await throwResponseError(response, `The request sent to the server was invalid.`);
  } else if (response.status === 401) {
    await throwResponseError(response, "The requested resource requires authentication to access.");
  } else if (response.status === 403) {
    await throwResponseError(response, "You do not have permission to access the requested resource.");
  } else if (response.status === 404) {
    await throwResponseError(response, "The requested resource was not found.");
  } else if (response.status < 200 || response.status > 299) {
    await throwResponseError(response, `Server returned ${response.statusText}.`);
  }
}

async function throwHttp500Error(response: Response, defaultErrorMsg: string) {
  let responseText: string = "";
  try {
    responseText = await response.text();
  } catch {
    throw new Error(defaultErrorMsg);
  }

  if (responseText) {
    throw new Error(responseText.split('\n')[0]);
  }

  throw new Error(defaultErrorMsg);
}

async function throwResponseError(response: Response, defaultErrorMsg: string) {
  let jsonResponse: any;
  let textResponse: any;

  try {
    textResponse = await response.text();
    jsonResponse = JSON.parse(textResponse);
  } catch {
    throw new Error(textResponse || defaultErrorMsg);
  }

  if (isErrorValidationErrorResponse(jsonResponse)) {
    throw new Error(`${jsonResponse.title}: ${reduceValidationErrors(jsonResponse, " ")}`)
  } else if (jsonResponse.Message) {
    throw new Error(jsonResponse.Message);
  } else {
    throw new Error(defaultErrorMsg);
  }
}

export function isErrorValidationErrorResponse(error: any): error is IValidationErrorResponse {
  if (error.title !== undefined
    && error.type !== undefined
    && error.status !== undefined
    && error.errors !== undefined) {
    return true;
  }
  return false;
}

export function reduceValidationErrors(error: IValidationErrorResponse, separator: string = ' '): string {
  return Object.keys(error.errors)
    .flatMap(k => error.errors[k])
    .join(separator);
}

export function getResponseErrorMessage(err: any) {
  if (isErrorValidationErrorResponse(err)) {
    return reduceValidationErrors(err);
  } else if (err.message) {
    return err.message;
  } else {
    return err.toString();
  }
}

export default interface IValidationErrorResponse {
  "type": string,
  "title": string,
  "status": number,
  "traceId": string,
  "errors": IValidationErrors,
}

export interface IValidationErrors {
  [property: string]: string[],
}
import React from "react";
import "./MAHTableHeader.scoped.scss";

const MAHTableHeader: React.FC = () =>
  <thead>
    <tr
      className="group-headers"
    >
      <th className="cmd-col"></th>
      <th
        colSpan={8}
        className="centered"
      >
        Hazard Identification
      </th>
      <th className="spacer"></th>
      <th
        colSpan={7}
        className="centered"
      >
        HIRA Tools Used
      </th>
      <th className="spacer"></th>
      <th
        colSpan={2}
        className="centered"
      >
        Situation / Incident or event of concern
      </th>
      <th className="spacer"></th>
      <th
        colSpan={2}
        className="centered"
      >
        MAH Risk Matrix
      </th>
      <th className="spacer"></th>
      <th
        colSpan={2}
        className="centered"
      >
        Key Performance Indicators
      </th>
      <th className="cmd-col"></th>
    </tr>
    <tr
      className="cell-headers"
    >
      <th
        className="cmd-col"
      >
      </th>
      <th>
        Hazard Number UID
      </th>
      <th>
        Hazard
      </th>
      <th>
        Hazard Description
      </th>
      <th>
        Source
      </th>
      <th>
        Tier1 Threshold (1 hour release)
      </th>
      <th>
        Max. Release Above Tier 1 Threshold?
      </th>
      <th>
        Point of Release
      </th>
      <th>
        Comments
      </th>
      <th className="spacer"></th>
      <th
        className="vertical-text"
      >
        Relative Ranking (RR)
      </th>
      <th
        className="vertical-text"
      >
        What If Analysis (WI)
      </th>
      <th
        className="vertical-text"
      >
        Safety Review (SR)
      </th>
      <th
        className="vertical-text"
      >
        Preliminary Hazard Analysis (PrefHA)
      </th>
      <th
        className="vertical-text"
      >
        Checklist
      </th>
      <th
        className="vertical-text"
      >
        Other
      </th>
      <th>
        Other Description
      </th>
      <th className="spacer"></th>
      <th>
        Type
      </th>
      <th>
        Description
      </th>
      <th className="spacer"></th>
      <th>
        Potential Risk
      </th>
      <th>
        Residual Risk
      </th>
      <th className="spacer"></th>
      <th>
        Tier 3
      </th>
      <th>
        Tier 4
      </th>
      <th className="cmd-col"></th>
    </tr>
  </thead>;

export default MAHTableHeader;
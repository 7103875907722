export interface ICurrentUser {
  name: string,
  email: string,
  roles: IUserRoleInfo[],
}

export enum Roles {
  Admin = "Admin",
}

export interface IUserRoleInfo {
  role: Roles,
}
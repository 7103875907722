import React, { useEffect, useState } from "react";
import "./Picker.scoped.scss";
import arrowIcon from "shared/media/dls/arrow-down-2.svg";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import Modal from "shared/components/layout/modal/Modal";
import Spinner from "shared/components/common/spinner/Spinner";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import PickerList from "./list/PickerList";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { IClosePickerAction, ICollapsePickerItemAction, IExpandPickerItemAction, ILoadPickerItemsAction, IOpenPickerAction, ISetPickerItemsAction, ISetPickerSelectedItemsAction } from "shared/store/picker/pickerReducerHandlers";
import { useDispatch } from "react-redux";
import SearchBox from "../search-box/SearchBox";
import { cloneDeep } from "lodash";

interface IPickerProps<T> {
  /**
   * The picker state from redux.
   */
  pickerState: IPickerState<T>,
  /**
   * Placeholder text to show when no items are selected. Defaults to "Select".
   */
  placeholder?: string,
  /**
   * The title text to show in the modal.
   */
  title: string,
  /**
   * Disables the picker so that the user cannot interact with it.
   */
  isDisabled?: boolean,
  /**
   * If specified, the picker will render itself as this node rather than the default display.
   */
  pickerNode?: React.ReactNode,
  /**
   * Receives one of the picker items and must return its renderable node for display in the list.
   * If this is not provided, the "text" property of the IPickerItem will be rendered instead.
   */
  renderListItem?: (item: T) => string | React.ReactNode,
  /**
   * Receives one of the picker items and must return its renderable node for display in the selected list when the modal is not open.
   * If this is not provided, the "text" property of the IPickerItem will be rendered instead.
   */
  renderSelectedItem?: (item: T) => string,
  /**
   * Determines how many selected items can be shown in the picker control. If the number of selected items
   * exceeds this limit, "x selected items" will appear instead. If not set, there is no limit.
   */
  maxSelectedItemsVisible?: number,
  /**
   * If set to true, allows multiple picker items to be selected. Defaults to false.
   */
  allowMultiSelect?: boolean,
  /**
   * Determines display type for the items in the list.
   * Defaults to list.
   */
  displayMode?: "list" | "tree",
  /**
   * When the modal opens, if this is set to true and there are any existing items in redux for this picker,
   * those items will be used instead of dispatching the loadAction.
   */
  preserveItems?: boolean,
  /**
   * Options for the search bar in the header. If no options are specified, the search bar will appear and filter the local list.
   */
  searchOptions?: {
    /**
     * Determines visibility of the search bar in the modal header.
     * Defaults to true.
     */
    show?: boolean,
    /**
     * Determines whether the search box will call the load action (async) or simply filter the local list (sync).
     * Defaults to "sync".
     */
    behavior?: "async" | "sync",
    /**
     * If specified and > 0, the loadAction will not be dispatched until the user types this many characters into the search box.
     * The search value will then be included in the load action. This option has no effect when loadAction is undefined.
     */
    asyncMinChars?: number,
    /**
     * The number of milliseconds after the search value changes to wait before dispatching the loadAction.
     * This option has no effect unless behavior is "async", "asyncMinChars" is > 0, and loadAction is specified.
     * Defaults to 1000.
     */
    asyncSearchDelay?: number,
    /**
      * Receives one of the picker items and a search string and must return whether or not it should be visible.
      * If this is not specified, the picker will attempt to filter by the item's text property.
      */
    filterItem?: (a: IPickerItem<T>, filterValue: string) => boolean,
  },
  /**
   * The message to show if there are no items available in the picker popup.
   * To show no message at all, pass an empty string.
   * Defaults to "No items were found."
   */
  noItemsMessage?: string,
  /**
   * Determines whether the items in redux should be cleared when the modal closes.
   * Defaults to true.
   */
  clearItemsOnClose?: boolean,
  /**
   * The redux action to dispatch when the user clicks to open the picker modal.
   */
  openAction: ActionCreatorWithPayload<IOpenPickerAction, string>,
  /**
   * The redux action to dispatch when the the user clicks the close button in the modal.
   */
  closeAction: ActionCreatorWithPayload<IClosePickerAction, string>,
  /**
   * The redux action to dispatch to load the picker items.
   * If this is not specified, a load action will never be dispatched.
   * In that case, items from the picker state will always be used instead.
   */
  loadAction?: ActionCreatorWithPayload<ILoadPickerItemsAction, string>,
  /**
   * The redux action the dispatch to set the picker items in redux.
   * This will only be called when the user closes the modal and only if clearItemsOnClose is true.
   */
  setItemsAction?: ActionCreatorWithPayload<ISetPickerItemsAction<T>, string>,
  /**
   * The redux action to dispatch when the user clicks the apply button.
   */
  setSelectedItemsAction?: ActionCreatorWithPayload<ISetPickerSelectedItemsAction<T>, string>,
  /**
   * If the redux action setSelectedItemsAction is not specified, it will use this callback instead
   * when items are selected.
   */
  setSelectedItems?: (items: IPickerItem<T>[]) => void,
  /**
   * The redux action to dispatch when the user expands an item.
   * This should only be specified when displayMode is "tree".
   */
  expandItemsAction?: ActionCreatorWithPayload<IExpandPickerItemAction, string>,
  /**
   * The redux action to dispatch when the user collapses an item.
   * This should only be specified when displayMode is "tree".
   */
  collapseItemsAction?: ActionCreatorWithPayload<ICollapsePickerItemAction, string>,
  /**
   * The classname to apply to the picker element.
   */
  className?: string,
}

const Picker = <T,>({
  pickerState: {
    key,
    isOpen,
    items,
    selectedItems,
    loadOperation,
  },
  placeholder,
  title,
  pickerNode,
  renderListItem,
  renderSelectedItem,
  isDisabled,
  maxSelectedItemsVisible,
  allowMultiSelect,
  displayMode,
  preserveItems,
  searchOptions,
  clearItemsOnClose,
  noItemsMessage,
  openAction,
  closeAction,
  loadAction,
  setItemsAction,
  setSelectedItemsAction,
  setSelectedItems,
  expandItemsAction,
  collapseItemsAction,
  className,
}: IPickerProps<T>) => {
  const dispatch = useDispatch();
  const [localSelectedItems, setLocalSelectedItems] = useState<IPickerItem<T>[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const showSearch = searchOptions?.show !== false;
  const searchBehavior = searchOptions?.behavior;
  const asyncSearchMinChars = searchOptions?.asyncMinChars;
  const asyncSearchDelay = searchOptions?.asyncSearchDelay;
  const filterItem = searchOptions?.filterItem;

  const defaultNoItemsMessage = searchOptions?.asyncMinChars
    ? (!searchValue?.trim()
      ? "Please use the search box to find items."
      : "No items were found. Please use the search box to find items."
    )
    : "No items were found.";

  useEffect(() => {
    setLocalSelectedItems([...selectedItems]);
  }, [selectedItems]);

  useEffect(() => {
    setSearchValue("");
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen
      && setItemsAction
      && !preserveItems
      && (clearItemsOnClose === undefined
        || clearItemsOnClose)) {
      dispatch(setItemsAction({
        pickerKey: key,
        items: [],
      }));
    }
  }, [isOpen, dispatch, setItemsAction, clearItemsOnClose, key, preserveItems]);

  useEffect(() => {
    let timeoutId: number | undefined = undefined;

    if (searchBehavior !== "async"
      || !asyncSearchMinChars
      || !loadAction
      || searchValue.trim().length < asyncSearchMinChars) {
      return;
    }

    timeoutId = window.setTimeout(() => {
      dispatch(loadAction({
        pickerKey: key,
        searchValue: searchValue,
      }));
    }, asyncSearchDelay ?? 1000);

    return () => {
      window.clearTimeout(timeoutId);
    }
  }, [searchValue, dispatch, loadAction, key, searchBehavior, asyncSearchMinChars, asyncSearchDelay]);

  const anySelected = selectedItems.length > 0;
  const showLoading = loadOperation?.isWorking;
  const showError = !loadOperation?.isWorking && !!loadOperation?.errorMessage;
  const showItems = !showLoading && !showError;
  const showSearchBar = showSearch === undefined
    || showSearch;

  const onItemSelected = (item: IPickerItem<T>) => {
    if (allowMultiSelect) {
      if (!localSelectedItems.some(k => k.key === item.key)) {
        setLocalSelectedItems([
          ...localSelectedItems,
          item,
        ]);
      }
    } else {
      setLocalSelectedItems([item]);
    }
  }

  const onItemDeselected = (item: IPickerItem<T>) => {
    if (allowMultiSelect) {
      setLocalSelectedItems([
        ...localSelectedItems.filter(k => k.key !== item.key),
      ]);
    }
  }

  const onOpen = () => {
    dispatch(openAction({
      pickerKey: key,
    }));
    if (loadAction) {
      if (preserveItems
        && items.length) {
        return;
      }

      if (asyncSearchMinChars !== undefined
        && asyncSearchMinChars > 0) {
        return;
      }
      dispatch(loadAction({
        pickerKey: key,
      }));
    }
  }

  const onClose = () => {
    dispatch(closeAction({
      pickerKey: key,
    }));
  }

  const onApply = (selItems: IPickerItem<T>[]) => {
    if (setSelectedItemsAction) {
      dispatch(setSelectedItemsAction({
        pickerKey: key,
        selectedItems: selItems,
      }));
    } else if (setSelectedItems) {
      setSelectedItems(selItems);
    }
    onClose();
  }

  const onClear = () => {
    onApply([]);
  }

  const onItemExpanded = (item: IPickerItem<T>, ancestryPath?: (string | number)[]) => {
    if (!expandItemsAction) {
      return;
    }

    dispatch(expandItemsAction({
      pickerKey: key,
      itemKey: item.key,
      ancestryPath,
    }));
  }

  const onItemCollapsed = (item: IPickerItem<T>, ancestryPath?: (string | number)[]) => {
    if (!collapseItemsAction) {
      return;
    }

    dispatch(collapseItemsAction({
      pickerKey: key,
      itemKey: item.key,
      ancestryPath,
    }));
  }

  const modalHeader = showSearchBar
    && isOpen
    ? (
      <>
        <div
          className="picker-modal-title"
        >
          {title}
        </div>
        <div
          className="picker-modal-search"
        >
          <SearchBox
            placeholder={`Search ${title}`}
            value={searchValue}
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            autoFocus={true}
          />
        </div>
      </>
    ) : title;

  const renderSelectedItems = () => {
    if (maxSelectedItemsVisible !== undefined
      && selectedItems.length > maxSelectedItemsVisible) {
      return `${selectedItems.length} selected item${selectedItems.length !== 1
        ? "s"
        : ""}`;
    }

    return selectedItems
      .map(x => (renderSelectedItem
        && x.item
        ? renderSelectedItem(x.item)
        : x.text)
        || "")
      .sort((a, b) => a < b
        ? -1
        : 1)
      .join('; ');
  }

  let searchLowered = "";
  let visibleItems = items;

  if (isOpen
    && searchBehavior !== "async") {
    searchLowered = searchValue.trim().toLowerCase();

    visibleItems = filterItems(items,
      searchLowered,
      filterItem
      ?? ((item, searchTerm) => {
        const ix = item.text?.toLowerCase()?.indexOf(searchTerm);
        return ix !== undefined
          && ix > -1;
      }),
      displayMode === "tree");
  }

  const onCancel = () => {
    setLocalSelectedItems([...selectedItems]);

    onClose();
  };

  return (
    <>
      {pickerNode !== undefined
        ? pickerNode
        : (
          <div
            className={`input picker ${isDisabled
              ? "disabled"
              : ""
              } ${className === undefined
                ? ""
                : className
              }`}
            onClick={isDisabled
              ? undefined
              : onOpen
            }
          >
            <div
              className={`labels ${!anySelected
                ? "placeholder"
                : ""}`
              }
            >
              {anySelected
                ? renderSelectedItems()
                : (placeholder || "Select")
              }
            </div>
            <img
              src={arrowIcon}
              alt=""
              className="icon-small"
            />
          </div>
        )}
      {isOpen &&
        <Modal
          header={modalHeader}
          isOpen={isOpen}
          buttons={[
            {
              className: "tertiary",
              key: "CLEAR",
              text: "Clear",
              onClick: onClear,
            },
            {
              className: "secondary",
              key: "CANCEL",
              text: "Cancel",
              onClick: onCancel,
            },
            {
              className: "primary",
              key: "APPLY",
              text: "Apply",
              disabled: !showItems,
              onClick: () => onApply(localSelectedItems),
            },
          ]}
        >
          {showLoading &&
            <Spinner />
          }
          {showError &&
            <Banner
              type={BannerType.error}
            >
              {loadOperation?.errorMessage}
            </Banner>
          }
          {showItems && (
            <PickerList
              items={visibleItems}
              renderItem={renderListItem}
              allowMultiSelect={allowMultiSelect}
              noItemsMessage={noItemsMessage === undefined
                ? defaultNoItemsMessage
                : noItemsMessage
              }
              pickerKey={key}
              selectedItems={localSelectedItems}
              displayMode={displayMode || "list"}
              onItemSelected={onItemSelected}
              onItemDeselected={onItemDeselected}
              onItemExpanded={onItemExpanded}
              onItemCollapsed={onItemCollapsed}
            />
          )}
        </Modal>
      }
    </>
  );
};

export default Picker;

function filterItems<T>(allItems: IPickerItem<T>[],
  searchTerm: string,
  filterItem: (item: IPickerItem<T>, searchTerm: string) => boolean,
  isTree: boolean): IPickerItem<T>[] {
  if (!searchTerm) {
    return allItems;
  }

  if (isTree) {
    // Tree mode filtering.
    let allTreeItems: IPickerItem<T>[] = cloneDeep(allItems);

    for (let i = allTreeItems.length - 1; i >= 0; i--) {
      let treeItem = allTreeItems[i];

      filterChildItems(treeItem,
        searchTerm,
        filterItem);

      if (!(treeItem.children?.length
        || filterItem(treeItem, searchTerm))) {
        // This item has no children that match the filter
        // and it doesn't match the filter.
        // Remove it from the list.
        allTreeItems.splice(i, 1);
      }
    }

    return allTreeItems;
  } else {
    // List mode filtering.
    return allItems.filter(x => filterItem(x, searchTerm));
  }
}

function filterChildItems<T>(item: IPickerItem<T>,
  searchTerm: string,
  filterItem: (item: IPickerItem<T>, searchTerm: string) => boolean) {
  if (!item.children?.length) {
    return;
  }

  let newChildren: IPickerItem<T>[] = [];

  for (let i = 0; i < item.children.length; i++) {
    const childItem = item.children[i];

    // If this item has any children, call this again.
    if (childItem.children?.length) {
      filterChildItems(childItem,
        searchTerm,
        filterItem);

      if (childItem.children.length > 0
        || filterItem(childItem, searchTerm)) {
        // This child item matches the filter or has a child that does.
        newChildren.push(childItem);
      }
    } else {
      if (filterItem(childItem, searchTerm)) {
        // This item matches the filter. Add it to the list for the parent item.
        newChildren.push(childItem);
      }
    }
  }

  item.children = newChildren;
}
import { isArray } from "lodash";
import React from "react";
import ReactDatePicker from "react-datepicker";
import Note from "shared/components/common/note/Note";
import Dropdown from "shared/components/controls/dropdown/Dropdown";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import formatDate from "shared/utilities/dateFormatters";
import { closePicker, collapsePickerItem, expandPickerItem, loadOtherLocations, loadPhases, loadPickerItems, loadRiskStandards, loadValidityPeriods, ManageRiskCards, openPicker, setPickerItems, setSelectedPickerItems, toggleLeaderEditorVisibility, toggleReporterEditorVisibility, toggleTeamEditorVisibility, updateRiskProperties } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IBasinItem, IBusinessItem, ICountry, IFacility, IValidityPeriod } from "types/masterDataTypes";
import UsersEditorModal from "../../users-editor/UsersEditorModal";
import "./ProcessCard.scoped.scss";
import ProcessCardTitle from "./ProcessCardTitle";

const std28Id = "614de9ec3fa9660eb8ea8e92";

const ProcessCard: React.FC = () => {
  const {
    riskItem,
    isViewOnly,
    validityPeriods,
    phases,
    otherLocations,
    riskStandards,
    isLeaderEditorOpen,
    isTeamEditorOpen,
    isReporterEditorOpen,
    cardStates,
    pickerData: {
      facilities,
      countries,
      businesses,
      basins,
    },
  } = useAppSelector(store => store.manageRisk);
  const dispatch = useAppDispatch();

  if (!riskItem) {
    return null;
  }

  const isOpen = cardStates.find(x => x.card === ManageRiskCards.process)?.isOpen ?? false;

  return (
    <Card
      showHeader={true}
      headerElement={
        <ProcessCardTitle />
      }
      isCollapsed={!isOpen}
    >
      <FlexRow>
        <FlexCol>
          <LabeledControl
            label="Date of Assessment"
            isRequired={true}
          >
            <ReactDatePicker
              onChange={(value) => isArray(value)
                ? undefined
                : dispatch(updateRiskProperties({
                  dateOfAssessment: (value as Date).getTime(),
                }))
              }
              value={riskItem.dateOfAssessment
                ? formatDate(new Date(riskItem.dateOfAssessment))
                : undefined
              }
              disabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Reporter"
            isRequired={true}
          >
            <div
              className={`input ${isViewOnly ? "disabled" : "pointer"}`}
              onClick={isViewOnly
                ? undefined
                : () => dispatch(toggleReporterEditorVisibility(true))
              }
            >
              {riskItem.reporter
                ? <>{riskItem.reporter.name} ({riskItem.reporter.email})</>
                : <>&nbsp;</>
              }
            </div>
          </LabeledControl>

          <LabeledControl
            label="Team Leader"
            isRequired={true}
          >
            <div
              className={`input ${isViewOnly ? "disabled" : "pointer"}`}
              onClick={isViewOnly
                ? undefined
                : () => dispatch(toggleLeaderEditorVisibility(true))
              }
            >
              {riskItem.teamLeader
                ? <>{riskItem.teamLeader.name} ({riskItem.teamLeader.email})</>
                : <>&nbsp;</>
              }
            </div>
          </LabeledControl>

          <LabeledControl
            label="Team Members"
          >
            <div
              className={`input ${isViewOnly ? "disabled" : "pointer"}`}
              onClick={isViewOnly
                ? undefined
                : () => dispatch(toggleTeamEditorVisibility(true))
              }
            >
              {(riskItem.teamMembers || []).length
                ? (
                  <>
                    {riskItem.teamMembers?.map(x => (
                      <span
                        className="team-member"
                        key={x.email}
                      >
                        {x.name} ({x.email})
                      </span>
                    ))}
                  </>
                )
                : <>&nbsp;</>
              }
            </div>
          </LabeledControl>

          <LabeledControl
            label="Risk Standard"
            isRequired={true}
          >
            <Dropdown
              isLoading={riskStandards.isWorking}
              loadError={riskStandards.errorMessage}
              items={riskStandards.data || []}
              selectedItem={riskItem.riskStandard}
              onChange={value => dispatch(updateRiskProperties({
                riskStandard: value,
              }))}
              keyMapper={item => item.id.toString()}
              textMapper={item => item.name}
              loadItems={() => !riskStandards.data?.length
                ? dispatch(loadRiskStandards())
                : []
              }
              autoLoadItems={true}
              autoSelectValue={std28Id}
              disabledItemMapper={item => item.id !== std28Id}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Process System/Operation high level description"
            isRequired={true}
          >
            <textarea
              value={riskItem.highLevelDescription || ""}
              onChange={e => dispatch(updateRiskProperties({
                highLevelDescription: e.currentTarget.value,
              }))}
              maxLength={250}
              disabled={isViewOnly}
            />
          </LabeledControl>
        </FlexCol>

        <FlexCol>
          <LabeledControl
            label="Division Hierarchy"
            isRequired={true}
          >
            <Picker<IBusinessItem>
              pickerState={{
                ...businesses,
                selectedItems: riskItem.divisionHierarchy
                  ? (
                    riskItem.divisionHierarchy.map(x => ({
                      key: x.id,
                      item: {
                        ...x,
                        children: [],
                        parent: "",
                      },
                    }))
                  ) : businesses.selectedItems,
              }}
              title="Division Hierarchy"
              renderListItem={(item) => `${item.code} - ${item.name}`}
              renderSelectedItem={(item) => item.code}
              openAction={openPicker}
              loadAction={loadPickerItems}
              closeAction={closePicker}
              setItemsAction={setPickerItems}
              setSelectedItemsAction={setSelectedPickerItems}
              expandItemsAction={expandPickerItem}
              collapseItemsAction={collapsePickerItem}
              preserveItems={true}
              allowMultiSelect={true}
              displayMode="tree"
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Basin Hierarchy"
            isRequired={true}
          >
            <Picker<IBasinItem>
              pickerState={{
                ...basins,
                selectedItems: riskItem.basinHierarchy
                  ? (
                    riskItem.basinHierarchy.map(x => ({
                      key: x.id,
                      item: {
                        ...x,
                        children: [],
                        parent: "",
                      },
                    }))
                  ) : basins.selectedItems,
              }}
              title="Basin Hierarchy"
              renderListItem={(item) => `${item.code} - ${item.name}`}
              renderSelectedItem={(item) => item.code}
              openAction={openPicker}
              loadAction={loadPickerItems}
              closeAction={closePicker}
              setItemsAction={setPickerItems}
              setSelectedItemsAction={setSelectedPickerItems}
              expandItemsAction={expandPickerItem}
              collapseItemsAction={collapsePickerItem}
              preserveItems={true}
              allowMultiSelect={true}
              displayMode="tree"
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Country"
            isRequired={true}
          >
            <Picker<ICountry>
              pickerState={{
                ...countries,
                selectedItems: riskItem.country
                  ? [{
                    key: riskItem.country.id,
                    item: riskItem.country,
                  }] : countries.selectedItems,
              }}
              title="Country"
              renderListItem={(item) => item.name}
              renderSelectedItem={(item) => item.name}
              openAction={openPicker}
              loadAction={loadPickerItems}
              closeAction={closePicker}
              setItemsAction={setPickerItems}
              setSelectedItemsAction={setSelectedPickerItems}
              preserveItems={true}
              searchOptions={{
                behavior: "sync",
                filterItem: (item, filterValue) => !!(!filterValue
                  || (item.item
                    && item.item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)),
              }}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Facility"
            isRequired={true}
          >
            <Picker<IFacility>
              pickerState={{
                ...facilities,
                selectedItems: riskItem.facility
                  ? [{
                    key: riskItem.facility.id,
                    item: riskItem.facility,
                  }] : facilities.selectedItems,
              }}
              title="Facility"
              renderListItem={(item) => `${item.commonId} - ${item.name}`}
              renderSelectedItem={(item) => item.name}
              openAction={openPicker}
              loadAction={loadPickerItems}
              closeAction={closePicker}
              setItemsAction={setPickerItems}
              setSelectedItemsAction={setSelectedPickerItems}
              preserveItems={true}
              searchOptions={{
                show: true,
                asyncMinChars: 2,
                behavior: "async",
              }}
              noItemsMessage={"Please begin typing a search term to execute a search."}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Other Location"
          >
            <Dropdown
              isLoading={otherLocations.isWorking}
              loadError={otherLocations.errorMessage}
              items={otherLocations.data || []}
              selectedItem={riskItem.otherLocation}
              onChange={value => dispatch(updateRiskProperties({
                otherLocation: value,
              }))}
              keyMapper={item => item.id.toString()}
              textMapper={item => item.name}
              loadItems={() => !otherLocations.data?.length
                ? dispatch(loadOtherLocations())
                : []
              }
              autoLoadItems={true}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Other Location Description"
            isRequired={!!riskItem.otherLocation}
          >
            <textarea
              value={riskItem.otherLocationDescription || ""}
              onChange={e => dispatch(updateRiskProperties({
                otherLocationDescription: e.currentTarget.value,
              }))}
              maxLength={250}
              disabled={isViewOnly}
            />
          </LabeledControl>
        </FlexCol>

        <FlexCol>
          <LabeledControl
            label="Phase"
            isRequired={true}
          >
            <Dropdown
              isLoading={phases.isWorking}
              loadError={phases.errorMessage}
              items={phases.data || []}
              selectedItem={riskItem.phase}
              onChange={value => dispatch(updateRiskProperties({
                phase: value,
              }))}
              keyMapper={item => item.id.toString()}
              textMapper={item => item.name}
              loadItems={() => !phases.data?.length
                ? dispatch(loadPhases())
                : []
              }
              autoLoadItems={true}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Validity Period"
            isRequired={true}
          >
            <Dropdown
              isLoading={validityPeriods.isWorking}
              loadError={validityPeriods.errorMessage}
              items={validityPeriods.data || []}
              selectedItem={riskItem.validityPeriod}
              onChange={value => dispatch(updateRiskProperties({
                validityPeriod: value as IValidityPeriod,
              }))}
              keyMapper={item => item.id.toString()}
              textMapper={item => item.name}
              loadItems={() => !validityPeriods.data?.length
                ? dispatch(loadValidityPeriods())
                : []
              }
              autoLoadItems={true}
              isDisabled={isViewOnly}
            />
          </LabeledControl>

          <LabeledControl
            label="Major Accident Hazard Register UID"
          >
            {riskItem.mahruidPrefixed || <Note>Generated after creation.</Note>}
          </LabeledControl>

        </FlexCol>
      </FlexRow>

      {isLeaderEditorOpen && (
        <UsersEditorModal
          currentUsers={riskItem.teamLeader
            ? [riskItem.teamLeader]
            : []
          }
          onClose={() => dispatch(toggleLeaderEditorVisibility(false))}
          onSave={(users) => dispatch(updateRiskProperties({
            teamLeader: users.length
              ? users[0]
              : undefined,
          }))}
          title="Team Leader"
          allowExternal={true}
          isDisabled={isViewOnly}
        />
      )}

      {isTeamEditorOpen && (
        <UsersEditorModal
          currentUsers={riskItem.teamMembers
            ? riskItem.teamMembers
            : []
          }
          onClose={() => dispatch(toggleTeamEditorVisibility(false))}
          onSave={(users) => dispatch(updateRiskProperties({
            teamMembers: users,
          }))}
          title="Team Members"
          allowMultiple={true}
          allowExternal={true}
          isDisabled={isViewOnly}
        />
      )}

      {isReporterEditorOpen && (
        <UsersEditorModal
          currentUsers={riskItem.reporter
            ? [riskItem.reporter]
            : []
          }
          onClose={() => dispatch(toggleReporterEditorVisibility(false))}
          onSave={(users) => dispatch(updateRiskProperties({
            reporter: users.length
              ? users[0]
              : undefined,
          }))}
          title="Reporter"
          isDisabled={isViewOnly}
        />
      )}
    </Card>
  )
};

export default ProcessCard;
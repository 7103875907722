import { Routes } from "components/routing/Routing";
import { isArray } from "lodash";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Page from "shared/components/layout/page/Page";
import Table from "shared/components/layout/table/Table";
import editIcon from "shared/media/dls/edit-1.svg";
import filterIcon from "shared/media/dls/filter.svg";
import { IColumnItem } from "shared/types/columnTypes";
import { loadRiskList, toggleFilters } from "store/risk-list/RiskListSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { BusinessTypes } from "types/masterDataTypes";
import { IRiskHazardLineItemView, RiskMatrixCellColors, RiskMatrixValues } from "types/riskTypes";
import { formatRiskMatrixValue } from "utilities/riskMatrixUtilities";
import FiltersCard from "./FiltersCard";
import "./RiskListPage.scoped.scss";

const RiskListPage: React.FC = () => {
  const {
    hasMore,
    page,
    pageSize,
    riskItems,
    loadOperation,
    areFiltersVisible,
    appliedFilters,
  } = useAppSelector(store => store.riskList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadRiskList({
      pageNumber: 1,
      pageSize: pageSize,
    }));
  }, [dispatch, pageSize]);

  let renderComponent: React.ReactNode | undefined;
  let hasAnyFilters: boolean = false;

  if (loadOperation?.isWorking
    && page === 1) {
    renderComponent = (
      <Spinner />
    );
  } else if (!loadOperation?.isWorking
    && loadOperation?.errorMessage) {
    renderComponent = (
      <Banner
        type={BannerType.error}
      >
        {loadOperation.errorMessage}
      </Banner>
    );
  } else if (riskItems.length) {
    hasAnyFilters = Object
      .entries(appliedFilters)
      .some(entry => isArray(entry[1])
        ? entry[1].length
        : !!entry[1]);

    renderComponent = (
      <Table
        data={riskItems}
        columns={getColumnDefinitions()}
        columnRender={renderColumns}
        fetchMoreData={() => dispatch(loadRiskList({
          pageNumber: page,
          pageSize: pageSize,
        }))}
        showLoadingNextPage={true}
        isLoadingPage={loadOperation?.isWorking}
        hasMore={hasMore}
        height="100%"
        className="risk-table"
        preHeaderRows={(
          <tr
            className="header-groups"
          >
            <th
              className="cell-cmd"
            ></th>
            <th
              colSpan={6}
            >
              Header
            </th>
            <th
              className="spacer"
            >
            </th>
            <th
              colSpan={8}
            >
              Hazard Identification
            </th>
            <th
              className="spacer"
            >
            </th>
            <th
              colSpan={7}
            >
              HIRA Tools Used
            </th>
            <th
              className="spacer"
            >
            </th>
            <th
              colSpan={2}
            >
              Situation / Incident or event of concern
            </th>
            <th
              className="spacer"
            >
            </th>
            <th
              colSpan={2}
            >
              MAH Risk Matrix
            </th>
            <th
              className="spacer"
            >
            </th>
            <th
              colSpan={2}
            >
              Key Performance Indicators
            </th>
          </tr>
        )}
      />
    );
  } else {
    renderComponent = (
      <Banner
        type={BannerType.info}
      >
        No items found in the risk registry.
      </Banner>
    );
  }

  return (
    <FlexCol
      className="main-col"
    >
      <Page
        title="Major Accident Hazard Registry List"
        stretchChildren={true}
        headerControls={[{
          key: "filters",
          element: (
            <button
              className="secondary filter-button"
              onClick={() => dispatch(toggleFilters(!areFiltersVisible))}
            >
              <img
                alt=""
                src={filterIcon}
                className="icon-small"
              />
              filters{hasAnyFilters ? "*" : ""}
            </button>
          )
        }]
        }
      >
        <FiltersCard />

        <Card
          style={{
            flex: "auto",
            overflow: "auto",
            minHeight: areFiltersVisible ? "400px" : undefined,
          }}
        >
          {renderComponent}
        </Card>
      </Page>
    </FlexCol>
  );
};

export default RiskListPage;

function getColumnDefinitions(): IColumnItem<IRiskHazardLineItemView, keyof IRiskHazardLineItemView>[] {
  return [
    { header: "", key: "cmd", headerCellClassName: "cell-cmd", },
    { header: "Business Line", key: "businessLines", property: "businessLines", },
    { header: "Country", key: "country", property: "country", },
    { header: "Facility", key: "facility", property: "facility", },
    { header: "Process UID", key: "processUID", property: "processUID", },
    { header: "Process Description", key: "processDescription", property: "processDescription", },
    { header: "Phase", key: "phase", property: "phase", },
    { header: "", key: "spacer1", headerCellClassName: "spacer" },
    { header: "Hazard Number UID", key: "lineItemId", property: "lineItemId", },
    { header: "Hazard", key: "hazard", property: "hazard", },
    { header: "Hazard Description", key: "hazardDescription", property: "hazardDescription", },
    { header: "Source", key: "source", property: "source", },
    { header: "Tier1 Threshold (1 hour release)", key: "volume", property: "volume", },
    { header: "Max. Release Above Tier 1 Threshold?", key: "maxReleaseAboveTier1Threshold", property: "maxReleaseAboveTier1Threshold", },
    { header: "Point of Release", key: "pointOfRelease", property: "pointOfRelease", },
    { header: "Comments", key: "comments", property: "comments", },
    { header: "", key: "spacer2", headerCellClassName: "spacer" },
    { header: "Relative Ranking (RR)", key: "relativeRanking", property: "relativeRanking", isVerticalText: true, },
    { header: "What If Analysis (WI)", key: "whatIfAnalysis", property: "whatIfAnalysis", isVerticalText: true, },
    { header: "Safety Review (SR)", key: "safetyReview", property: "safetyReview", isVerticalText: true, },
    { header: "Preliminary Hazard Analysis (PreHA)", key: "prelimHazardAnalysis", property: "prelimHazardAnalysis", isVerticalText: true, },
    { header: "Checklist", key: "checklist", property: "checklist", isVerticalText: true, },
    { header: "Other", key: "other", property: "otherHIRATools", isVerticalText: true, },
    { header: "Other Description", key: "otherDescription", property: "otherHIRAToolsDescription", },
    { header: "", key: "spacer3", headerCellClassName: "spacer" },
    { header: "Type", key: "type", property: "type", },
    { header: "Description", key: "typeDescription", property: "typeDescription", },
    { header: "", key: "spacer4", headerCellClassName: "spacer" },
    { header: "Potential Risk", key: "potentialRisk", property: "potentialRisk", },
    { header: "Residual Risk", key: "residualRisk", property: "residualRisk", },
    { header: "", key: "spacer5", headerCellClassName: "spacer" },
    { header: "Tier 3", key: "kpiTier3", property: "kpiTier3", },
    { header: "Tier 4", key: "kpiTier4", property: "kpiTier4", },
  ];
}

function renderColumns(columnKey: string,
  boundObj: IRiskHazardLineItemView,
  objProperty: string) {
  if (columnKey === "cmd") {
    return getNodeCell((
      <Link
        to={Routes.ManageRiskReport.replace(":riskId", boundObj.id)}
      >
        <img
          src={editIcon}
          alt="View/Edit"
          title="View/Edit"
          className="icon-small button"
        />
      </Link>
    ), "cmd");
  } else if (objProperty === "businessLines") {
    return getTextCell(boundObj
      .businessLines
      .filter(x => x.type === BusinessTypes.BusinessLine)
      .map(x => x.code)
      .join(", "),
      columnKey);
  } else if (objProperty === "country") {
    return getTextCell(boundObj.country?.name, columnKey);
  } else if (objProperty === "facility") {
    return getTextCell(boundObj.facility?.name, columnKey);
  } else if (objProperty === "phase") {
    return getTextCell(boundObj.phase?.name, columnKey);
  } else if (objProperty === "hazard") {
    return getTextCell(boundObj.hazard?.description || "", columnKey);
  } else if (objProperty === "hazardDescription") {
    return getTextCell(boundObj.hazardDescription?.name || "", columnKey);
  } else if (objProperty === "pointOfRelease") {
    return getTextCell(boundObj.pointOfRelease?.name || "", columnKey);
  } else if (objProperty === "type") {
    return getTextCell(boundObj.type?.name || "", columnKey);
  } else if (objProperty === "potentialRisk") {
    return getRiskCell(boundObj.potentialRisk, columnKey);
  } else if (objProperty === "residualRisk") {
    return getRiskCell(boundObj.residualRisk, columnKey);
  } else if (columnKey.startsWith("spacer")) {
    return getSpacerCell(columnKey);
  } else {
    let cellValue = (boundObj as any)[objProperty];

    return getTextCell(!cellValue
      ? ""
      : String(cellValue), columnKey);
  }
}

function getNodeCell(node: React.ReactNode, key: string) {
  return (
    <td
      key={`cell-${key}`}
      className={`cell-${key}`}
    >
      {node}
    </td>
  );
}

function getTextCell(text: string, key: string) {
  return (
    <td
      className={`cell-${key}`}
      key={`cell-${key}`}
      title={text}
    >
      {text || ""}
    </td>
  );
}

function getRiskCell(risk: RiskMatrixValues | undefined, key: string) {
  return (
    <td
      className={`cell-${key}`}
      key={`cell-${key}`}
      style={risk
        ? {
          backgroundColor: RiskMatrixCellColors[risk],
        } : undefined
      }
    >
      {formatRiskMatrixValue(risk)}
    </td>
  );
}

function getSpacerCell(key: string) {
  return (
    <td
      className={`cell-spacer`}
      key={`cell-${key}`}
    >
    </td>
  );
}
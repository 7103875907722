import React from "react";
import "./Note.scoped.scss";

const Note: React.FC<React.HTMLProps<HTMLSpanElement>> = (props) =>
  <span
    {...props}
    className={`note ${props.className ?? ''}`}
  >
    {props.children}
  </span>;

export default Note;
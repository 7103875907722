import { isArray } from "lodash";
import { IAzureADUser } from "shared/types/userTypes";
import { ICurrentUser } from "types/userTypes";

export function formatSearchAzureADUsersResponse(obj: any): IAzureADUser[] {
  return isArray(obj)
    ? obj.map((x: any): IAzureADUser => formatUser(x))
    : [];
}

export function formatUser(obj: any): IAzureADUser {
  return {
    name: obj.name,
    email: obj.email,
  };
}

export function formatMyProfile(obj: any): ICurrentUser {
  return {
    name: obj.name,
    email: obj.email,
    roles: isArray(obj.roles)
      ? obj.roles
      : [],
  };
}
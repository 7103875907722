import React, { useCallback, useEffect, useRef } from "react";
import Note from "shared/components/common/note/Note";
import Spinner from "shared/components/common/spinner/Spinner";
import { IColumnItem } from "shared/types/columnTypes";
import "./Table.scoped.scss";
import TableHeader from "./TableHeader";
import TableRows from "./TableRows";

interface ITableProps<T, K extends keyof T> {
  data: Array<T>,
  columns: Array<IColumnItem<T, K>>,
  columnRender: (columnKey: string, boundObj: any, objProperty: string) => JSX.Element,
  fetchMoreData: () => void,
  hasMore: boolean,
  width?: string,
  height?: string,
  className?: string,
  preHeaderRows?: React.ReactNode,
  showLoadingNextPage?: boolean,
  isLoadingPage?: boolean,
}

const Table = <T, K extends keyof T>({
  data,
  columns,
  columnRender,
  fetchMoreData,
  hasMore,
  width,
  height,
  className,
  preHeaderRows,
  showLoadingNextPage,
  isLoadingPage,
}: ITableProps<T, K>): JSX.Element => {
  const tableElmnt = useRef<HTMLTableElement>(null);
  const wrapperElmnt = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!hasMore
      || isLoadingPage
      || !tableElmnt.current
      || !wrapperElmnt.current) {
      return;
    }

    let wrapperOffsetHeight = wrapperElmnt.current.offsetHeight;
    let wrapperScrollTop = wrapperElmnt.current.scrollTop;
    let wrapperScrollPos = wrapperOffsetHeight + wrapperScrollTop;

    let doFetch = wrapperScrollPos >= tableElmnt.current.offsetHeight;

    if (!doFetch) {
      const lastRow = tableElmnt.current.rows.length > 0
        && tableElmnt.current.rows[tableElmnt.current.rows.length - 1];

      if (!lastRow) {
        return;
      }

      doFetch = wrapperScrollPos <= lastRow.offsetHeight;
    }

    if (doFetch) {
      fetchMoreData();
    }
  }, [fetchMoreData, tableElmnt, wrapperElmnt, hasMore, isLoadingPage]);

  useEffect(() => {
    window.addEventListener("resize", handleScroll);

    return () => window.removeEventListener("resize", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (hasMore
      && !isLoadingPage) {
      handleScroll();
    }
  }, [hasMore, isLoadingPage, handleScroll])

  return (
    <div
      className="outer-wrapper"
    >
      <div
        className="table-wrapper"
        ref={wrapperElmnt}
        onScroll={handleScroll}
        style={height
          ? {
            height: height || "60vh",
          } : undefined
        }
      >
        <table
          ref={tableElmnt}
          className={`sticky-table ${className || ""}`}
          style={width
            ? {
              width: width,
            } : undefined
          }
        >
          <thead>
            {preHeaderRows}
            <TableHeader
              columns={columns}
            />
          </thead>
          <TableRows
            data={data}
            columns={columns}
            columnRender={columnRender}
          />
        </table>
      </div>

      {showLoadingNextPage
        && isLoadingPage && (
          <span
            className="page-loader"
          >
            <Spinner
              className="icon-large"
            />
            <Note>
              Loading next page...
            </Note>
          </span>
        )}
    </div>
  );
};

export default Table;
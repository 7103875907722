import React from "react";
import "../GlobalStyles.scss";
import FlowLayout from "./FlowLayout";
import "./MainLayout.scoped.scss";
import MainMenu from "./menu/MainMenu";
import { IMenuItemProps } from "./menu/MenuItem";
import TitleBar from "./titlebar/TitleBar";

export interface IMainLayoutProps {
  menuItems: IMenuItemProps[],
  siteTitle: string,
  siteTitleUrl: string,
  children?: React.ReactNode,
}

const MainLayout: React.FC<IMainLayoutProps> = ({
  menuItems,
  siteTitle,
  siteTitleUrl,
  children,
}) =>
  <div
    className="site"
  >
    <TitleBar
      title={siteTitle}
      titleUrl={siteTitleUrl}
    />
    <div
      className="lower-section"
    >
      <MainMenu
        menuItems={menuItems}
      />
      <FlowLayout>
        {children}
      </FlowLayout>
    </div>
  </div>;

export default MainLayout;
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Prompt from "shared/components/common/prompt/Prompt";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Page from "shared/components/layout/page/Page";
import { IPageHeaderControl } from "shared/components/layout/page/PageBar";
import { deleteRiskItem, loadRiskItem, resetRiskStore, saveRiskItem, setIsViewOnly, toggleDeleteConfirmation, updateRiskProperties } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { isRiskItemReadOnly } from "utilities/riskUtilities";
import "./ManageRiskPage.scoped.scss";
import HazardListCard from "./hazard-list-card/HazardListCard";
import HazardIdentificationCard from "./main-props/hazard-id-card/HazardIdentificationCard";
import ProcessCard from "./main-props/process-card/ProcessCard";
import ReferencesCard from "./main-props/references-card/ReferencesCard";

interface IManageRiskPageProps {
  mode: "new" | "edit",
}

const ManageRiskPage: React.FC<IManageRiskPageProps> = ({
  mode,
}) => {
  const currentUser = useAppSelector(store => store.currentUser.currentUser);
  const riskItem = useAppSelector(store => store.manageRisk.riskItem);
  const isDirty = useAppSelector(store => store.manageRisk.isDirty);
  const isViewOnly = useAppSelector(store => store.manageRisk.isViewOnly);
  const loadRiskItemOp = useAppSelector(store => store.manageRisk.loadRiskItemOp);
  const saveRiskItemOp = useAppSelector(store => store.manageRisk.saveRiskItemOp);
  const deleteRiskItemOp = useAppSelector(store => store.manageRisk.deleteRiskItemOp);
  const isConfirmDeleteVisible = useAppSelector(store => store.manageRisk.isConfirmDeleteVisible);

  const dispatch = useAppDispatch();
  const {
    riskId: id,
  } = useParams();

  const shouldBeViewOnly = riskItem
    ? isRiskItemReadOnly(riskItem, currentUser)
    : false;

  const {
    name,
    email,
  } = currentUser;

  useEffect(() => {
    if (mode === "edit") {
      dispatch(loadRiskItem(id || ""));
    } else if (mode === "new") {
      dispatch(updateRiskProperties({
        reporter: {
          name: name,
          email: email,
        },
      }));
    }
  }, [mode, id, dispatch, name, email]);

  useEffect(() => {
    return () => {
      dispatch(resetRiskStore());
    };
  }, [dispatch, mode]);

  useEffect(() => {
    if (isViewOnly !== shouldBeViewOnly) {
      dispatch(setIsViewOnly(shouldBeViewOnly));
    }
  }, [isViewOnly, shouldBeViewOnly, dispatch]);

  let renderComponent: React.ReactNode | undefined;
  let headerControls: IPageHeaderControl[] = [];

  if (loadRiskItemOp?.isWorking) {
    renderComponent = (
      <ModalSpinner />
    );
  } else if (loadRiskItemOp) {
    renderComponent = (
      <Banner
        type={BannerType.error}
      >
        {loadRiskItemOp.errorMessage || "Failed to load risk entry."}
      </Banner>
    );
  } else {
    if (!isViewOnly) {

      headerControls.push({
        key: "buttons",
        element: (
          <div
            className="buttons"
          >
            {riskItem?.id && (
              <button
                className="secondary"
                onClick={() => dispatch(toggleDeleteConfirmation(true))}
              >
                delete
              </button>
            )}

            <button
              className="primary"
              disabled={!isDirty
                || !riskItem
              }
              onClick={() => dispatch(saveRiskItem())}
            >
              save
            </button>
          </div>
        )
      });
    }

    renderComponent = (
      <>
        <ProcessCard />
        <ReferencesCard />
        <HazardIdentificationCard />

        <HazardListCard />

        {(saveRiskItemOp?.isWorking
          || deleteRiskItemOp?.isWorking) && (
            <ModalSpinner />
          )}
      </>
    );
  }

  return (
    <Page
      title={mode === "new"
        ? "New Risk Report"
        : "Manage Risk Report"
      }
      headerControls={headerControls}
    >
      {renderComponent}

      {isConfirmDeleteVisible && (
        <ConfirmModal
          header="Confirm Delete"
          message={
            <Banner
              type={BannerType.warn}
            >
              Are you sure you want to remove this item? This operation cannot be undone.
            </Banner>
          }
          onYesClicked={() => dispatch(deleteRiskItem())}
          onNoClicked={() => dispatch(toggleDeleteConfirmation(false))}
        />
      )}

      <Prompt
        message={"Are you sure you want to leave? All unsaved changes will be lost."}
        isDirty={isDirty}
      />
    </Page>
  );
};

export default ManageRiskPage;
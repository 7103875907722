import { RiskMatrixCellColors, RiskMatrixColors, RiskMatrixColumns, RiskMatrixRows, RiskMatrixValues } from "types/riskTypes";

export function formatRiskMatrixValue(value: RiskMatrixValues | undefined): string {
  if (!value) {
    return '';
  }

  return value.split('').join('-');
}

export function getRiskMatrixValuesByRow(row: RiskMatrixRows): RiskMatrixValues[] {
  return Object
    .values(RiskMatrixValues)
    .filter(x => x.endsWith(row.toString()[row.toString().length - 1]))
    .map(x => x as RiskMatrixValues);
}

export function getRiskMatrixValuesByColumn(column: RiskMatrixColumns): RiskMatrixValues[] {
  return Object
    .values(RiskMatrixValues)
    .filter(x => x.startsWith(column.toString()))
    .map(x => x as RiskMatrixValues);
}

export function getRiskMatrixValuesByColor(color: RiskMatrixColors): RiskMatrixValues[] {
  return Object
    .entries(RiskMatrixCellColors)
    .filter(x => x[1] === color)
    .map(x => x[0] as RiskMatrixValues);
}
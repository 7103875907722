import { authGetJson } from "auth/authFetches";
import config from "config";
import { IAzureADUser } from "shared/types/userTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { ICurrentUser } from "types/userTypes";
import { formatMyProfile, formatSearchAzureADUsersResponse } from "./userFormatters";

class UsersApi {
  public async searchADUsers(searchTerm: string): Promise<IAzureADUser[]> {
    const response = await authGetJson(`${config.endpoints.users.searchADUsers}?searchTerm=${searchTerm}`);
    throwIfResponseError(response);
    return formatSearchAzureADUsersResponse((await response.json()));
  }

  public async getMyProfile(email: string): Promise<ICurrentUser> {
    const response = await authGetJson(config.endpoints.users.myProfile.replace(":email", email));

    if (response.status === 404) {
      throw new Error("Profile not found.");
    } else {
      await throwIfResponseError(response);
    }

    return formatMyProfile(await response.json());
  }
}

const usersApi = new UsersApi();
export default usersApi;
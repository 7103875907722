import React from "react";
import "./RadioButtonSet.scoped.scss";

interface IRadioButtonOption {
  text: string,
  value: string | number,
}

interface IRadioButtonSetProps {
  options: IRadioButtonOption[],
  selectedValue?: string | number,
  onChange(newValue: string | number | undefined): void,
  allowClickToDisable?: boolean,
  isDisabled?: boolean,
}

const RadioButtonSet: React.FC<IRadioButtonSetProps> = ({
  options,
  selectedValue,
  onChange,
  allowClickToDisable,
  isDisabled,
}) =>
  <div
    className="radio-button-set"
  >
    {options.map(opt => (
      <button
        className={`radio-button ${selectedValue === opt.value ? "primary" : "secondary"}`}
        key={opt.value}
        onClick={() => selectedValue === opt.value
          && allowClickToDisable
          ? onChange(undefined)
          : (selectedValue === opt.value
            ? undefined
            : onChange(opt.value)
          )
        }
        disabled={isDisabled}
      >
        {opt.text}
      </button>
    ))}
  </div>;

export default RadioButtonSet;
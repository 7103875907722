import React, { ButtonHTMLAttributes, useEffect } from "react";
import Portal from "../portal/Portal";
import "./Modal.scoped.scss";

export interface IModalProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * Specifies if the close button (x) should be shown on the right side of the header.
   */
  showCloseButton?: boolean,
  /**
   * Specifies what should be shown at the top of the modal.
   * Default is false.
   */
  header?: string | React.ReactNode,
  /**
   * Specifies a hard-coded width. If not specified, it will conform to its content's width.
   */
  width?: number | string,
  /**
   * Specifies a minimum width.
   */
  minWidth?: number | string,
  /**
   * Specifies a hard-coded max-width.
   */
  maxWidth?: number | string,
  /**
   * Specifies the modal's theme for various coloring.
   */
  theme?: ModalTheme,
  /**
   * Specifies the callback for when the closeButton is clicked.
   */
  onCloseButtonClicked?(): void,
  /**
   * If customControls are provided, they will be rendered at the bottom of the modal instead of the buttons.
   */
  customControls?: React.ReactNode,
  /**
   * The array of buttons that should be rendered (in order) at the bottom of the modal.
   */
  buttons?: (IModalButton & ButtonHTMLAttributes<HTMLButtonElement>)[],
  children: React.ReactNode,
}

export interface IModalButton {
  key: string,
  text: React.ReactNode,
}

export enum ModalTheme {
  Normal = "modal",
  Error = "modal error",
}

const Modal: React.FC<IModalProps> = (props) => {
  useEffect(() => {
    let pageOffset = window.pageYOffset;

    if (props.isOpen) {
      document.body.style.top = `-${pageOffset}px`;
      document.body.classList.add('has-open-modal');
    }

    return () => {
      document.body.classList.remove('has-open-modal');
      document.body.style.removeProperty('top');
      window.scrollTo(0, pageOffset || 0);
    };
  }, [props.isOpen]);

  if (!props.isOpen) {
    return null;
  }

  return (
    <Portal
      className="modal-portal"
    >
      <div
        className="fade"
      >
      </div>
      <div
        className={props.theme || ModalTheme.Normal}
        style={{
          width: props.width,
          minWidth: props.minWidth,
          maxWidth: props.maxWidth,
        }}
      >
        <div
          className="top"
        >
          <span
            className="header"
          >
            {props.header}
          </span>
          {props.showCloseButton &&
            <span
              className="close"
              onClick={props.onCloseButtonClicked}
            >
              x
            </span>
          }
        </div>
        <div
          className="middle"
        >
          {props.children}
        </div>
        {(props.customControls
          || props.buttons) &&
          <div
            className="bottom"
          >
            {props.customControls
              ? props.customControls
              : (
                <div
                  className="buttons"
                >
                  {props.buttons?.map(btnProps => {
                    let btnAttrs = { ...btnProps };
                    delete btnAttrs.text;
                    
                    return (
                      <button
                        {...btnAttrs}
                      >
                        {btnProps.text}
                      </button>
                    );
                  })}
                </div>
              )
            }
          </div>
        }
      </div>
    </Portal>
  );
}

export default Modal;
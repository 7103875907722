import React from "react";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { ManageRiskCards, toggleCardState } from "store/manage-risk/ManageRiskSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { BusinessTypes } from "types/masterDataTypes";
import "./ProcessCardTitle.scoped.scss";

const ProcessCardTitle: React.FC = () => {
  const riskItem = useAppSelector(store => store.manageRisk.riskItem);
  const cardStates = useAppSelector(store => store.manageRisk.cardStates);
  const dispatch = useAppDispatch();

  if (!riskItem) {
    return <>Process</>;
  }

  const isOpen = cardStates.find(x => x.card === ManageRiskCards.process)?.isOpen ?? false;

  const hierarchyTitle = [
    riskItem.divisionHierarchy?.slice().sort((a, b) => {
      let aNum = Object.keys(BusinessTypes).indexOf(a.type);
      let bNum = Object.keys(BusinessTypes).indexOf(b.type);

      return aNum < bNum
        ? -1
        : (a.name < b.name
          ? -1
          : 1
        );
    }).map(x => x.code).join(", "),
    riskItem.country?.name,
    riskItem.facility?.name,
  ].filter(x => x?.trim()).join(' - ');

  const hasNoTitleText = !hierarchyTitle
    && !riskItem.phase
    && !riskItem.processOperationUID;

  return (
    <FlexRow>
      <span
        className="title-section"
      >
        {hasNoTitleText
          ? (
            <h4>Process</h4>
          ) : (
            <>
              {!!hierarchyTitle && (
                <h4>
                  {hierarchyTitle}
                </h4>
              )}

              {riskItem.phase && (
                <h4>
                  {riskItem.phase.name}
                </h4>
              )}

              {!!riskItem.processOperationUID && (
                <h4>
                  Process / Operation UID {riskItem.processOperationUID}
                </h4>
              )}
            </>
          )}
      </span>
      <CollapseSwitch
        isOpen={isOpen}
        onClick={() => dispatch(toggleCardState({
          card: ManageRiskCards.process,
          isOpen: !isOpen,
        }))}
      />
    </FlexRow >
  );
};

export default ProcessCardTitle;
import GroupedDropdown, { IGroupedDropdownItem } from "components/controls/grouped-dropdown/GroupedDropdown";
import { isArray } from "lodash";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "shared/components/controls/dropdown/Dropdown";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import RadioButtonSet from "shared/components/controls/radio-button-set/RadioButtonSet";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import filtersIcon from "shared/media/dls/filter.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import formatDate from "shared/utilities/dateFormatters";
import { applyFilters, clearFilters, closePicker, collapsePickerItem, expandPickerItem, loadHazards, loadOtherLocations, loadPhases, loadPickerItems, openPicker, setPickerItems, setSelectedPickerItems, toggleFilters, updateUnappliedFilters } from "store/risk-list/RiskListSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { YesNo } from "types/answerTypes";
import { IBasinItem, IBusinessItem, ICountry, IFacility, IHazard } from "types/masterDataTypes";
import "./FiltersCard.scoped.scss";
import RiskFilterModal from "./risk-filter-modal/RiskFilterModal";

const FiltersCard: React.FC = () => {
  const {
    areFiltersVisible,
    unappliedFilters,
    pickerData: {
      countries,
      facilities,
      basins,
      businesses,
    },
    otherLocations,
    hazards,
    phases,
  } = useAppSelector(store => store.riskList);
  const dispatch = useAppDispatch();
  const [openRiskFilterType, setOpenRiskFilterType] = useState<"Potential" | "Residual" | undefined>();

  return (
    <Card
      style={{
        display: areFiltersVisible ? "flex" : "none",
      }}
    >
      <FlexCol>
        <FlexRow
          className="filters-row"
        >
          <FlexCol>
            <LabeledControl
              label="Dates Between"
              className="dates"
            >
              <DatePicker
                onChange={value => isArray(value)
                  ? undefined
                  : dispatch(updateUnappliedFilters({
                    startDate: (value as Date).getTime(),
                  }))
                }
                value={unappliedFilters.startDate
                  ? formatDate(new Date(unappliedFilters.startDate))
                  : undefined
                }
                dateFormat="yyyy-MMM-dd"
              />
              &amp;
              <DatePicker
                onChange={value => isArray(value)
                  ? undefined
                  : dispatch(updateUnappliedFilters({
                    endDate: (value as Date).getTime(),
                  }))
                }
                value={unappliedFilters.endDate
                  ? formatDate(new Date(unappliedFilters.endDate))
                  : undefined
                }
                dateFormat="yyyy-MMM-dd"
              />
            </LabeledControl>

            <LabeledControl
              label="Reporter"
            >
              <input
                type="text"
                value={unappliedFilters.reporter || ""}
                onChange={e => dispatch(updateUnappliedFilters({
                  reporter: e.currentTarget.value,
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Team Leader"
            >
              <input
                type="text"
                value={unappliedFilters.teamLeader || ""}
                onChange={e => dispatch(updateUnappliedFilters({
                  teamLeader: e.currentTarget.value,
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Process System/Operation high level description"
            >
              <input
                type="text"
                value={unappliedFilters.highLevelDescription || ""}
                onChange={e => dispatch(updateUnappliedFilters({
                  highLevelDescription: e.currentTarget.value,
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Hazard"
              className="labeled-hazard"
            >
              <GroupedDropdown
                loadOperation={{
                  ...hazards,
                  data: hazards.data?.map((x: IHazard): IGroupedDropdownItem => ({
                    value: x.id,
                    parentValue: hazards.data?.find(z => z.number === x.parent)?.id || "",
                    text: `${x.number} - ${x.description}`,
                  }))
                }}
                loadItems={() => dispatch(loadHazards())}
                onValueChanged={item => dispatch(updateUnappliedFilters({
                  hazardId: item?.value,
                }))}
                selectedId={unappliedFilters.hazardId}
                allowSelectGroup={false}
              />
            </LabeledControl>
          </FlexCol>

          <FlexCol>
            <LabeledControl
              label="Division Hierarchy"
            >
              <Picker<IBusinessItem>
                pickerState={businesses}
                title="Division Hierarchy"
                renderListItem={(item) => `${item.code} - ${item.name}`}
                renderSelectedItem={(item) => item.code}
                openAction={openPicker}
                loadAction={loadPickerItems}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                expandItemsAction={expandPickerItem}
                collapseItemsAction={collapsePickerItem}
                preserveItems={true}
                allowMultiSelect={true}
                displayMode="tree"
              />
            </LabeledControl>

            <LabeledControl
              label="Basin Hierarchy"
            >
              <Picker<IBasinItem>
                pickerState={basins}
                title="Basin Hierarchy"
                renderListItem={(item) => `${item.code} - ${item.name}`}
                renderSelectedItem={(item) => item.code}
                openAction={openPicker}
                loadAction={loadPickerItems}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                expandItemsAction={expandPickerItem}
                collapseItemsAction={collapsePickerItem}
                preserveItems={true}
                allowMultiSelect={true}
                displayMode="tree"
              />
            </LabeledControl>

            <LabeledControl
              label="Country"
            >
              <Picker<ICountry>
                pickerState={countries}
                title="Country"
                renderListItem={(item) => item.name}
                renderSelectedItem={(item) => item.name}
                openAction={openPicker}
                loadAction={loadPickerItems}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                preserveItems={true}
                allowMultiSelect={true}
                searchOptions={{
                  behavior: "sync",
                  filterItem: (item, filterValue) => !!(!filterValue
                    || (item.item
                      && item.item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)),
                }}
              />
            </LabeledControl>

            <LabeledControl
              label="Facility"
            >
              <Picker<IFacility>
                pickerState={facilities}
                title="Facility"
                renderListItem={(item) => `${item.commonId} - ${item.name}`}
                renderSelectedItem={(item) => item.name}
                openAction={openPicker}
                loadAction={loadPickerItems}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                preserveItems={true}
                searchOptions={{
                  show: true,
                  asyncMinChars: 2,
                  behavior: "async",
                }}
                allowMultiSelect={true}
                noItemsMessage={"Please begin typing a search term to execute a search."}
              />
            </LabeledControl>

            <LabeledControl
              label="Other Location"
            >
              <Dropdown
                isLoading={otherLocations.isWorking}
                loadError={otherLocations.errorMessage}
                items={otherLocations.data || []}
                selectedItem={(otherLocations.data || []).find(x => x.id === unappliedFilters.otherLocationId)}
                onChange={value => dispatch(updateUnappliedFilters({
                  otherLocationId: value?.id,
                }))}
                keyMapper={item => item.id.toString()}
                textMapper={item => item.name}
                loadItems={() => !otherLocations.data?.length
                  ? dispatch(loadOtherLocations())
                  : []
                }
                autoLoadItems={true}
              />
            </LabeledControl>

            <LabeledControl
              label="Potential Risk"
            >
              <button
                className="secondary"
                onClick={() => setOpenRiskFilterType("Potential")}
              >
                {unappliedFilters.potentialRisks.length > 0
                  ? `${unappliedFilters.potentialRisks.length} selected`
                  : "Click to choose"
                }
              </button>
            </LabeledControl>
          </FlexCol>

          <FlexCol>
            <LabeledControl
              label="Valid"
            >
              <RadioButtonSet
                isDisabled={true}
                options={Object.entries(YesNo).map(val => ({
                  value: val[0],
                  text: val[0],
                }))}
                selectedValue={unappliedFilters.valid}
                allowClickToDisable={true}
                onChange={val => dispatch(updateUnappliedFilters({
                  valid: val === undefined
                    ? undefined
                    : YesNo[val as YesNo],
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Phase"
            >
              <Dropdown
                isLoading={phases.isWorking}
                loadError={phases.errorMessage}
                items={phases.data || []}
                selectedItem={(phases.data || []).find(x => x.id === unappliedFilters.phaseId)}
                onChange={value => dispatch(updateUnappliedFilters({
                  phaseId: value?.id,
                }))}
                keyMapper={item => item.id.toString()}
                textMapper={item => item.name}
                loadItems={() => !phases.data?.length
                  ? dispatch(loadPhases())
                  : []
                }
                autoLoadItems={true}
              />
            </LabeledControl>

            <LabeledControl
              label="Process / Operation UID"
            >
              <input
                type="text"
                value={unappliedFilters.processOperationUID || ""}
                onChange={e => dispatch(updateUnappliedFilters({
                  processOperationUID: e.currentTarget.value,
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="MAHRUID"
            >
              <input
                type="text"
                value={unappliedFilters.mahruidLineNumber || ""}
                onChange={e => dispatch(updateUnappliedFilters({
                  mahruidLineNumber: e.currentTarget.value,
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Other Location Description"
            >
              <input
                type="text"
                value={unappliedFilters.otherLocationDescription || ""}
                onChange={e => dispatch(updateUnappliedFilters({
                  otherLocationDescription: e.currentTarget.value,
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Residual Risk"
            >
              <button
                className="secondary"
                onClick={() => setOpenRiskFilterType("Residual")}
              >
                {unappliedFilters.residualRisks.length > 0
                  ? `${unappliedFilters.residualRisks.length} selected`
                  : "Click to choose"
                }
              </button>
            </LabeledControl>
          </FlexCol>

          <FlexCol>
            <LabeledControl
              label="Well Control Risk?"
            >
              <RadioButtonSet
                options={Object.entries(YesNo).map(val => ({
                  value: val[0],
                  text: val[0],
                }))}
                selectedValue={unappliedFilters.wellControlRisk}
                allowClickToDisable={true}
                onChange={val => dispatch(updateUnappliedFilters({
                  wellControlRisk: val === undefined
                    ? undefined
                    : YesNo[val as YesNo],
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Permanent Processing Facility/Equipment"
            >
              <RadioButtonSet
                options={Object.entries(YesNo).map(val => ({
                  value: val[0],
                  text: val[0],
                }))}
                selectedValue={unappliedFilters.permanentProcessing}
                allowClickToDisable={true}
                onChange={val => dispatch(updateUnappliedFilters({
                  permanentProcessing: val === undefined
                    ? undefined
                    : YesNo[val as YesNo],
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Temporary Processing Facility/Equipment"
            >
              <RadioButtonSet
                options={Object.entries(YesNo).map(val => ({
                  value: val[0],
                  text: val[0],
                }))}
                selectedValue={unappliedFilters.temporaryProcessing}
                allowClickToDisable={true}
                onChange={val => dispatch(updateUnappliedFilters({
                  temporaryProcessing: val === undefined
                    ? undefined
                    : YesNo[val as YesNo],
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Continuous"
            >
              <RadioButtonSet
                options={Object.entries(YesNo).map(val => ({
                  value: val[0],
                  text: val[0],
                }))}
                selectedValue={unappliedFilters.continuous}
                allowClickToDisable={true}
                onChange={val => dispatch(updateUnappliedFilters({
                  continuous: val === undefined
                    ? undefined
                    : YesNo[val as YesNo],
                }))}
              />
            </LabeledControl>

            <LabeledControl
              label="Batch"
            >
              <RadioButtonSet
                options={Object.entries(YesNo).map(val => ({
                  value: val[0],
                  text: val[0],
                }))}
                selectedValue={unappliedFilters.batch}
                allowClickToDisable={true}
                onChange={val => dispatch(updateUnappliedFilters({
                  batch: val === undefined
                    ? undefined
                    : YesNo[val as YesNo],
                }))}
              />
            </LabeledControl>
          </FlexCol>
        </FlexRow>
        <FlexRow
          className="button-row"
        >
          <button
            className="secondary"
            onClick={() => dispatch(toggleFilters(false))}
          >
            close
          </button>

          <button
            className="secondary"
            onClick={() => dispatch(clearFilters())}
          >
            clear all
          </button>

          <button
            className="primary apply-button"
            onClick={() => dispatch(applyFilters())}
          >
            <img
              src={filtersIcon}
              alt=""
              className="icon-small"
            />
            apply
          </button>
        </FlexRow>
      </FlexCol>

      {openRiskFilterType !== undefined && (
        <RiskFilterModal
          onCancel={() => setOpenRiskFilterType(undefined)}
          onValuesSelected={selValues => {
            let newValues = openRiskFilterType === "Potential"
              ? unappliedFilters.potentialRisks.slice()
              : unappliedFilters.residualRisks.slice();

            if (selValues.length === 1) {
              // If there is only 1 selected value, toggle it.
              const oldIx = newValues.indexOf(selValues[0]);

              if (oldIx === -1) {
                // This item isn't already selected. Add it to the list.
                newValues.push(selValues[0]);
              } else {
                // This item is already in the list. Remove it.
                newValues.splice(oldIx, 1);
              }
            } else {
              // If there is more than 1 selected value, ensure they are all
              // selected unless they are all already selected, in which case
              // deselect them all.
              const oldCount = newValues
                .filter(nv => selValues.some(s => s === nv))
                .length;

              if (oldCount === selValues.length) {
                // All of the items were already selected. Deselect them all.
                newValues = newValues
                  .filter(nv => !selValues.some(s => s === nv));
              } else {
                // Ensure all of the items are selected.
                newValues = newValues
                  .filter(nv => !selValues.some(s => s === nv))
                  .concat(selValues.slice());
              }
            }

            if (openRiskFilterType === "Potential") {
              dispatch(updateUnappliedFilters({
                potentialRisks: newValues,
              }));
            } else if (openRiskFilterType === "Residual") {
              dispatch(updateUnappliedFilters({
                residualRisks: newValues,
              }));
            } else {
              dispatch(showErrorToast("An unknown risk filter modal type is selected."));
            }
          }}
          type={openRiskFilterType}
          isDisabled={false}
          selectedValues={openRiskFilterType === "Potential"
            ? unappliedFilters.potentialRisks
            : unappliedFilters.residualRisks
          }
        />
      )}
    </Card>
  );
};

export default FiltersCard;
import { formatCountry, formatFacility, formatGetDivisionsResponse, formatHazard, formatHazardDescription, formatPhase, formatSituation } from "api/master-data/masterDataFormatters";
import { isArray } from "lodash";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { BusinessTypes, IBusinessItem } from "types/masterDataTypes";
import { IRiskHazardItem, IRiskHazardLineItemView, IRiskItem, IRiskListFilters } from "types/riskTypes";

function formatCreateUpdateRiskItem(item: IRiskItem): any {
  return {
    dateOfAssessment: new Date(item.dateOfAssessment || 0),
    highLevelDescription: item.highLevelDescription,
    reporter: item.reporter,
    validityPeriodId: item.validityPeriod?.id,
    phaseId: item.phase?.id,
    otherLocationId: item.otherLocation?.id,
    riskStandardId: item.riskStandard?.id,
    divisionHierarchyIds: item.divisionHierarchy?.map(x => x.id),
    basinHierarchyIds: item.basinHierarchy?.map(x => x.id),
    countryId: item.country?.id,
    facilityId: item.facility?.id,
    teamLeader: item.teamLeader,
    teamMembers: item.teamMembers,
    otherLocationDescription: item.otherLocationDescription,
    wellControlRisk: item.wellControlRisk,
    existingProcess: item.existingProcess,
    existingProcedures: item.existingProcedures,
    similarExperience: item.similarExperience,
    processFlowDiagram: item.processFlowDiagram,
    pandID: item.PandID,
    operatingHistory: item.operatingHistory,
    permanentProcessingFacilityOrEquipment: item.permanentProcessingFacilityOrEquipment,
    temporaryProcessingFacilityOrEquipment: item.temporaryProcessingFacilityOrEquipment,
    continuousOperation: item.continuousOperation,
    batchOperation: item.batchOperation,
    prevMAHRegisterLink: item.prevMAHRegisterLink,
    regulatoryReqsLink: item.regulatoryReqsLink,
    contracturalReqsLink: item.contracturalReqsLink,
    assumptions: item.assumptions,
    emergencyResponsePlan: item.emergencyResponsePlan,
    riskWorkshopReportLink: item.riskWorkshopReportLink,
    lineItems: formatCreateUpdateRiskLineItems(item.hazards),
  };
}

export function formatCreateUpdateRiskLineItems(lineItems: IRiskHazardItem[]): any[] {
  return lineItems.map((item): any => ({
    lineItemNumber: item.lineItemNumber,
    hazardId: item.hazard?.id,
    hazardDescriptionId: item.hazardDescription?.id,
    source: item.source,
    volume: item.volume,
    comments: item.comments,
    maxReleaseAboveTier1Threshold: item.maxReleaseAboveTier1Threshold,
    pointOfReleaseId: item.pointOfRelease?.id,
    otherPointOfReleaseDescription: item.otherPointOfReleaseDescription,
    relativeRanking: item.relativeRanking,
    whatIfAnalysis: item.whatIfAnalysis,
    safetyReview: item.safetyReview,
    prelimHazardAnalysis: item.prelimHazardAnalysis,
    checklist: item.checklist,
    otherHIRATools: item.otherHIRATools,
    otherHIRAToolsDescription: item.otherHIRAToolsDescription,
    typeId: item.type?.id,
    typeDescription: item.typeDescription,
    potentialRisk: item.potentialRisk,
    residualRisk: item.residualRisk,
    kpiTier3: item.kpiTier3,
    kpiTier4: item.kpiTier4,
  }));
}

export function formatCreateRiskItemRequest(item: IRiskItem): any {
  return formatCreateUpdateRiskItem(item);
}

export function formatUpdateRiskItemRequest(item: IRiskItem): any {
  return formatCreateUpdateRiskItem(item);
}

export function formatRiskLineItemViewResponse(obj: any): IPagedResult<IRiskHazardLineItemView> {
  let items = (isArray(obj.results)
    ? (obj.results) as any[]
    : []).map(x => formatRiskLineItemView(x));

  return {
    currentPage: Number(obj.currentPage),
    pageSize: Number(obj.pageSize),
    results: items,
  };
}

export function formatRiskLineItemView(item: any): IRiskHazardLineItemView {
  return {
    businessLines: formatGetDivisionsResponse(item.divisionHierarchies).filter((x: IBusinessItem) => x.type === BusinessTypes.BusinessLine),
    country: formatCountry(item.country),
    facility: formatFacility(item.facility),
    phase: formatPhase(item.phase),
    id: item.id,
    processDescription: item.highLevelDescription,
    lineItemId: item.lineItem.lineItemId,
    lineItemNumber: item.lineItem.lineItemNumber,
    hazard: formatHazard(item.lineItem.hazard),
    processUID: item.processOperationUID,
    kpiTier3: item.lineItem.kpiTier3,
    kpiTier4: item.lineItem.kpiTier4,
    checklist: item.lineItem.checklist,
    hazardDescription: item.lineItem.hazardDescription
      ? formatHazardDescription(item.lineItem.hazardDescription)
      : undefined,
    source: item.lineItem.source,
    volume: item.lineItem.volume,
    maxReleaseAboveTier1Threshold: item.lineItem.maxReleaseAboveTier1Threshold,
    pointOfRelease: item.lineItem.pointOfRelease,
    comments: item.lineItem.comments,
    relativeRanking: item.lineItem.relativeRanking,
    whatIfAnalysis: item.lineItem.whatIfAnalysis,
    safetyReview: item.lineItem.safetyReview,
    prelimHazardAnalysis: item.lineItem.prelimHazardAnalysis,
    otherHIRATools: item.lineItem.otherHIRATools,
    otherHIRAToolsDescription: item.lineItem.otherHIRAToolsDescription,
    type: formatSituation(item.lineItem.type),
    typeDescription: item.lineItem.typeDescription,
    potentialRisk: item.lineItem.potentialRisk,
    residualRisk: item.lineItem.residualRisk,
  };
}

export function formatGetRiskItemResponse(obj: any): IRiskItem {
  return {
    id: obj.id,
    processOperationUID: obj.processOperationUID,
    mahruid: obj.mahruid,
    mahruidPrefixed: obj.mahruidPrefixed,
    dateOfAssessment: new Date(obj.dateOfAssessment).getTime(),
    highLevelDescription: obj.highLevelDescription,
    reporter: obj.reporter,
    validityPeriod: obj.validityPeriod,
    phase: obj.phase,
    otherLocation: obj.otherLocation,
    riskStandard: obj.riskStandard,
    divisionHierarchy: obj.divisionHierarchies,
    basinHierarchy: obj.basinHierarchies,
    country: obj.country,
    facility: obj.facility,
    teamLeader: obj.teamLeader,
    teamMembers: obj.teamMembers,
    otherLocationDescription: obj.otherLocationDescription,
    wellControlRisk: obj.wellControlRisk,
    existingProcess: obj.existingProcess,
    existingProcedures: obj.existingProcedures,
    similarExperience: obj.similarExperience,
    processFlowDiagram: obj.processFlowDiagram,
    PandID: obj.pandID,
    operatingHistory: obj.operatingHistory,
    permanentProcessingFacilityOrEquipment: obj.permanentProcessingFacilityOrEquipment,
    temporaryProcessingFacilityOrEquipment: obj.temporaryProcessingFacilityOrEquipment,
    continuousOperation: obj.continuousOperation,
    batchOperation: obj.batchOperation,
    prevMAHRegisterLink: obj.prevMAHRegisterLink,
    regulatoryReqsLink: obj.regulatoryReqsLink,
    contracturalReqsLink: obj.contracturalReqsLink,
    assumptions: obj.assumptions,
    emergencyResponsePlan: obj.emergencyResponsePlan,
    riskWorkshopReportLink: obj.riskWorkshopReportLink,
    createdBy: obj.createdBy,
    createdOn: new Date(obj.createdOn).getTime(),
    modifiedBy: obj.modifiedBy,
    hazards: isArray(obj.lineItems)
      ? obj.lineItems.map((x: any): IRiskHazardItem => formatRiskItemLineItem(x))
      : [],
  }
}

export function formatRiskItemLineItem(obj: any): IRiskHazardItem {
  return {
    ...obj,
  };
}

export function formatSearchRiskFilter(obj: IRiskListFilters): any {
  return {
    startDate: obj.startDate ? new Date(obj.startDate) : undefined,
    endDate: obj.endDate ? new Date(obj.endDate) : undefined,
    reporter: obj.reporter,
    teamLeader: obj.teamLeader,
    highLevelDescription: obj.highLevelDescription,
    hazardIds: obj.hazardId ? [obj.hazardId] : [],
    divisionHierarchyIds: obj.divisionHierarchyIds,
    basinHierarchyIds: obj.basinHierarchyIds,
    countryIds: obj.countryIds,
    facilityIds: obj.facilityIds,
    otherLocationIds: obj.otherLocationId ? [obj.otherLocationId] : [],
    otherLocationDescription: obj.otherLocationDescription,
    processOperationUID: obj.processOperationUID,
    mahruidLineNumber: obj.mahruidLineNumber,
    phaseIds: obj.phaseId ? [obj.phaseId] : [],
    valid: obj.valid,
    wellControlRisk: obj.wellControlRisk,
    permanentProcessing: obj.permanentProcessing,
    temporaryProcessing: obj.temporaryProcessing,
    continuous: obj.continuous,
    batch: obj.batch,
    residualRisks: obj.residualRisks,
    potentialRisks: obj.potentialRisks,
  };
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICurrentUser } from "types/userTypes";

export interface ICurrentUserState {
  currentUser: ICurrentUser,
}

const initialState: ICurrentUserState = {
  currentUser: {
    name: "",
    email: "",
    roles: [],
  },
};

export const currentUserSlice = createSlice({
  name: "current-user",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<ICurrentUser>) => {
      state.currentUser = action.payload;
    },
  },
});

export const {
  setCurrentUser,
} = currentUserSlice.actions;

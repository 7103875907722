import React from "react";
import spinnerIcon from "shared/media/Pulse-1s-200px.svg";
import "./Spinner.scoped.scss";

interface ISpinnerProps {
  altText?: string,
  display?: "block" | "inline",
  hAlign?: "center" | "left" | "right",
  className?: string,
}

const Spinner: React.FC<ISpinnerProps> = (props) =>
  <div
    className="spinner"
    style={{
      display: props.display || "block",
      textAlign: props.hAlign || "center",
    }}
  >
    <img
      className={props.className}
      src={spinnerIcon}
      alt={props.altText || ""}
    />
  </div>;

export default Spinner;
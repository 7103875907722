import React, { useCallback, useEffect } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import { IOperation } from "shared/types/operationTypes";
import "./GroupedDropdown.scoped.scss";

const UNDEFINED_DROPDOWN_VALUE = "___UNDEFINED___";

interface IGroupedDropdownProps {
  loadOperation: IOperation<IGroupedDropdownItem[]>,
  selectedId?: string,
  isDisabled?: boolean,
  allowSelectGroup?: boolean,
  loadItems(): void,
  onValueChanged(item?: IGroupedDropdownItem): void,
  sortGroups?(a: string, b: string): number,
  sortItems?(a: string, b: string): number,
  finalSortGroups?(groupOptions: IGroupedDropdownItem[]): IGroupedDropdownItem[],
}

export interface IGroupedDropdownItem {
  value: string,
  text: string,
  parentValue: string,
}

export interface IGroupedDropdownOption {
  value: string,
  text: string,
  isGroup: boolean,
}

const GroupedDropdown: React.FC<IGroupedDropdownProps> = ({
  loadOperation,
  selectedId,
  isDisabled,
  allowSelectGroup,
  loadItems,
  onValueChanged,
  sortGroups,
  sortItems,
  finalSortGroups,
}) => {
  // eslint-disable-next-line
  const loadItemsCallback = useCallback(loadItems, []);

  useEffect(() => {
    if (!loadOperation.isWorking
      && !loadOperation.data?.length
      && !loadOperation.errorMessage) {
      loadItemsCallback();
    }
  }, [loadOperation, loadItemsCallback]);

  const options: IGroupedDropdownOption[] = [];

  if (!loadOperation.isWorking
    && !loadOperation.errorMessage) {
    let groups = loadOperation.data?.filter(x => !x.parentValue)
      .sort(sortGroups
        ? (a, b) => sortGroups(a.text, b.text)
        : (a, b) => a.text < b.text ? -1 : 1) || [];

    if (finalSortGroups) {
      groups = finalSortGroups(groups);
    }

    groups.forEach(group => {
      options.push({
        text: group.text,
        value: group.value,
        isGroup: true,
      });

      loadOperation.data?.filter(x => x.parentValue === group.value)
        .map(item => ({
          text: item.text,
          value: item.value,
          isGroup: false,
        }))
        .sort(sortItems
          ? (a, b) => sortItems(a.text, b.text)
          : (a, b) => a.text < b.text ? -1 : 1)
        .forEach(x => options.push(x));
    });
  }

  if (loadOperation.isWorking) {
    return (
      <Spinner
        className="icon-medium"
      />
    );
  } else if (loadOperation.errorMessage) {
    return (
      <span
        className="error"
        title={loadOperation.errorMessage}
      >
        Load error.
        <button
          className="link"
          onClick={loadItems}
        >
          Retry
        </button>
      </span>
    );
  } else if (!loadOperation.isWorking && !loadOperation.errorMessage) {
    return (
      <select
        value={selectedId === undefined
          ? UNDEFINED_DROPDOWN_VALUE
          : selectedId
        }
        className={!selectedId
          ? "placeholder"
          : undefined
        }
        onChange={e => onValueChanged(e.currentTarget.value
          && e.currentTarget.value !== UNDEFINED_DROPDOWN_VALUE
          ? loadOperation.data?.find(x => x.value === e.currentTarget.value)
          : undefined)
        }
        disabled={isDisabled}
      >
        <option
          className="placeholder"
          value={UNDEFINED_DROPDOWN_VALUE}
        >
          Select
        </option>
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.isGroup
              && !allowSelectGroup
            }
            className={option.isGroup
              ? "group"
              : undefined
            }
          >
            {option.text}
          </option>
        ))}
      </select>
    );
  } else {
    return null;
  }
};

export default GroupedDropdown;
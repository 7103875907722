import React from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import PickerItem from "./PickerItem";
import "./PickerList.scoped.scss";
import expandIcon from "shared/media/dls/expand-2.svg";
import collapseIcon from "shared/media/dls/collapse.svg";

interface IPickerListProps<T> {
  items: IPickerItem<T>[],
  pickerKey: string,
  allowMultiSelect?: boolean,
  noItemsMessage: string,
  displayMode: "list" | "tree",
  ancestryPath?: (number | string)[],
  selectedItems: IPickerItem<T>[],
  renderItem?: (item: T) => string | React.ReactNode,
  onItemSelected(item: IPickerItem<T>): void,
  onItemDeselected(item: IPickerItem<T>): void,
  onItemExpanded(item: IPickerItem<T>, ancestryPath?: (string | number)[]): void,
  onItemCollapsed(item: IPickerItem<T>, ancestryPath?: (string | number)[]): void,
}

const PickerList = <T,>({
  items,
  pickerKey,
  allowMultiSelect,
  noItemsMessage,
  displayMode,
  ancestryPath,
  selectedItems,
  renderItem,
  onItemSelected,
  onItemDeselected,
  onItemExpanded,
  onItemCollapsed,
}: IPickerListProps<T>) =>
  <div
    className="picker-list"
  >
    {items.map(item => {
        const showChildren = displayMode === "tree"
          && item.children !== undefined
          && item.children.length > 0;

        const childItems = item.children !== undefined
          ? item.children
          : [];

        return (
          <React.Fragment
            key={item.key}
          >
            <div
              className={`picker-item-row`}
            >
              {showChildren && (
                <img
                  className="icon-small toggle-button"
                  src={item.isExpanded
                    ? collapseIcon
                    : expandIcon
                  }
                  alt={item.isExpanded
                    ? "-"
                    : "+"
                  }
                  onClick={() => item.isExpanded
                    ? onItemCollapsed(item, ancestryPath)
                    : onItemExpanded(item, ancestryPath)
                  }
                />
              )}
              {!showChildren
                && displayMode === "tree" && (
                  <span
                    className="icon-small toggle-button-placeholder"
                  >
                  </span>
                )}
              <PickerItem
                key={item.key}
                pickerKey={pickerKey}
                item={item}
                isSelected={selectedItems.some(k => k.key === item.key)}
                renderItem={renderItem}
                type={allowMultiSelect ? "checkbox" : "radio"}
                onSelect={() => onItemSelected(item)}
                onDeselect={() => onItemDeselected(item)}
              />
            </div>
            {showChildren
              && item.isExpanded && (
                <div
                  className="child-nodes"
                >
                  <PickerList
                    items={childItems}
                    selectedItems={selectedItems}
                    renderItem={renderItem}
                    allowMultiSelect={allowMultiSelect}
                    pickerKey={pickerKey}
                    displayMode={displayMode || "list"}
                    ancestryPath={ancestryPath === undefined
                      ? [item.key]
                      : [
                        ...ancestryPath,
                        item.key,
                      ]}
                    noItemsMessage={noItemsMessage}
                    onItemSelected={onItemSelected}
                    onItemDeselected={onItemDeselected}
                    onItemExpanded={onItemExpanded}
                    onItemCollapsed={onItemCollapsed}
                  />
                </div>
              )}
          </React.Fragment>
        );
      })}
    {!items.length &&
      <span
        className="no-items"
      >
        {noItemsMessage}
      </span>
    }
  </div>;

export default PickerList;
import RiskMatrix from "components/risks/risk-matrix/RiskMatrix";
import React from "react";
import Modal from "shared/components/layout/modal/Modal";
import { RiskMatrixValues } from "types/riskTypes";
import { getRiskMatrixValuesByColor, getRiskMatrixValuesByColumn, getRiskMatrixValuesByRow } from "utilities/riskMatrixUtilities";

interface IRiskFilterModalProps {
  type: "Potential" | "Residual",
  onValuesSelected(value: RiskMatrixValues[]): void,
  onCancel(): void,
  isDisabled?: boolean,
  selectedValues: RiskMatrixValues[],
}

const RiskFilterModal: React.FC<IRiskFilterModalProps> = ({
  type,
  onValuesSelected,
  onCancel,
  isDisabled,
  selectedValues,
}) =>
  <Modal
    header={`${type} Risk`}
    isOpen={true}
    buttons={[{
      key: "cancel",
      text: "close",
      className: "primary",
      onClick: onCancel,
    }]}
  >
    <RiskMatrix
      isDisabled={isDisabled}
      onCellClicked={cell => onValuesSelected([cell])}
      onRowClicked={row => onValuesSelected(getRiskMatrixValuesByRow(row))}
      onColumnClicked={col => onValuesSelected(getRiskMatrixValuesByColumn(col))}
      onColorLegendClicked={color => onValuesSelected(getRiskMatrixValuesByColor(color))}
      userMessage="Please click on the relevant Risk level, row (0-5), column (A-D), or color (white-red) in the matrix."
      selectedValues={selectedValues}
    />
  </Modal>;

export default RiskFilterModal;
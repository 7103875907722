import React from "react";
import { RiskMatrixCellColors, RiskMatrixColors, RiskMatrixColumns, RiskMatrixRows, RiskMatrixValues } from "types/riskTypes";
import "./RiskMatrix.scoped.scss";

interface IRiskMatrixProps {
  /** Optional. If true, disables all table selections. Default = false. */
  isDisabled?: boolean,
  /** Called when the user clicks on a risk cell (A0, B3, etc.) */
  onCellClicked(value: RiskMatrixValues): void,
  /** Optional. Called when the user clicks on a row header (Row0, Row3, etc.). If not specified, user cannot click row headers. */
  onRowClicked?(value: RiskMatrixRows): void,
  /** Optional. Called when the user clicks on a column header (A, B, C, etc.). If not specified, user cannot click column headers. */
  onColumnClicked?(value: RiskMatrixColumns): void,
  /** Optional. Called when the user clicks on  */
  onColorLegendClicked?(value: RiskMatrixColors): void,
  /** Optional. A message displayed to the user above the matrix. */
  userMessage?: string,
  /** Optional. If supplied, selected values will be highlighted. */
  selectedValues?: RiskMatrixValues[],
}

const RiskMatrix: React.FC<IRiskMatrixProps> = ({
  isDisabled = false,
  onCellClicked,
  onRowClicked,
  onColumnClicked,
  onColorLegendClicked,
  userMessage,
  selectedValues,
}) => {
  const getCellClassNames = (cell: RiskMatrixValues, classNames?: string[]) => {
    const classes: string[] = [
      RiskMatrixCellColors[cell],
      (selectedValues || []).includes(cell) ? "selected" : "",
      ...(classNames || []),
      !isDisabled ? "clickable" : "",
    ];

    return classes.filter(x => !!x).join(" ") || undefined;
  };

  return (
    <div className="risk-matrix-container">
      {!isDisabled
        && !!userMessage
        && (
          <p>
            {userMessage}
          </p>
        )}

      <table
        className={`matrix ${isDisabled ? "disabled-table" : ""}`}
      >
        <thead>
          <tr>
            <td colSpan={5} className="header thick-right">Consequence</td>
            <td colSpan={4} className="header">Increasing probability</td>
          </tr>

          <tr>
            <td
              rowSpan={2}
            >
              Severity rating
            </td>
            <td
              rowSpan={2}
            >
              People
            </td>
            <td
              rowSpan={2}
            >
              Assets
            </td>
            <td
              rowSpan={2}
            >
              Environment
            </td>
            <td
              rowSpan={2}
              className="thick-right"
            >
              Reputation
            </td>

            <td
              className={onColumnClicked ? "clickable" : undefined}
              onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.A) : undefined}
            >
              A
            </td>
            <td
              className={onColumnClicked ? "clickable" : undefined}
              onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.B) : undefined}
            >
              B
            </td>
            <td
              className={onColumnClicked ? "clickable" : undefined}
              onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.C) : undefined}
            >
              C
            </td>
            <td
              className={onColumnClicked ? "clickable" : undefined}
              onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.D) : undefined}
            >
              D
            </td>
          </tr>

          <tr>
            <td onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.A) : undefined}>Has occurred in E&amp;P industry</td>
            <td onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.B) : undefined}>Has occurred in operating company</td>
            <td onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.C) : undefined}>Occured several times a year in operating company</td>
            <td onClick={onColumnClicked ? () => onColumnClicked(RiskMatrixColumns.D) : undefined}>Occured several times a year in location</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              className={onRowClicked ? "clickable" : undefined}
              onClick={onRowClicked ? () => onRowClicked(RiskMatrixRows.Row0) : undefined}
            >
              0
            </td>
            <td>Zero injury</td>
            <td>Zero damage</td>
            <td>Zero effect</td>
            <td>Zero impact</td>
            <td
              className={getCellClassNames(RiskMatrixValues.A0, ["thick-left"])}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.A0)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.B0)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.B0)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.C0)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.C0)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.D0)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.D0)}
            ></td>
          </tr>
          <tr>
            <td
              className={onRowClicked ? "clickable" : undefined}
              onClick={onRowClicked ? () => onRowClicked(RiskMatrixRows.Row1) : undefined}
            >
              1
            </td>
            <td>Slight injury</td>
            <td>Slight damage</td>
            <td>Slight effect</td>
            <td>Slight impact</td>
            <td
              className={getCellClassNames(RiskMatrixValues.A1, ["thick-left"])}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.A1)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.B1)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.B1)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.C1)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.C1)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.D1)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.D1)}
            ></td>
          </tr>
          <tr>
            <td
              className={onRowClicked ? "clickable" : undefined}
              onClick={onRowClicked ? () => onRowClicked(RiskMatrixRows.Row2) : undefined}
            >
              2
            </td>
            <td>Minor injury</td>
            <td>Minor damage</td>
            <td>Minor effect</td>
            <td>Limited impact</td>
            <td
              className={getCellClassNames(RiskMatrixValues.A2, ["thick-left"])}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.A2)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.B2)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.B2)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.C2)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.C2)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.D2)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.D2)}
            ></td>
          </tr>
          <tr>
            <td
              className={onRowClicked ? "clickable" : undefined}
              onClick={onRowClicked ? () => onRowClicked(RiskMatrixRows.Row3) : undefined}
            >
              3
            </td>
            <td className="orange">Major injury</td>
            <td className="orange">Local damage</td>
            <td className="orange">Local effect</td>
            <td className="orange">Considerable impact</td>
            <td
              className={getCellClassNames(RiskMatrixValues.A3, ["thick-left"])}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.A3)}
            ></td>
            <td
              className={getCellClassNames(RiskMatrixValues.B3)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.B3)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.C3)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.C3)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.D3)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.D3)}
            >
              MAH
            </td>
          </tr>
          <tr>
            <td
              className={onRowClicked ? "clickable" : undefined}
              onClick={onRowClicked ? () => onRowClicked(RiskMatrixRows.Row4) : undefined}
            >
              4
            </td>
            <td className="orange">Single fatality</td>
            <td className="orange">Major damage</td>
            <td className="orange">Major effect</td>
            <td className="orange">Major national impact</td>
            <td
              className={getCellClassNames(RiskMatrixValues.A4, ["thick-left"])}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.A4)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.B4)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.B4)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.C4)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.C4)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.D4)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.D4)}
            >
              MAH
            </td>
          </tr>
          <tr>
            <td
              className={onRowClicked ? "clickable" : undefined}
              onClick={onRowClicked ? () => onRowClicked(RiskMatrixRows.Row5) : undefined}
            >
              5
            </td>
            <td className="orange">Multiple fatality</td>
            <td className="orange">Extensive damage</td>
            <td className="orange">Massive effect</td>
            <td className="orange">Major international impact</td>
            <td
              className={getCellClassNames(RiskMatrixValues.A5, ["thick-left"])}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.A5)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.B5)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.B5)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.C5)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.C5)}
            >
              MAH
            </td>
            <td
              className={getCellClassNames(RiskMatrixValues.D5)}
              onClick={isDisabled ? undefined : () => onCellClicked(RiskMatrixValues.D5)}
            >
              MAH
            </td>
          </tr>
        </tbody>
      </table>

      <table
        className="notes"
      >
        <tbody>
          <tr>
            <ColorLegendCell
              color={RiskMatrixColors.white}
              onClick={onColorLegendClicked}
            />
            <td>No risk</td>
          </tr>
          <tr>
            <ColorLegendCell
              color={RiskMatrixColors.yellow}
              onClick={onColorLegendClicked}
            />
            <td>Review potential for escalation to MAH and incorporate risk reducing measures</td>
          </tr>
          <tr>
            <ColorLegendCell
              color={RiskMatrixColors.orange}
              onClick={onColorLegendClicked}
            />
            <td>MAH - Need to incorporate risk level reducing measures</td>
          </tr>
          <tr>
            <ColorLegendCell
              color={RiskMatrixColors.red}
              onClick={onColorLegendClicked}
            />
            <td>MAH - Fail to meet acceptable risk level</td>
          </tr>
        </tbody>
      </table>

      <p>
        <label>Indicative table of Process Safety risk tolerability and MAH classification in general terms.</label>
      </p>
    </div>
  );
}

export default RiskMatrix;

function ColorLegendCell({
  color,
  onClick,
}: {
  color: RiskMatrixColors,
  onClick?: (value: RiskMatrixColors) => void,
}) {
  return (
    <td
      className={`${color} ${onClick ? "clickable" : ""}`}
      onClick={onClick
        ? () => onClick(color)
        : undefined
      }
    ></td>
  );
}
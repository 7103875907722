import React, { useEffect, useRef } from "react";
import "./SearchBox.scoped.scss";
import searchIcon from "shared/media/dls/search.svg";

const SearchBox: React.FC<React.HTMLProps<HTMLInputElement>> = (props) => {
  const autofocus = props.autoFocus;
  const searchBoxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (searchBoxRef.current
      && autofocus) {
      window.setTimeout(() => searchBoxRef.current?.focus(), 1);
    }
  }, [autofocus]);

  return (
    <input
      type="text"
      {...props}
      className={`search-box ${props.className}`}
      style={props.style
        ? {
          ...props.style,
          backgroundImage: `url(${searchIcon})`,
        } : {
          backgroundImage: `url(${searchIcon})`,
        }
      }
      ref={searchBoxRef}
    />
  );
};

export default SearchBox;
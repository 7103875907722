import React from "react";
import FlowLayout from "../FlowLayout";
import "./Page.scss";
import PageBar, { IPageHeaderControl } from "./PageBar";
import PageFooter from "./PageFooter";

export interface IPageProps {
  title?: string,
  backButtonRoute?: string,
  headerControls?: IPageHeaderControl[],
  footer?: React.ReactNode,
  stretchChildren?: boolean,
  children?: React.ReactNode,
}

const Page: React.FC<IPageProps> = ({
  title,
  backButtonRoute,
  headerControls,
  children,
  footer,
  stretchChildren
}) =>
  <FlowLayout
    classNames={{
      header: "page-header",
      body: `page-body ${stretchChildren ? "stretch-children" : ""}`,
    }}
    header={title
      ? (
        <PageBar
          title={title}
          backButtonRoute={backButtonRoute}
          headerControls={headerControls}
        />
      ) : undefined
    }
    footer={footer === undefined
      ? undefined
      : (
        <PageFooter>
          {footer}
        </PageFooter>
      )
    }
  >
    {children}
  </FlowLayout>;

export default Page;
import { IColumnItem } from "shared/types/columnTypes";
import "./TableHeader.scoped.scss";

interface ITableHeaderProps<T, K extends keyof T> {
  columns: Array<IColumnItem<T, K>>,
}

const TableHeader = <T, K extends keyof T>({
  columns,
}: ITableHeaderProps<T, K>): JSX.Element => {
  const headers = columns.map((column, index) => {
    const style = {
      width: column.width + "%",
      maxWidth: column.width + "%",
    };

    return (
      <th
        className={`table-header ${column.isVerticalText ? "vertical-text": ""} ${column.headerCellClassName || ""}`}
        key={`headCell-${column.key}`}
        style={style}
      >
        {column.header}
      </th>
    );
  });

  return (
    <tr>
      {headers}
    </tr>
  );
};

export default TableHeader;

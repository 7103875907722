import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import pickerHandlers, { getInitialPickerState } from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { IPickerState } from "shared/types/pickerTypes";
import { IBasinItem, IBusinessItem, ICountry, IFacility, IHazard, IOtherLocation, IPhase } from "types/masterDataTypes";
import { IRiskHazardLineItemView, IRiskListFilters } from "types/riskTypes";

export interface IRiskListState {
  riskItems: IRiskHazardLineItemView[],
  loadOperation?: IOperation<IPagedResult<IRiskHazardLineItemView>>,
  page: number,
  pageSize: number,
  hasMore: boolean,
  areFiltersVisible: boolean,
  unappliedFilters: IRiskListFilters,
  appliedFilters: IRiskListFilters,

  pickerData: {
    facilities: IPickerState<IFacility>,
    countries: IPickerState<ICountry>,
    businesses: IPickerState<IBusinessItem>,
    basins: IPickerState<IBasinItem>,
  },
  phases: IOperation<IPhase[]>,
  otherLocations: IOperation<IOtherLocation[]>,
  hazards: IOperation<IHazard[]>,
}

export enum RiskListPickerKeys {
  facilities = "facilities",
  countries = "countries",
  businesses = "businesses",
  basins = "basins",
}

const initialState: IRiskListState = {
  riskItems: [],
  loadOperation: {
    isWorking: true,
  },
  page: 1,
  pageSize: 20,
  hasMore: false,
  areFiltersVisible: false,
  unappliedFilters: {
    basinHierarchyIds: [],
    countryIds: [],
    divisionHierarchyIds: [],
    facilityIds: [],
    potentialRisks: [],
    residualRisks: [],
  },
  appliedFilters: {
    basinHierarchyIds: [],
    countryIds: [],
    divisionHierarchyIds: [],
    facilityIds: [],
    potentialRisks: [],
    residualRisks: [],
  },

  pickerData: {
    facilities: getInitialPickerState(RiskListPickerKeys.facilities),
    countries: getInitialPickerState(RiskListPickerKeys.countries),
    businesses: getInitialPickerState(RiskListPickerKeys.businesses),
    basins: getInitialPickerState(RiskListPickerKeys.basins),
  },
  phases: {
    isWorking: false,
  },
  otherLocations: {
    isWorking: false,
  },
  hazards: {
    isWorking: false,
  },
};

export const riskListSlice = createSlice({
  name: "risk-list",
  initialState,
  reducers: {
    ...pickerHandlers,
    loadRiskList: (state, _: PayloadAction<{
      pageNumber: number,
      pageSize: number,
    }>) => {
      state.loadOperation = {
        isWorking: true,
      };
    },
    finishLoadingRiskList: (state, action: PayloadAction<IOperation<IPagedResult<IRiskHazardLineItemView>>>) => {
      if (action.payload.errorMessage
        || action.payload.data === undefined) {
        state.loadOperation = action.payload;
        return;
      }

      if (action.payload.data.currentPage === 1) {
        state.riskItems = [];
        state.page = 1;
      }

      const newRiskItems = action.payload.data.results;
      state.riskItems.push(...newRiskItems);
      state.hasMore = newRiskItems.length === action.payload.data.pageSize;
      state.page = action.payload.data.currentPage + 1;

      state.loadOperation = undefined;
    },
    toggleFilters: (state, action: PayloadAction<boolean>) => {
      state.areFiltersVisible = action.payload;
    },
    updateUnappliedFilters: (state, action: PayloadAction<Partial<IRiskListFilters>>) => {
      Object.assign(state.unappliedFilters, action.payload);
    },
    clearFilters: state => {
      state.unappliedFilters = cloneDeep(initialState.unappliedFilters);
      Object.entries(state.pickerData).forEach(entry => {
        (state.pickerData as any)[entry[0]].selectedItems = [];
      });
    },
    applyFilters: state => {
      state.appliedFilters = cloneDeep(state.unappliedFilters);
      state.appliedFilters.basinHierarchyIds = state.pickerData.basins.selectedItems.map(x => x.item?.id || "");
      state.appliedFilters.divisionHierarchyIds = state.pickerData.businesses.selectedItems.map(x => x.item?.id || "");
      state.appliedFilters.countryIds = state.pickerData.countries.selectedItems.map(x => x.item?.id || "");
      state.appliedFilters.facilityIds = state.pickerData.facilities.selectedItems.map(x => x.item?.id || "");
      state.page = initialState.page;
      state.pageSize = initialState.pageSize;
    },
    loadPhases: (state) => {
      state.phases = {
        isWorking: true,
      };
    },
    finishLoadingPhases: (state, action: PayloadAction<IOperation<IPhase[]>>) => {
      state.phases = action.payload;
    },
    loadOtherLocations: (state) => {
      state.otherLocations = {
        isWorking: true,
      };
    },
    finishLoadingOtherLocations: (state, action: PayloadAction<IOperation<IOtherLocation[]>>) => {
      state.otherLocations = action.payload;
    },
    loadHazards: (state) => {
      state.hazards = {
        isWorking: true,
      };
    },
    finishLoadingHazards: (state, action: PayloadAction<IOperation<IHazard[]>>) => {
      state.hazards = action.payload;
    },
  },
});

export const {
  loadRiskList,
  finishLoadingRiskList,
  toggleFilters,
  updateUnappliedFilters,
  clearFilters,
  applyFilters,

  // Pickers and Dropdowns.
  openPicker,
  closePicker,
  loadPickerItems,
  setPickerError,
  setPickerItems,
  setSelectedPickerItems,
  expandPickerItem,
  collapsePickerItem,

  loadPhases,
  finishLoadingPhases,
  loadOtherLocations,
  finishLoadingOtherLocations,
  loadHazards,
  finishLoadingHazards,
} = riskListSlice.actions;

import type { Transition } from 'history';
import { useCallback } from 'react';
import { useBlocker } from './useBlocker';

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
 export function usePrompt(message: boolean | string | ((
  location: Transition['location'],
  action: Transition['action'],
  continueNav: () => void,
) => string | boolean), when = true) {
  const blocker = useCallback((tx: Transition) => {
    let response;
    
    if (typeof message === "boolean") {
      response = message;
    } else if (typeof message === 'function') {
      response = message(tx.location, tx.action, () => tx.retry());
      if (typeof response === 'string') {
        response = window.confirm(response);
      }
    } else {
      response = window.confirm(message);
    }

    if (response) {
      tx.retry();
    }
  }, [message]);
  return useBlocker(blocker, when);
}